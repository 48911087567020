import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { StyledSwitch } from "../../components/Base";
import Button from "../../components/Button";
import TableComponent from "../../components/TableComponent";
import { getObervableByPositionId, getTrainingUser, getAssessmentPassed, changeStatusAnswer, setAssessmentPassed, setPracticalStatus  } from "../../api/training";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';

import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';

import Paper from '@mui/material/Paper';


function isObjectInArray(arr, obj) {
  for (let i = 0; i < arr.length; i++) {
    if (JSON.stringify(arr[i]) === JSON.stringify(obj)) {
      return true;
    }
  }
  return false;
}

const AssessmentTraining = ({ match, history }) => {

  const [observableList, setObservableList] = useState([]);
  const [refresh, setRefresh] = useState(1);
  const [totalQuestions, settotalQuestions] = useState(0);
  const [rightAnswers, setRightAnswers] = useState(0);
  const [perc, setPerc] = useState(0);
  const trainingUserId = match.params.trainingUserId;
  const positionId = match.params.positionId;

  async function onStatusChange(trainingUserId,  processStepId, activitiesTrainingId,  observableId, passed)  {
      await changeStatusAnswer(trainingUserId, processStepId, activitiesTrainingId,  observableId, passed);
      setRefresh(refresh * -1);
  }


  useEffect(() => {
    (async () => {
    
      //const res = await getObervableByOperationId(operationId);
      const res = await getObervableByPositionId(positionId);
      const assessmentPassed =  await getAssessmentPassed(trainingUserId);
      const trainingUser = await getTrainingUser(trainingUserId);

      let rightAnswers = 0;
      res.forEach((elem) => {
        elem.ob.forEach((row) => {
          let item = { 
            process_step_id:row.process_step_id,
            activities_training_id:row.activities_training_id, 
            observable_id:row.observable_id
          };
          if (isObjectInArray(assessmentPassed, item)) {
            row.passed = 1;
            rightAnswers = rightAnswers + 1;
          } else {
            row.passed = 0;
          }
          row.passed = isObjectInArray(assessmentPassed, item) ? 1 : 0;
        }) 
      })
      setObservableList(res)
      const totalQuestions = res.reduce( (tot, elem) => tot + elem.ob.length, 0)
      settotalQuestions(totalQuestions)
      setRightAnswers(rightAnswers)

      const perc = Math.round(rightAnswers/totalQuestions*10000)/100;
      if (totalQuestions === 0) { 
        setPerc(0) 
      } else { 
        setPerc(perc) 
      } 

      //set assessment passed

      if (perc >= process.env.REACT_APP_ASSESSMENT_PASSED){
        setAssessmentPassed(trainingUserId, 1)
        // check if all module practical passes
        if (trainingUser.Training.instructor_complete === trainingUser.instructor_complete && 
            trainingUser.Training.assessor_passed === trainingUser.Training.assessor_passed 
            && trainingUser.Training.checker_passed === trainingUser.checker_passed) 
          {
            await setPracticalStatus(trainingUserId, 1)
          } 
      } else {
        await setAssessmentPassed(trainingUserId, 0)
        await setPracticalStatus(trainingUserId, 0)
      }
      

    })();
  }, [refresh]);
  return (
    <>
      <Typography variant="h3">Assessments</Typography>
          <Typography variant="h4">Total questions: {totalQuestions}</Typography>
          <Typography variant="h4">Passed questions: {rightAnswers}</Typography>
          <Typography variant="h4">Perc: {perc} %</Typography>

          {observableList.map((item) => {
                return <div key={item.process_step_id} style={{paddingBottom:'30px', paddingTop:'30px'}}> 
                    <Typography variant="h4">{item.process_step_name}</Typography>
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell style={{width:'40%', fontSize: "1.2rem"}} align="left"><b></b>Activities</TableCell>
                            <TableCell style={{ fontSize: "1.2rem"}}  align="left">Observable</TableCell>
                            <TableCell  style={{width:'10%', fontSize: "1.2rem"}} align="left">Passed</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                        {item.ob.map((row, i) => {
                            return  <>
                                    <TableRow
                                        key= {i}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                      >
                                        <TableCell >{row.activities_training_name}</TableCell>
                                        <TableCell >{row.observable_name}</TableCell>
                                        <TableCell>
                                          <StyledSwitch
                                            title="Change company status"
                                            checked={row.passed === 1}
                                            onChange={() =>
                                              onStatusChange(trainingUserId, row.process_step_id, row.activities_training_id,  row.observable_id, row.passed) 
                                            }
                                          />
                                        </TableCell>
                                      </TableRow>       
                                      </>
                        })}

                    </TableBody>
                  </Table>
                </TableContainer>   
            </div>
          })}


      <Button
              title="Go back"
              type="button"
              onClick={() => history.goBack()}
          >
            Go back
      </Button>
    </>
  );
};

export default AssessmentTraining;
