import React from "react";
import Typography from "@mui/material/Typography";

import CourseStatusIcon from "../../components/CourseStatusIcon";

const ExpandableSectionTitle = ({ title, status }) => (
  <>
    {status ? <CourseStatusIcon status={status} /> : null}
    <Typography variant={"body1"} style={{ marginLeft: ".5rem" }}>
      {title}
    </Typography>
  </>
);

export default ExpandableSectionTitle;
