import React from "react";
import Box from "@mui/material/Box";
import { Switch, Route } from "react-router";
import UserEdit from "../../features/user-edit";
import RolesEdit from "../../features/member-roles";
import UserCrewEditNavigation from "../../features/user-navigation/UserCrewEditNavigation";


const UserCrewEdit = ({ match, history }) => {
  return (
    <>
      <Box marginBottom={"1.5rem"}>
        <Route
          path={`${match.path}/:pageId`}
          component={UserCrewEditNavigation}
        />
      </Box>
      <Switch>
        <Route path={`${match.path}/user`} component={UserEdit} />

        <Route path={`${match.path}/roles`} component={RolesEdit} />
      </Switch>
    </>
  );
};

export default UserCrewEdit;
