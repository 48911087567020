import React from "react";

import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Link from "@mui/material/Link";
import AddIcon from "@mui/icons-material/Add";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Button from "../../components/Button";

const ScheduleItem = ({ onAdd, onDelete, slots, eventType }) => {
  return (
    <TableRow>
      <TableCell>{slots.user_id}</TableCell>
      <TableCell>{slots.fullname}</TableCell> 
      <TableCell>{slots.courseName}</TableCell> 
      <TableCell>{slots.location}</TableCell> 
      <TableCell>{slots.start_date_text}</TableCell> 
      <TableCell>{slots.end_date_text}</TableCell> 
      <TableCell>
        {!(slots.scheduled_id) &&
        <Link style={{ marginRight: "1rem" }} onClick={() => onAdd(slots.trainingId, slots.user_id, eventType)}>
          <Button title="Add slot" icon={<AddIcon />} />
        </Link>
         }
         {(slots.scheduled_id) &&
        <Link onClick={() => onDelete(slots.scheduled_id)}>
          <Button
            title="Delete slot"
            icon={<DeleteForeverIcon color="error" />}
          />
        </Link>
        }
      </TableCell>
    </TableRow>
  );
};

export default ScheduleItem;
