import React, { useState, useEffect, useMemo } from "react";

import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import PostAddIcon from "@mui/icons-material/PostAdd";
import TextField from "@mui/material/TextField";
import TableSortLabel from "@mui/material/TableSortLabel";
import TablePagination from "@mui/material/TablePagination";
import Divider from "@mui/material/Divider";

import Button from "../../components/Button";
import Confirm from "../../components/Confirm";
import PositionItem from "./PositionItem";

import "./index.scss";

import {
  getPositions,
  deletePositionById,
  updatePosition,
} from "../../api/positions";

const PositionsTable = ({ match, history }) => {
  const [positions, setPositions] = useState([]);
  const [selectedPositionId, setSelectedPositionId] = useState(null);
  const [isDeleteModalShow, setIsDeleteModalShow] = useState(false);
  const [refresh, setRefresh] = useState(1);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const [searchKey, setSearchKey] = useState("");
  const [sortField, setSortField] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");

  const isSortAscended = useMemo(() => {
    return sortDirection === "asc";
  }, [sortDirection]);

  const filteredPositions = useMemo(() => {
    let filteredArray = positions;
    if (searchKey) {
      filteredArray = positions.filter((position) => {
        return (
          position.name.toLowerCase().includes(searchKey.toLowerCase()) ||
          position.category.name.toLowerCase().includes(searchKey.toLowerCase())
        );
      });
    }

    if (sortField) {
      filteredArray.sort((a, b) => {
        let firstField = a[sortField];
        let secondField = b[sortField];
        //key insensitive
        if (typeof a[sortField] === "string") {
          firstField = firstField.toLowerCase();
          secondField = secondField.toLowerCase();
        }

        if (isSortAscended) {
          return firstField > secondField;
        } else {
          return firstField < secondField;
        }
      });
    }

    return filteredArray;
  }, [positions, searchKey, sortField, isSortAscended]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function handleHeaderClick(headerName) {
    if (sortField === headerName) {
      setSortDirection(isSortAscended ? "desc" : "asc");
    } else {
      setSortField(headerName);
    }
  }

  function showDeleteModal(id) {
    setSelectedPositionId(id);
    setIsDeleteModalShow(true);
  }

  function onEdit(id) {
    history.push(`${match.path}/${id}`);
  }

  async function onStatusChange(id, newStatus) {
    await updatePosition(id, { status: newStatus });

    setRefresh(refresh * -1);
  }

  async function confirmDeleteModal(isConfirmed) {
    if (isConfirmed) {
      await deletePositionById(selectedPositionId);
    }
    setIsDeleteModalShow(false);

    setRefresh(refresh * -1);
  }

  useEffect(() => {
    (async () => {
      let newPositions = await getPositions();
      setPositions(newPositions);
    })();
  }, [refresh]);

  return (
    <>
      <h2>Positions</h2>
      <TableContainer component={Paper} variant="outlined">
        <div className="header-panel">
          <TextField
            className="search-input"
            placeholder="Search"
            value={searchKey}
            onChange={(event) => setSearchKey(event.target.value)}
            variant="outlined"
          />
          <Button
            size="small"
            onClick={() => history.push(`${match.path}/add_position`)}
          >
            <PostAddIcon />
            Add new position
          </Button>
        </div>
        <Divider />
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell style={{ width: "5%" }}>
                <TableSortLabel
                  active={sortField === "id"}
                  direction={sortDirection}
                  onClick={() => handleHeaderClick("id")}
                >
                  ID
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortField === "name"}
                  direction={sortDirection}
                  onClick={() => handleHeaderClick("name")}
                >
                  Position Name
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortField === "id"}
                  direction={sortDirection}
                  onClick={() => handleHeaderClick("id")}
                >
                  Category name
                </TableSortLabel>
              </TableCell>
              <TableCell style={{ width: "15%" }}>
                <TableSortLabel
                  active={sortField === "status"}
                  direction={sortDirection}
                  onClick={() => handleHeaderClick("status")}
                >
                  Position Status
                </TableSortLabel>
              </TableCell>
              <TableCell style={{ width: "15%" }}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredPositions
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((position) => (
                <PositionItem
                  key={position.id}
                  onEdit={onEdit}
                  onDelete={showDeleteModal}
                  onStatusChange={onStatusChange}
                  position={position}
                />
              ))}
          </TableBody>
        </Table>
        {isDeleteModalShow && selectedPositionId ? (
          <Confirm title={"Delete position"} onConfirm={confirmDeleteModal}>
            <p>Are you sure you want to delete the position?</p>
          </Confirm>
        ) : null}
      </TableContainer>
      <TablePagination  
        rowsPerPageOptions={[10, 25, 50, 100]}
        component="div"
        count={filteredPositions.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

export default PositionsTable;
