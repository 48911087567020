import React from "react";
import { useRouteMatch } from 'react-router-dom';
import Typography from "@mui/material/Typography";
import AddUserForm from "../../components/EditForm/add";

import { createUser } from "../../api/user";

const UserAdd = ({ match, history }) => {
  let originUrl = useRouteMatch();
  const { crewId } = match?.params;


  let members = "members"
  if( originUrl.url.includes('users')){
    members = "users"
  }




  const onSubmit = async (values) => {
    const error = await createUser({
      ...values,
      crewId,
    });
    console.log(error);
    if (!error) {
      history.push(`/crew/${crewId}/${members}`);
    } else {
      return error;
    }
  };

  return (
    <>
      <Typography variant="h4">Add {members}</Typography>
      <AddUserForm onSubmit={onSubmit} />
    </>    
  );
};

export default UserAdd;
