import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";

interface SearchFieldProps {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
}

const StyledSearch = styled(TextField)({
  width: "20rem",
  "& input": {
    padding: "0.25rem 0.5rem",
  },
});

// TODO this should also show an X button on the right so user can clear search with single click
// TODO should also select all text on focus so it's easy to overwrite / reset search like this, too

const SearchField = ({
  value,
  onChange,
  placeholder = "Search",
  ...props
}: SearchFieldProps) => (
  <StyledSearch
    className="search-filed"
    placeholder={placeholder}
    value={value}
    onChange={onChange}
    variant="outlined"
    {...props}
  />
);

export default SearchField;
