import React from "react";
import { useHistory } from "react-router-dom";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import Button from "../Button";

const NavigationBack = () => {
  const history = useHistory();

  return (
    <Button
      title="Back"
      icon={<ArrowBackIcon />}
      onClick={() => history.goBack()}
    />
  );
};

export default NavigationBack;
