import { useState, useEffect } from "react";
import { Autocomplete, Paper, TextField} from '@mui/material';
import { sortBy } from "lodash";

import Button from "../../components/Button";
import { useConfirm } from "../../context/ConfirmContext";

import "./index.scss";
import {
  getAvailableCourses,
  getCoursesGroupById,
  updateGroupCourse,
} from "../../api/coursesGroups";

const GROUP_NAME_ERROR_TEXT = "Please enter the name of group";

const CoursesGroupsEdit = ({ match, history }) => {
  const [isDataFetched, setIsDataFetched] = useState(false);
  const [newGroupName, setNewGroupName] = useState("");
  const [availableCourses, setAvailableCourses] = useState([]);
  const [selectedCourses, setSelectedCourses] = useState([]);

  const [groupNameErrorText, setGroupNameErrorText] = useState(null);
  const confirmDialog = useConfirm();

  useEffect(() => {
    (async () => {
      const groupCourse = await getCoursesGroupById(match.params.groupCourseId);

      setNewGroupName(groupCourse.groupName);

      setSelectedCourses(groupCourse.groupCourses);

      const newCourses = await getAvailableCourses();
      setAvailableCourses(newCourses);
      setIsDataFetched(true);
    })();
  }, [match.params.groupCourseId]);

  function handleGroupName(value) {
    setNewGroupName(value);
    if (!value) return setGroupNameErrorText(GROUP_NAME_ERROR_TEXT);
    setGroupNameErrorText(null);
  }

  function arrayDifference(array1, array2) {
    let cutArray = [];
    if (array1.length === 0 && array2.length !== 0) cutArray = array2;
    else if (array2.length === 0 && array1.length !== 0) cutArray = array1;
    else if (array2.length !== 0 && array1.length !== 0) {
      cutArray = array1
        .filter((item1) => !array2.includes(item1))
        .concat(array2.filter((item2) => !array1.includes(item2)));
    }
    return cutArray;
  }

  function handleSelectedCourses(newCourses) {
    if (newCourses.length < selectedCourses.length) {
      let cutCourses = arrayDifference(newCourses, selectedCourses);
      setAvailableCourses((oldValue) => [...oldValue, ...cutCourses]);
    } else {
      setAvailableCourses((oldValue) =>
        oldValue.filter((course) => !newCourses.includes(course))
      );
    }
    setSelectedCourses(newCourses);
  }

  async function save() {
    if (!newGroupName) return handleGroupName(newGroupName);
    const groupCourseId = match.params.groupCourseId;
    const result = await updateGroupCourse(groupCourseId, {
      groupName: newGroupName,
      selectedCourses: selectedCourses,
    });
    if (result.ok) {
      history.push("/courses_groups");
    } else {
      confirmDialog.notification( result);
    }
  }

  return (
    <>
      <h2>Edit Courses Group</h2>

      <Paper
        variant="outlined"
        className="edit-operation"
        style={{ padding: "1rem 1.5rem" }}
      >
        {isDataFetched ? (
          <>
            <TextField
              className="edit-field"
              variant="outlined"
              size="small"
              label="Name*"
              value={newGroupName}
              onChange={(event) => handleGroupName(event.target.value)}
              error={!!groupNameErrorText}
              helperText={groupNameErrorText}
            />
            <Autocomplete
              className="select-position"
              size="small"
              multiple
              options={sortBy(availableCourses, "startDate")}
              getOptionLabel={(c) => `${c.visible ? '' : '(hidden)'} (${c.id}) ${c.name}`}
              defaultValue={selectedCourses}
              value={sortBy(selectedCourses, "startDate")}
              onChange={(event, newCourses) =>
                handleSelectedCourses(newCourses)
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Select courses"
                />
              )}
            />
            <Button title="Save" onClick={save}>
              Save
            </Button>
            <Button title="Go back" onClick={() => history.goBack()}>
              Cancel
            </Button>
          </>
        ) : null}
      </Paper>
    </>
  );
};

export default CoursesGroupsEdit;
