import request from "./request";

export const getPendingCertificates = async (crewId) => {
  const json = await request(`/certificate/pending/${crewId}`);
  return json;
};

export const getPendingCertificatesCount = async (crewId) => {
  const json = await request(`/certificate/pending/${crewId}/count`);
  return json;
};

export const resolvePending = async (crewId, objectId, status, isCustom) => {
  const json = await request.put(
    `/certificate/pending/${crewId}/resolve/${objectId}`,
    {
      status,
      isCustom,
    }
  );
  return json;
};
