import { createContext } from "react";

interface FormContextType {
  set: (name: string, value: any) => void;
  get: (name: string) => any;
}

const FormContext = createContext<FormContextType>({
  set: () => {},
  get: () => {},
});

export default FormContext;
