import request from "./request";

import { SelectableWithIdObject } from "src/components/Form/types";

interface CountryResponse {
  id: number;
  name: string;
  code: string;
}

export class Country extends SelectableWithIdObject {
  public name: string;
  public code: string;

  constructor(data: CountryResponse) {
    super(data.id);
    this.name = data.name;
    this.code = data.code;
  }

  getDisplayName(): string {
    return this.name;
  }
}

export const mapCountry = (data: CountryResponse): Country => {
  return new Country(data);
};

export const getCountries = async (): Promise<Country[]> => {
  const response = await request(`/countries`);
  return response.map((data: CountryResponse) => mapCountry(data));
};
