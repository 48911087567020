import { useCallback, useEffect, useState, useRef, ReactNode } from "react";

import { Row } from "../../components/Base";
import Button from "../../components/Button";
import { Model } from "../Form/types";

interface DynamicListProps<T extends Model> {
  renderChild: (props: {
    item: T;
    onChange: (updatedItem: T) => void;
    onRemove: () => void;
  }) => ReactNode;
  initialList?: T[];
  createItem?: () => T;
  onChange: (list: T[]) => void;
  addButtonText: string;
}

const DynamicList = <T extends Model>({
  renderChild,
  initialList = [],
  onChange,
  createItem,
  addButtonText,
}: DynamicListProps<T>) => {
  const [list, setList] = useState<T[]>(initialList);
  const prevListRef = useRef<T[]>(initialList);

  const handleAdd = useCallback(() => {
    setList((prevList) => [...prevList, createItem ? createItem() : ({} as T)]);
  }, []);

  const handleRemove = useCallback((index: number) => {
    setList((prevList) => prevList.filter((_, i) => i !== index));
  }, []);

  const handleUpdate = useCallback((index: number, updatedItem: T) => {
    setList((prevList) =>
      prevList.map((item, i) => (i === index ? updatedItem : item)),
    );
  }, []);

  useEffect(() => {
    const filteredList = list.filter((item) =>
      typeof item?.validate === "function"
        ? item.validate()
        : item && Object.keys(item).length > 0,
    );
    if (JSON.stringify(filteredList) !== JSON.stringify(prevListRef.current)) {
      prevListRef.current = filteredList;
      onChange(filteredList);
    }
  }, [list, onChange]);

  return (
    <>
      <Row>
        <Button onClick={handleAdd}>{addButtonText}</Button>
      </Row>
      {list.map((item, index) => (
        <Row key={index}>
          {renderChild({
            item,
            onChange: (updatedItem: T) => handleUpdate(index, updatedItem),
            onRemove: () => handleRemove(index),
          })}
        </Row>
      ))}
    </>
  );
};

export default DynamicList;
