import React from "react";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";

import BillingForm from "./form";

const BillingDialog = ({ done, id }) => (
  <Dialog open={true} onClose={() => done()}>
    <DialogTitle>
      {id ? "Edit billing address" : "Add billing address"}
    </DialogTitle>
    <DialogContent>
      <BillingForm id={id} onSubmit={() => done()} />
    </DialogContent>
  </Dialog>
);

export default BillingDialog;
