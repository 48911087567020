import { useState, useEffect } from "react";

import { Autocomplete, Paper, TextField} from '@mui/material';
import Button from "../../components/Button";

import { getPositions } from "../../api/positions";
import { getOperationById, updateOperation } from "../../api/operations";
import "./index.scss";

const OPERATION_ERROR_TEXT = "Please enter the name of operation";
const POSITIONS_ERROR_TEXT = "Please select one or more positions";

const OperationsEdit = ({ match, history }) => {
  const [newOperationName, setNewOperationName] = useState("");
  const [positions, setPositions] = useState([]);
  const [selectedPositions, setSelectedPositions] = useState([]);

  const [operationErrorText, setOperationErrorText] = useState(null);
  const [positionsErrorText, setPositionsErrorText] = useState(null);

  const [isDataFetching, setIsDataFetching] = useState(false);

  useEffect(() => {
    (async () => {
      setIsDataFetching(true);
      const operation = await getOperationById(match.params.operationId);

      setNewOperationName(operation.name);
      setSelectedPositions(operation.positions);

      let newPositions = await getPositions();
      setPositions(newPositions);
      setIsDataFetching(false);
    })();
  }, [match.params.operationId]);

  function handleOperationName(value) {
    setNewOperationName(value);
    if (!value) return setOperationErrorText(OPERATION_ERROR_TEXT);
    setOperationErrorText(null);
  }

  function handleSelectedPositions(value) {
    setSelectedPositions(value);
    if (!value.length) return setPositionsErrorText(POSITIONS_ERROR_TEXT);
    setPositionsErrorText(null);
  }

  async function editOperation() {
    if (!selectedPositions.length)
      return handleSelectedPositions(selectedPositions);
    if (!newOperationName) return handleOperationName(newOperationName);

    const operationId = match.params.operationId;

    const positionIdList = selectedPositions.map((position) => position.id);
    const result = await updateOperation(operationId, {
      name: newOperationName,
      positionIdList: positionIdList,
    });
    if (result.ok) {
      history.push("/operations");
    }
  }

  return (
    <>
      <h2>Edit operation</h2>

      <Paper
        variant="outlined"
        className="edit-operation"
        style={{ padding: "1rem 1.5rem" }}
      >
        {!isDataFetching ? (
          <>
            <Autocomplete
              className="select-position"
              size="small"
              multiple
              options={positions}
              getOptionLabel={(position) => position.name}
              filterSelectedOptions
              defaultValue={selectedPositions}
              value={selectedPositions}
              onChange={(event, newPositions) =>
                handleSelectedPositions(newPositions)
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Select position"
                  error={!!positionsErrorText}
                  helperText={positionsErrorText}
                />
              )}
            />
            <TextField
              className="edit-field"
              variant="outlined"
              size="small"
              label="Operation name*"
              value={newOperationName}
              onChange={(event) => handleOperationName(event.target.value)}
              error={!!operationErrorText}
              helperText={operationErrorText}
            />
            <Button title="Save Operation" onClick={editOperation}>
              Save Operation
            </Button>
            <Button title="Go Back" onClick={() => history.goBack()}>
              Cancel
            </Button>
          </>
        ) : null}
      </Paper>
    </>
  );
};

export default OperationsEdit;
