import React from "react";

import Box from "@mui/material/Box";
import SchoolIcon from "@mui/icons-material/School";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import StyledListItemIcon from "../List/StyledListItemIcon";

import CourseAction from "./CourseAction";

const CourseTreeItem = ({
  nodeId,
  course,
  customCourse = null,
  group = null,
}) => {
  const obj = course || customCourse || group;
  const id = customCourse
    ? `cc_${customCourse.id}`
    : group
    ? `gc_${group.groupId}`
    : course?.id;
  const title = `${obj.shortname || obj.fullname || obj.name}`;
  const key = `${nodeId}/c${id}`;

  return obj ? (
    <ListItem key={key}>
      <StyledListItemIcon>
        <SchoolIcon />
      </StyledListItemIcon>
      <ListItemText>
        <Box display={"flex"} lineHeight={2}>
          {obj.hidden ? (
            <Box color="text.disabled" fontStyle="italic">
              {title}
            </Box>
          ) : (
            title
          )}
          <CourseAction course={{ ...obj, isCustom: !!customCourse }} />
        </Box>
      </ListItemText>
    </ListItem>
  ) : null;
};

export default CourseTreeItem;
