import React from "react";

import { Switch, Route } from "react-router";
import SpecializationsTable from "../../features/specializations-table";
import SpecializationsAdd from "../../features/specializations-add";
import SpecializationsEdit from "../../features/specializations-edit";

const Specializations = ({ match, history }) => {
  return (
    <>
      <Switch>
        <Route exact path={`${match.path}`} component={SpecializationsTable} />

        <Route
          exact
          path={`${match.path}/add_specialization`}
          component={SpecializationsAdd}
        />
        <Route
          exact
          path={`${match.path}/:specializationId`}
          component={SpecializationsEdit}
        />
      </Switch>
    </>
  );
};

export default Specializations;
