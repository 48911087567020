import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import useCrew from "../../hooks/useCrew";
import useUser from "../../hooks/useUser";
import React, { useState, useEffect } from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Button from "../../components/Button";
import TableComponent from "../../components/TableComponent";
import { getCrewUsersTrainings  } from "../../api/training";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import { getCrewTrainingBought  } from "../../api/training";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import { useShoppingCart } from "../../context/ShoppingCartContext";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import { Link } from 'react-router-dom';
import GetAppIcon from "@mui/icons-material/GetApp";
import {openExportWindow } from "../../api/";

     

function formatDate(string){
  if (string) {
    return new Date(string).toLocaleString('de-CH', {day: '2-digit', month:'2-digit', year:'2-digit'});
  } else {
    return 'Missing';
  }
}


function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const CrewUsersTrainings = ({ match, history }) => {

  const { crew } = useCrew();
  const { t } = useTranslation();
  const crewId = crew.id;
  const shoppingCart = useShoppingCart();
  const [crewUsersTrainings, setCrewUsersTrainings] = useState([]);
  const user = useUser();     
  //const eventType = 'trainer';

  useEffect(() => {
    (async () => {
      const res = await getCrewUsersTrainings(crewId);    
      setCrewUsersTrainings(res);
    })();
  }, [crew]);

  return (  
    <>
      <Typography variant="h4">Crew Users Training</Typography>
      {crewUsersTrainings? (
        <TableComponent
          header={[
            { title: "User ID", style: { width: "5%" } },
            { title: "User" },
            { title: "Training" },
            { title: "Position"},
            { title: "Valid Until"},
            { title: "Start Date" },
            { title: "End Date"},
            { title: "Actions", style: { width: "10%" }},   
          ]}

          renderChildren={(crewUsersTraining) => (
            <TableRow key={crewUsersTraining.unique_key}>
              <TableCell>{crewUsersTraining.userId}</TableCell>
              <TableCell>{crewUsersTraining.fullname}</TableCell>
              <TableCell>{crewUsersTraining.trainingName}</TableCell>
              <TableCell>{crewUsersTraining.trainingPositions}</TableCell>
              <TableCell>{formatDate(crewUsersTraining.validUntil)}</TableCell>
              <TableCell>{formatDate(crewUsersTraining.trainingStartDate)}</TableCell>
              <TableCell>{formatDate(crewUsersTraining.trainingEndDate)}</TableCell>
            
              <TableCell>
                {crewUsersTraining.bought ? (
                <>
                    <Button
                      title={`Training bought: (${crewUsersTraining.trainingCourseId})`}
                      icon={<AssignmentTurnedInIcon />}
                      onClick={() => {}}
                    />
                    <Link to={`/training-users/user/details/${crewUsersTraining.trainingId}/${crewUsersTraining.userId}/${crewUsersTraining.trainingCourseId}`}>
                        <Button
                          title={`Checking progress: (${crewUsersTraining.trainingCourseId})`}
                          icon={<PublishedWithChangesIcon />}
                        />
                    </Link>
                        {crewUsersTraining.hasCertificate ? ( 
                          <>
                            <Button
                              title="Download Certificate"
                              icon={<GetAppIcon />}
                              onClick={() => openExportWindow(crewUsersTraining.trainingCourseId, crewUsersTraining.userId)}
                            />
                          </>
                        ) : null}

                </>
                ) : 
                  <Button
                  title={`Add to cart: (${crewUsersTraining.trainingCourse.id}) ${crewUsersTraining.trainingCourse.name}, ${crewUsersTraining.trainingCourse.price}`}
                  icon={<AddShoppingCartIcon />}
                  onClick={() => shoppingCart.actions.add(crewUsersTraining.trainingCourse, crewUsersTraining.userId)}
                  />
                }   
              </TableCell>


            </TableRow>
          )}
          search={["userId", "fullname", "trainingName"]}
          pagination
          items={crewUsersTrainings}
        />
      ) : null}
      

    </>
  );
};

export default CrewUsersTrainings