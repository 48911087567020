import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import Button from "../../components/Button";
import TableComponent from "../../components/TableComponent";
import { getUsersWritteTest, deleteWrittenTest,  getUsersInscribed } from "../../api/training";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Confirm from "../../components/Confirm";
import GetAppIcon from "@mui/icons-material/GetApp";
import request from "../../api/request"; 


export async function getSSID() {
  const ssid = await request(`/sts`);
  return ssid;
}


const downloadWrittenTest = async (fileName) => {
  const ssid = await getSSID();
  window.open(
    `${process.env.REACT_APP_API_URL}/files/download/written-test/${fileName}?ssid=${ssid}`,
    "_blank"
  );
};

const WrittenTestTrainingInfo = ({ match, history }) => {
  const [usersWrittenTest, setUsersWrittenTest] = useState([]);
  const [selectedTrainingId, setTrainingId] = useState(null);
  
  const [selectedOperationId, setSelectedOperationId] = useState(null);
  const [isDeleteModalShow, setIsDeleteModalShow] = useState(false);
  const [refresh, setRefresh] = useState(1);

  function showDeleteModal(id) {
    setSelectedOperationId(id);
    setIsDeleteModalShow(true);
  }

  async function confirmDeleteModal(isConfirmed) {
    if (isConfirmed) {
      await deleteWrittenTest(selectedOperationId);
    }
    setIsDeleteModalShow(false);
    setRefresh(refresh * -1);
  }


  useEffect(() => {
    (async () => {
      const trainingId = match.params.trainingId;
      const userId = match.params.userId;
      setTrainingId(trainingId);
      const usersWrittenTest = await request(`/users-training/user-written-test/${trainingId}`);  
      setUsersWrittenTest(usersWrittenTest.filter( el => el.user_id == userId));
    })();
  }, [refresh]);


  return (
    <>
      <Typography variant="h4">Written test</Typography>
      {usersWrittenTest? (
        <TableComponent
          header={[
            { title: "User ID", style: { width: "5%" } },
            { title: "Perc Passed"},
            { title: "Completed Date"},
            { title: "Comment"},
            { title: "Download" },
          ]}
          //writtenTestId
          renderChildren={(userWrittenTest,  index) => (
            <TableRow key={index}>
              <TableCell>{userWrittenTest.user_id}</TableCell>

              <TableCell>{userWrittenTest.valid_answer}</TableCell>
              <TableCell>{(new Date(userWrittenTest.completed_date)).toLocaleDateString('de-CH')}</TableCell>
              <TableCell>{userWrittenTest.comment}</TableCell>
              
              <TableCell>
              {userWrittenTest.file_name ? (
                  <>
                  <Button
                    title="Download written test"
                    icon={<GetAppIcon />}
                    onClick={() => downloadWrittenTest(userWrittenTest.file_name)}
                  />
      
                  </>             
                ) : null}                  

              </TableCell>
            </TableRow>
          )}
          search={["user_id","fullname", "company_name"]}
          pagination
          items={usersWrittenTest}
        />
      ) : null}
      <Button title="Back" onClick={() => history.goBack()}>
              Back
      </Button>
      {isDeleteModalShow && selectedOperationId ? (
        <Confirm title={"Delete Written Test"} onConfirm={confirmDeleteModal}>
          <p>Are you sure you want to delete the written Test?</p>
        </Confirm>
      ) : null}
    </>
  );
};

export default WrittenTestTrainingInfo;


