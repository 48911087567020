import React from "react";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CollapsibleListItem from "../List/CollapsibleList";
import PermissionTreeItem from "./PermissionTreeItem";

const RoleTreeItem = ({ nodeId, onClick, onRemove, role }) => {
  const key = `${nodeId}/r${role.id}`;
  const isExpandable = !!role?.permissions?.length;

  return (
    <CollapsibleListItem
      nodeId={key}
      title={`${role.name}`}
      isExpandable={isExpandable}
      icon={<AccountCircleIcon />}
      onClick={onClick}
    >
      {role.permissions?.map((permission) => (
        <PermissionTreeItem
          key={`${key}/p${permission.id}`}
          permission={permission}
          onRemove={onRemove}
          nodeId={key}
        />
      ))}
    </CollapsibleListItem>
  );
};

export default RoleTreeItem;
