import React, { useState, useEffect } from "react";
import TableComponent from "../../components/TableComponent";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { addCockpitPermitRoles, deleteCockpitPermitRoles, getCockpitPermitRoles } from "../../api/ngftApp.js";
import { getRoles } from "../../api/roles.js";
import { StyledSwitch } from "../../components/Base";



const CockpitPermissionRoles = ({ match, history }) => {

  const [roles, setRoles] = useState([]);
  const [refresh, setRefresh] = useState(1);


  useEffect(() => {
    (async () => {
      const newRoles = await getRoles();
      const cockpitPermitRoles = await getCockpitPermitRoles();
      const permitRoles = cockpitPermitRoles.map( role => role.roleId);
      newRoles.forEach(element => {
          if (permitRoles.includes(element.id) ){
            element.status = "active"
          } else {
            element.status = "passive"
          }
      });
      setRoles(newRoles);
    })();
  }, [refresh]);

  const onStatusChange = (roleId, status) => {
    if (status === "passive"){
       // delete row 
       deleteCockpitPermitRoles(roleId);
    } else {
      // insert rom
      addCockpitPermitRoles({roleId});
    }
    setRefresh(refresh * -1);
  };
 
  return (
    <>
    <h2>Roles to add</h2>
    <TableComponent

      header={[
        { title: "Id" },
        { title: "Role" },
        { title: "Add role" , style: { width: "5%" } },
      ]}
      renderChildren={(item) => (
        <TableRow key={item.id}>

          <TableCell>{item.id}</TableCell>
          <TableCell>{item.name}</TableCell>
          <TableCell>
            <StyledSwitch
              title="Change active role"
              checked={item.status === "active"}
              onChange={() =>
                onStatusChange(
                  item.id,
                  item.status === "active" ? "passive" : "active"
                )
              }
            />
          </TableCell>
      </TableRow>
      )}
      items={roles}
      search={["name"]}
      pagination
    />
  </>
  );
};

export default CockpitPermissionRoles;
