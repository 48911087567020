import React from "react";

import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Link from "@mui/material/Link";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Button from "../../components/Button";
import SvgIcon from '@mui/material/SvgIcon';

const NgftAppsItem = ({ onEdit, onDelete, ngft_app }) => {

  return (
    <TableRow>
      <TableCell>{ngft_app.id}</TableCell>
      <TableCell>{ngft_app.name}</TableCell>
      <TableCell>{ngft_app.shortName}</TableCell>
      <TableCell>{ngft_app.color}</TableCell>
      <TableCell>{ngft_app.textColor}</TableCell>
      <TableCell>
            <SvgIcon>
                <path d={ngft_app.icon} />
            </SvgIcon>
      </TableCell>
      <TableCell>
        <Link style={{ marginRight: "1rem" }} onClick={() => onEdit(ngft_app.id)}>
          <Button title="Edit NGFT APP" icon={<EditIcon />} />
        </Link>
        <Link onClick={() => onDelete(ngft_app.id)}>
          <Button
            title="Delete NGFT APP"
            icon={<DeleteForeverIcon color="error" />}
          />
        </Link>
      </TableCell>
    </TableRow>
  );
};

export default NgftAppsItem;
