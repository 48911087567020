import React, { useEffect, useState } from "react";

import Typography from "@mui/material/Typography";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

import DeleteIcon from "@mui/icons-material/Delete";
import { useHistory } from "react-router-dom";

import StripeCheckout from 'react-stripe-checkout';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

import Button from "../../components/Button";
import {
  PageHeader,
  BasePaper,
  Row,
  Text,
  Separator,
  FlexRow,
} from "../../components/Base";
import StyledTable from "../../components/TableComponent";
import BillingAddressSelector from "../billing-form";

import { useShoppingCart } from "../../context/ShoppingCartContext";
import { calculatePrice, payWithStripe, createPayWithInvoice } from "../../api/payment";
import Service from "./service";

import ShoppingCartService from "../../services/payments";
import { useConfirm } from "../../context/ConfirmContext";

import { usePubsub } from "../../hooks/usePubsub";

import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import { getMyInfo, getBillingAddress } from "../../api/user";



const ShoppingCart = ({ match }) => {
  const history = useHistory();
  const { crewId } = match.params;
  const { items, actions } = useShoppingCart();
  const [shoppingItems, setShoppingItems] = useState(null);
  const [total, setTotal] = useState(null);
  const [currency, setCurrency] = useState(null);
  const [userNames, setUserNames] = useState([]);
  const [userPayWithInvoice, setUserPayWithInvoice] = useState(0);
  const [selectedBillingId, setSelectedBillingId] = useState(null);
  const [billingEmail, setBillingEmail] = useState(null);
  const [additionalBillingEmail, setdditionalBillingEmail] = useState(null);
  const [status, setStatus] = useState(null);
  const dialog = useConfirm();

  const [open, setOpen] = useState(false);

  const [refresh, setRefresh] = useState(0);

  const forceRefresh = () => setRefresh((r) => r + 1);

  const publishableKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
  const paypalClientId = process.env.REACT_APP_PAYPAL_CLIENT_ID;
  
  const style = {"layout":"horizontal","color":"silver"};
 

  const handleClose = () => {
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen(true);
  };



  usePubsub("billing/address-changed", forceRefresh);

  useEffect(() => {
    const crewCourses = ShoppingCartService.getCrewItems(crewId);
    setShoppingItems(crewCourses);
  }, [crewId, refresh]);

  useEffect(() => {
    (async () => {
      if (items?.length) {
        const userData = await getMyInfo();
        setUserPayWithInvoice(userData.payWithInvoice)
        
        const address = await getBillingAddress(selectedBillingId);
        setBillingEmail(address.email)
        setdditionalBillingEmail(address.additionalEmail)

        const response = await calculatePrice(items, selectedBillingId);

        const shoppingItems = Service.mapToDisplayObject(
          items,
          response.prices,
          response.bundlePrices,
          response.currency,
          response.names,
        );
        setTotal(response.total);
        setCurrency(response.currency);
        setShoppingItems(shoppingItems);
        setUserNames(response.names);

      }
    })();
  }, [items, refresh]); 



  const payPalExecute = async (details) => {
    const response = await calculatePrice(items, selectedBillingId);
    const shoppingItems = Service.mapToDisplayObject(
      items,
      response.prices,
      response.bundlePrices,
      response.currency,
      response.names,
    );
    setTotal(response.total);
    setCurrency(response.currency);
    setShoppingItems(shoppingItems);
    setUserNames(response.names);
    
    handleToggle();
    if (details.status ==="COMPLETED") {
      
      const retVal = await createPayWithInvoice(items, selectedBillingId, shoppingItems, 'paypal', 'paid', '');
      if (retVal.orderStatus === 'errorInvoice') {
        handleClose();
        dialog.notification(
          "Order not complete:",
          "You have paid but waiting for the invoice"
        );
        history.push(`/order-paid=2`);
      } else if (retVal.orderStatus === 'success') {
        handleClose();
        await actions.clear();
        history.push(`/order-paid=1`);
      } else {
        handleClose();
        await actions.clear();
        history.push(`/order-paid=0`);
      }
    } else {
      handleClose();
      dialog.notification(
        "Order error:",
        "There payment with Paypal is not completed."
      );
      history.push(`/order-paid=0`);      
    }
  };


  const payNow = async token => {
    try {
      handleToggle();
      const response = await payWithStripe(token, total, currency, items, selectedBillingId, shoppingItems, 'stripe');
      if (response.status === 'success') { 
        handleClose();   
        await actions.clear();
        history.push(`/order-paid=1`);
      } else if (response.status === 'error-invoice') { 
        handleClose();   
        await actions.clear();
        history.push(`/order-paid=2`);
      } else if (response.status === 'failure') { 
        handleClose();   
        await actions.clear();
        history.push(`/order-paid=0`);
      } 
    } catch (error) {
      handleClose();
      await actions.clear();
      history.push(`/order-paid=0`);
    }
  };




  return (
    <>
      <PageHeader>Shopping Cart</PageHeader>
      {shoppingItems?.length ? (
        <BasePaper>
          <Row>
            <Typography variant="h5">Items</Typography>
          </Row>
          <Row>
            <StyledTable
              items={shoppingItems || []}
              renderChildren={(item) => (
                <TableRow>
                  <TableCell>
                    ({item.courseId}) {item.courseName}{" "}
                    {item.isBundle ? " (Bundle)" : ""}
                  </TableCell>
                  <TableCell>
                    ({item.userId}){" "}
                    {userNames.find((n) => n.id === item.userId)?.fullname}
                  </TableCell>
                  <TableCell>{item.price}</TableCell>
                  <TableCell>
                    <Button
                      icon={<DeleteIcon />}
                      title="Remove from cart"
                      onClick={async () => {
                        await actions.remove(item);
                        forceRefresh();
                      }}
                    />
                  </TableCell>
                </TableRow>
              )}
            />
          </Row>
          <BillingAddressSelector
            onSelect={(selected) => {
              setSelectedBillingId(selected);
              forceRefresh();
            }}
          />
          <Row>
            Billing email: {billingEmail}
          </Row>
          <Row>
            Additional billing email: {additionalBillingEmail}
          </Row>

{/*           
<BasePaper>
          <Row>
            <Typography variant="h5">Payment old</Typography>
          </Row>
          <Row>
            <FlexRow>
              <div>
                <Button
                  onClick={async () => {
                    if (!selectedBillingId) {
                      dialog.notification(
                        `Billing address required`,
                        `Please specify billing address`
                      );
                      return;
                    }
                    if ((await actions.checkout(selectedBillingId)) === false) {
                      dialog.notification(
                        "Order error",
                        "There was an error placing your order. Please get in touch with our customer support for assistance."
                      );
                    }
                  }}
                >
                  Checkout
                </Button>
                
                <Button
                  onClick={async () => {
                    await actions.clear();
                    forceRefresh();
                  }}
                >
                  Clear cart
                </Button>
                </div>
                <Typography variant="h4">
                <Text>Total: {`${currency} ${total}`}</Text>
              </Typography>
            </FlexRow>
          </Row>
  </BasePaper>
 */}
          <BasePaper>
          <Row>
            <Typography variant="h5">Payment</Typography>

          </Row>
      
          <Row>
            <FlexRow>
              <div>
                    <div style={{ marginBottom: "12px" }}>
                    {userPayWithInvoice == 1 && (
                          <Button //userPayWithInvoice
                          onClick={async () => {
                            handleToggle();
                            if (!selectedBillingId) {
                              handleClose();
                              dialog.notification(
                                `Billing address required`,
                                `Please specify billing address`
                              );
                              return;
                            };
                            const retVal = await createPayWithInvoice(items, selectedBillingId, shoppingItems, 'invoice', 'processing', '');
                            //const retVal = await actions.payWithInvoice(selectedBillingId, shoppingItems, userNames);
                    
                            if (retVal === false || retVal.orderStatus == "failure" ) {
                              handleClose();
                              dialog.notification(
                                "Order error:",
                                "There was an error placing your order. Please get in touch with our customer support for assistance."
                              );
                              history.push(`/order-paid=0`);
                            } else {
                              handleClose();
                              await actions.clear();
                              history.push(`/order-paid=1`);
                            }
                            }}
                          >
                            Pay with invoice
                        </Button>
                        
                    )}

                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={open}
                      >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    <Button
                    onClick={async () => {
                      await actions.clear();
                      forceRefresh();
                    }}
                  >
                    Clear cart
                  </Button>
                    </div>

                    <div>
                    <StripeCheckout
                      stripeKey={publishableKey}
                      label="PAY WITH CREDIT CARD"
                      name="Pay With Credit Card"
                      amount={total*100}
                      currency={ currency}
                      email={billingEmail}
                      description={`Your total is ${currency} ${total}`} 
                      token={payNow}
                    />
                    </div>
                    <div style={{ maxWidth: "750px", minHeight: "200px", paddingTop: "12px" }}>
                      <PayPalScriptProvider options={{ "client-id": paypalClientId,  currency: 'CHF'}}>
                          <PayPalButtons
                              forceReRender={[total, currency]}
                              fundingSource="paypal"
                              style={style}
                              createOrder={(data, actions) => {
                                  
                                  return actions.order.create({
                                      purchase_units: [
                                          {
                                            description: "Ngft Invoice",
                                              amount: {
                                                currency_code: currency,
                                                value: total,
                                              },
                                          },
                                      ],
                                  });
                              }}
                              onApprove={(data, actions) => {
                                  return actions.order.capture().then((details) => {
                                      payPalExecute(details);  
                                  });
                              }}
                          />
                      </PayPalScriptProvider>
                    </div>
            </div>

              <Typography variant="h4">
                <Text>Total: {`${currency} ${total}`}</Text>
              </Typography>
            </FlexRow>
          </Row>
          
              
          </BasePaper>
        </BasePaper>
      ) : (
        <Typography variant="h4">Your shopping cart is empty</Typography>
      )}
    </>
  );
};

export default ShoppingCart;
