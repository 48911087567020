import { useState, useEffect } from "react";

import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Tabs from "@mui/material/Tabs";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { styled } from "@mui/material/styles";

import Button from "./Button";

export const Row = ({ children, ...props }) => (
  <Box mb={2} {...props}>
    {children}
  </Box>
);

export const FlexRow = ({ children, ...props }) => (
  <Row
    display="flex"
    justifyContent="space-between"
    alignItems="center"
    {...props}
  >
    {children}
  </Row>
);

export const BasePaper = ({ children, className = "" }) => (
  <Row className={className}>
    {/* TODO: we might maxWidth limitation (e.g. 600px), but it needs to be proved */}
    <Paper variant="outlined" style={{ padding: "1rem" }}>
      {children}
    </Paper>
  </Row>
);

export const ErrorMessage = ({ error }) =>
  error ? <Typography color="error">{error}</Typography> : null;

export const Message = ({ message }) =>
  message ? <Typography color="success">{message}</Typography> : null;

export const PageHeader = ({ children }) => (
  <Row>
    <Typography variant="h4">{children}</Typography>
  </Row>
);

export const Text = ({ children, title, titlePlacement = "bottom" }) => {
  if (title) {
    return (
      <Tooltip title={title} placement={titlePlacement} fontSize="large">
        <span>{children}</span>
      </Tooltip>
    );
  }
  return children;
};

export const StyledPaper = styled(Paper)({
  padding: "1rem",
});

export const StyledTabs = styled(Tabs)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  borderRadius: theme.shape.borderRadius,
  border: `1px solid lightgrey`,
}));

export const StyledSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase": {
    color: "#656b74",
    "&.Mui-checked": {
      color: "#536b86",
    },
    "&.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "#656b74",
    },
  },
  "& .MuiSwitch-track": {},
}));

export const BackButton = ({ history }) => (
  <Button
    title="Go Back"
    onClick={() => history.goBack()}
    icon={<ArrowBackIcon />}
  />
);

export const Separator = () => <div style={{ marginRight: "1rem" }} />;

export const StyledInput = ({
  label,
  type,
  required,
  value,
  onChange,
  validate,
  fullWidth = false,
  disabled = false,
  error = null,
}) => {
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    setErrorMessage(error);
  }, [error]);
  const onChangeValidate = ({ target }) => {
    const value = target.value;
    if (required && !value) {
      setErrorMessage("Field is required");
      return;
    } else if (validate) {
      const errorMessage = validate(value);
      if (errorMessage) setErrorMessage(errorMessage);
      return;
    }
    setErrorMessage(null);
    onChange(value);
  };

  return (
    <TextField
      type={type}
      error={!!errorMessage}
      helperText={errorMessage || ""}
      fullWidth={fullWidth}
      value={value}
      onChange={onChangeValidate}
      label={label}
      disabled={disabled}
      size="small"
      style={{ flex: 1 }}
    />
  );
};
