let id = 1;
const idGen = () => id++;

export const onFratLoadUpdateIdGen = (frat) => {
  updateSectionId(frat.preflightChecklist || {});
  updateSectionId(frat.inflightChecklist || {});
  updateSectionId(frat.postflightChecklist || {});
};

const updateSectionId = ({ sections = [] }) => {
  for (const section of sections) {
    if (section.id >= id) id = section.id + 1;
    for (const question of section.questions || []) {
      if (question.id >= id) id = question.id + 1;
      for (const answer of question.answers || []) {
        if (answer.id >= id) id = answer.id + 1;
      }
      for (const mitigation of question.mitigationMeasures || []) {
        if (mitigation.id >= id) id = mitigation.id + 1;
      }
    }
  }
};

export const checklistActions = (getter, setter) => ({
  add() {
    const newItem = {
      id: idGen(),
      sections: [],
    };
    setter(newItem);
  },
  addSection() {
    const list = getter();
    const item = { id: idGen(), questions: [] };
    setter({ ...list, sections: [...(list.sections || []), item] });
  },
  setSectionName(sectionId, name) {
    const list = getter();
    const sections = [];
    for (const section of list.sections) {
      if (section.id === sectionId) {
        sections.push({ ...section, name });
      } else {
        sections.push(section);
      }
    }
    setter({ ...list, sections });
  },
  removeSection(sectionId) {
    const list = getter();
    const res = [];
    for (const section of list.sections) {
      if (section.id !== sectionId) {
        res.push(section);
      }
    }
    setter({ ...list, sections: res });
  },
  addQuestion({ sectionId }) {
    const list = getter();
    const sections = list.sections;
    const res = [];
    for (const section of sections) {
      if (section.id === sectionId) {
        res.push({
          ...section,
          questions: [
            ...section.questions,
            {
              id: idGen(),
              description: "",
              question: "",
              answers: [],
              mitigationMeasures: [],
            },
          ],
        });
      } else {
        res.push(section);
      }
    }
    setter({ ...list, sections: res });
  },
  setQuestionProp(sectionId, questionId, propName, value) {
    const list = getter();
    const res = [];
    for (const section of list.sections) {
      if (section.id === sectionId) {
        const questions = [];
        for (const question of section.questions) {
          if (question.id === questionId) {
            questions.push({ ...question, [propName]: value });
          } else {
            questions.push(question);
          }
        }
        res.push({ ...section, questions });
      } else {
        res.push(section);
      }
    }
    setter({ ...list, sections: res });
  },
  removeQuestion(sectionId, questionId) {
    const list = getter();
    const res = [];
    for (const section of list.sections) {
      if (section.id === sectionId) {
        const questions = [];
        for (const question of section.questions) {
          if (question.id !== questionId) {
            questions.push(question);
          }
        }
        res.push({ ...section, questions });
      } else {
        res.push(section);
      }
    }
    setter({ ...list, sections: res });
  },
  addAnswer(sectionId, questionId) {
    const list = getter();
    const res = [];
    for (const section of list.sections) {
      if (section.id === sectionId) {
        const questions = [];
        for (const question of section.questions) {
          if (question.id === questionId) {
            questions.push({
              ...question,
              answers: [
                ...question.answers,
                {
                  id: idGen(),
                  severity: 0,
                  answer: "",
                },
              ],
            });
          } else {
            questions.push(question);
          }
        }
        res.push({ ...section, questions });
      } else {
        res.push(section);
      }
    }
    setter({ ...list, sections: res });
  },
  setAnswerProp(sectionId, questionId, answerId, propName, value) {
    const list = getter();
    const res = [];
    for (const section of list.sections) {
      if (section.id === sectionId) {
        const questions = [];
        for (const question of section.questions) {
          if (question.id === questionId) {
            const answers = [];
            for (const answer of question.answers) {
              if (answer.id === answerId) {
                answers.push({ ...answer, [propName]: value });
              } else {
                answers.push(answer);
              }
            }
            questions.push({ ...question, answers });
          } else {
            questions.push(question);
          }
        }
        res.push({ ...section, questions });
      } else {
        res.push(section);
      }
    }
    setter({ ...list, sections: res });
  },
  removeAnswer(sectionId, questionId, answerId) {
    const list = getter();
    const res = [];
    for (const section of list.sections) {
      if (section.id === sectionId) {
        const questions = [];
        for (const question of section.questions) {
          if (question.id === questionId) {
            const answers = [];
            for (const answer of question.answers) {
              if (answer.id !== answerId) {
                answers.push(answer);
              }
            }
            questions.push({ ...question, answers });
          } else {
            questions.push(question);
          }
        }
        res.push({ ...section, questions });
      } else {
        res.push(section);
      }
    }
    setter({ ...list, sections: res });
  },
  addMitigation(sectionId, questionId) {
    const list = getter();
    const res = [];
    for (const section of list.sections) {
      if (section.id === sectionId) {
        const questions = [];
        for (const question of section.questions) {
          if (question.id === questionId) {
            questions.push({
              ...question,
              mitigationMeasures: [
                ...question.mitigationMeasures,
                {
                  id: idGen(),
                  reduction: 0,
                  level: 0,
                  mitigationMeasure: "",
                },
              ],
            });
          } else {
            questions.push(question);
          }
        }
        res.push({ ...section, questions });
      } else {
        res.push(section);
      }
    }
    setter({ ...list, sections: res });
  },
  setMitigationProp(sectionId, questionId, mitigationId, propName, value) {
    const list = getter();
    const res = [];
    for (const section of list.sections) {
      if (section.id === sectionId) {
        const questions = [];
        for (const question of section.questions) {
          if (question.id === questionId) {
            const mitigationMeasures = [];
            for (const mitigation of question.mitigationMeasures) {
              if (mitigation.id === mitigationId) {
                mitigationMeasures.push({ ...mitigation, [propName]: value });
              } else {
                mitigationMeasures.push(mitigation);
              }
            }
            questions.push({ ...question, mitigationMeasures });
          } else {
            questions.push(question);
          }
        }
        res.push({ ...section, questions });
      } else {
        res.push(section);
      }
    }
    setter({ ...list, sections: res });
  },
  removeMitigation(sectionId, questionId, mitigationId) {
    const list = getter();
    const res = [];
    for (const section of list.sections) {
      if (section.id === sectionId) {
        const questions = [];
        for (const question of section.questions) {
          if (question.id === questionId) {
            const mitigationMeasures = [];
            for (const mitigation of question.mitigationMeasures) {
              if (mitigation.id !== mitigationId) {
                mitigationMeasures.push(mitigation);
              }
            }
            questions.push({ ...question, mitigationMeasures });
          } else {
            questions.push(question);
          }
        }
        res.push({ ...section, questions });
      } else {
        res.push(section);
      }
    }
    setter({ ...list, sections: res });
  },
});
