import React from "react";

import { Box, IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import EditIcon from "@mui/icons-material/Edit";
import SchoolIcon from "@mui/icons-material/School";
import { StyledSwitch } from "../Base";
import Button from "../Button";


const UserRow = ({
  onShowCertificates,
  onStatusChange,
  onInvoiceChange,
  onEdit,
  onSetRole,
  user,
}) => {

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <TableRow className="custom-row">
      <TableCell>{user.id}</TableCell>
      <TableCell>{user.fullname}</TableCell>
      <TableCell>{user.email}</TableCell>
      <TableCell>{user.crews.join(", ")}</TableCell>
      <TableCell>{user.country}</TableCell>
      <TableCell className="t-none">
        <StyledSwitch
          title="Change user status"
          checked={user.status === "active"}
          onChange={() =>
            onStatusChange(
              user.id,
              user.status === "active" ? "passive" : "active"
            )
          }
        />
      </TableCell>
 
      <TableCell className="t-none">
        <StyledSwitch
          title="Change invoice status"
          checked={user.pay_with_invoice === 1}
          onChange={() =>
            onInvoiceChange(
              user.id,
              user.pay_with_invoice === 1 ? (0  || null)   : 1
            )
          }
        />
      </TableCell>
      <TableCell className="t-none">
        <Button
          title="All Completed Courses/Certificates"
          onClick={() => onShowCertificates(user.id)}
          icon={<SchoolIcon />}
        />
        <Button
          title="Edit user"
          onClick={() => onEdit(user.id)}
          icon={<EditIcon />}
        />
        {onSetRole && user.id !== 1 ? (
          <Button
            onClick={() => onSetRole(user.id)}
            title="Assign member role"
            icon={<AccountCircleIcon />}
          />
        ) : null}
      </TableCell>
      <TableCell className="d-none">
       <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.6899 12C11.6899 11.8323 11.8322 11.69 11.9999 11.69C12.1677 11.69 12.3099 11.8323 12.3099 12C12.3099 12.1677 12.1677 12.31 11.9999 12.31C11.8322 12.31 11.6899 12.1677 11.6899 12Z" fill="#151B28" stroke="#151B28" strokeWidth="2"/>
            <path d="M6.99994 10.69C6.27994 10.69 5.68994 11.28 5.68994 12C5.68994 12.72 6.27994 13.31 6.99994 13.31C7.71994 13.31 8.30994 12.72 8.30994 12C8.30994 11.28 7.71994 10.69 6.99994 10.69Z" fill="#151B28"/>
            <path d="M16.9999 10.69C16.2799 10.69 15.6899 11.28 15.6899 12C15.6899 12.72 16.2799 13.31 16.9999 13.31C17.7199 13.31 18.3099 12.72 18.3099 12C18.3099 11.28 17.7199 10.69 16.9999 10.69Z" fill="#151B28"/>
            </svg>

          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem>
          <StyledSwitch
            title="Change user status"
            checked={user.status === "active"}
            onChange={() =>
              onStatusChange(
                user.id,
                user.status === "active" ? "passive" : "active"
              )
            }
          /> Status
        </MenuItem>
        <MenuItem>
          <StyledSwitch
            title="Change invoice status"
            checked={user.pay_with_invoice === 1}
            onChange={() =>
              onInvoiceChange(
                user.id,
                user.pay_with_invoice === 1 ? (0  || null)   : 1
              )
            }
          /> Pay with Invoice
        </MenuItem>
        <MenuItem onClick={() => onEdit(user.id)}>
          <Button
            size="large"
            title="Edit user"
            icon={<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="svg-icon">
+                  <path d="M11.05 3L4.20829 10.2417C3.94996 10.5167 3.69996 11.0583 3.64996 11.4333L3.34162 14.1333C3.23329 15.1083 3.93329 15.775 4.89996 15.6083L7.58329 15.15C7.95829 15.0833 8.48329 14.8083 8.74162 14.525L15.5833 7.28333C16.7666 6.03333 17.3 4.60833 15.4583 2.86667C13.625 1.14167 12.2333 1.75 11.05 3Z" stroke="#909CA9" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
+                  <path d="M9.90833 4.20833C10.2667 6.50833 12.1333 8.26667 14.45 8.5" stroke="#909CA9" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
+                  <path d="M2.5 18.3333H17.5" stroke="#909CA9" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                }
          />
          Edit
        </MenuItem>
        <MenuItem onClick={() => onSetRole(user.id)}>
        {onSetRole && user.id !== 1 ? (
          <div>
            <Button
            size="large"
            title="Assign member role"
            icon={<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="svg-icon">
+                  <path d="M10.1001 10.65C10.0417 10.6417 9.96672 10.6417 9.90006 10.65C8.43339 10.6 7.26672 9.4 7.26672 7.925C7.26672 6.41667 8.48339 5.19167 10.0001 5.19167C11.5084 5.19167 12.7334 6.41667 12.7334 7.925C12.7251 9.4 11.5667 10.6 10.1001 10.65Z" stroke="#909CA9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
+                  <path d="M15.6166 16.15C14.1333 17.5083 12.1666 18.3333 9.99997 18.3333C7.8333 18.3333 5.86663 17.5083 4.3833 16.15C4.46663 15.3667 4.96663 14.6 5.8583 14C8.14163 12.4833 11.875 12.4833 14.1416 14C15.0333 14.6 15.5333 15.3667 15.6166 16.15Z" stroke="#909CA9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
+                  <path d="M9.99996 18.3333C14.6023 18.3333 18.3333 14.6024 18.3333 10C18.3333 5.39762 14.6023 1.66666 9.99996 1.66666C5.39759 1.66666 1.66663 5.39762 1.66663 10C1.66663 14.6024 5.39759 18.3333 9.99996 18.3333Z" stroke="#909CA9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                }
          /> Profile
          </div>
        ) : null}
        </MenuItem>
      </Menu>
      </TableCell>
    </TableRow>
  );
};

export default UserRow;