import React, { useState, useEffect } from "react";

import Typography from "@mui/material/Typography";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Form, InputForm, Validation } from "../../components/Form";
import Autocomplete from "../../components/Form/StyledAutocomplete";
import { BasePaper, Row } from "../../components/Base";
import Button from "../../components/Button";
import { getObservable, createObservable, updateObservable } from "../../api/observable";


const testTypes = [ "assessment", "check"];

const areas = ["dangerous good","other"];

const statusAll = ["active","passive"];

const EditObservable = ({ match, history }) => {
  const { observableId } = match.params;
  const isAdd = !observableId;
  const [observable, setObservableRes] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [selectedTestType, setSelectedTestType] = useState(null);
  const [selectedArea, setSelectedArea] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);

  useEffect(() => {
    (async () => {
      if (observableId) {
        const observableRes = await getObservable(observableId);
        setObservableRes(observableRes);
        setSelectedTestType(observableRes.test_type);
        setSelectedArea(observableRes.area);
        setSelectedStatus(observableRes.status);
      }
      setLoaded(true);
    })();
  }, [observableId]);

  const goBack = () => history.goBack();

  const onSubmit = async (values) => {
    let result;

    values.test_type = selectedTestType
    values.area = selectedArea
    values.status = selectedStatus


    if (isAdd) {
      result = await createObservable(values);
    } else {
      result = await updateObservable(observableId, values);
    }
    if (result.ok) {
      history.push("/observable");
    } 
  };

  const title = isAdd ? "Add new observable" : "Edit observable";

  return (     
    <>
      <Typography variant="h4">
        <Button icon={<ArrowBackIcon />} onClick={goBack} />
        {title}
      </Typography>
      {loaded ? (
        <BasePaper>
          <Form
            defaultValues={{
              name: observable?.name,
            }}
            validation={{
              name: Validation.required,
            }}
            onSubmit={onSubmit}
          >
            <Row>
              <InputForm name="name" label="Observable name*" />
            </Row>

            <Row>
              <Autocomplete
                    label="Test type"
                    items={testTypes}
                    selected={selectedTestType}
                    onChange={(items) => {
                      setSelectedTestType(items);
                    }}
                    formatLabel={(item) => `${item}`}
              />
            </Row>  

            <Row>
              <Autocomplete
                    label="Area"
                    items={areas}
                    selected={selectedArea}
                    onChange={(items) => {
                      setSelectedArea(items);
                    }}
                    formatLabel={(item) => `${item}`}
              />
            </Row>  

            <Row>
              <Autocomplete
                    label="Status"
                    items={statusAll}
                    selected={selectedStatus}
                    onChange={(items) => {
                      setSelectedStatus(items);
                    }}
                    formatLabel={(item) => `${item}`}
              />
            </Row>            

            <Row>
              <Button type="submit">Save</Button>
              <Button onClick={goBack}>Cancel</Button>
            </Row>
          </Form>
        </BasePaper>
      ) : null}
    </>
  );
};

export default EditObservable;
