import React from "react";

import Typography from "@mui/material/Typography";

import ExpandableSection from "../../components/ExpandableSection";
import AircraftList from "../../components/Certificate/Aircraft";
import AircraftService from "./service";
import CourseStatusIcon from "../../components/CourseStatusIcon";


function findHideCourse(course, hideCourses, typeCourse) {
  const newArray = hideCourses.filter(function (el) {
   return el.category_id === course.categoryId &&
          el.operation_id === course.positionId &&
          el.specialization_id === course.operationId &&
          el.type_id === course.aircraftTypeId &&
          el.type === typeCourse;
   });
   if (newArray.length >0 ){
     return false
   } else {
     return true
   }
 }

const Title = ({ item: { title, status } }) => (
  <>
    <CourseStatusIcon status={status} />
    <Typography variant={"body1"} style={{ marginLeft: ".5rem" }}>
      {title}
    </Typography>
  </>
);

const Aircraft = ({ certificates, courses, properties, aircraft,hideAdminCourses }) => {
  const aircraftWithPositions = AircraftService.getAircraft(
    aircraft,
    courses,
    properties
  );


  if (!hideAdminCourses || hideAdminCourses=== null ) {
    hideAdminCourses = [];
  }

  courses.courses = courses.courses.filter((elem) => {
    return findHideCourse(elem, hideAdminCourses, "moodle")
  });

  courses.customCourses = courses.customCourses.filter((elem) => {
      return findHideCourse(elem, hideAdminCourses, "custom")
  });

  

  const aircraftDisplay = AircraftService.toDisplayObject(
    aircraftWithPositions,
    certificates,
    courses.relations
  );



  return (
    <>
      {aircraftDisplay.map((displayObj, index) => (
        <ExpandableSection
          key={`aircraft-${index}`}
          title={<Title item={displayObj} />}
        >
          <AircraftList aircraft={displayObj.aircraft} />
        </ExpandableSection>
      ))}
    </>
  );
};

export default Aircraft;
