import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import useCrew from "../../hooks/useCrew";
import useUser from "../../hooks/useUser";
import React, { useState, useEffect } from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Button from "../../components/Button";
import TableComponent from "../../components/TableComponent";
import { getUserCrewTrainings  } from "../../api/training";
import DateRangeIcon from '@mui/icons-material/DateRange';
import QuizIcon from '@mui/icons-material/Quiz';
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import { convertDateTime } from "../../services/formatters";
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';



function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const TrainerTrainings = ({ match, history }) => {
  const { t } = useTranslation();
  const [userCrewTrainings, setUserCrewTrainings] = useState([]);
  const user = useUser();     
  const  {crew}  = useCrew();   
  const eventType = 'trainer';

  useEffect(() => {
    (async () => {
      
      const res = await getUserCrewTrainings(eventType, user.id, crew.id)
      setUserCrewTrainings(res);
    })();
  }, []);

  return (  
    <>
      <Typography variant="h4">Trainer List</Typography>  
      {userCrewTrainings? (
        <TableComponent
          header={[
            { title: "ID", style: { width: "5%" } },
            { title: "Training" },
            { title: "Status" },
            { title: "e-learning"},
            { title: "Classroom"},
            { title: "Practical"},
            { title: "Actions" },
          ]}


          renderChildren={(userCrewTraining) => (
            <TableRow key={userCrewTraining.id}>
              <TableCell>{userCrewTraining.training_id}</TableCell>
              <TableCell>{userCrewTraining.displayname}</TableCell>
              <TableCell>{userCrewTraining.status}</TableCell>

              <TableCell>    
                {userCrewTraining.is_e_learning   ? "Yes" : "No"}
              </TableCell>
              <TableCell>
                  {userCrewTraining.is_classroom   ? "Yes" : "No"}
              </TableCell>
              <TableCell>
                  {userCrewTraining.is_practical   ? "Yes" : "No"}
              </TableCell> 

              <TableCell>
                  <Button
                    icon={<PublishedWithChangesIcon />}
                    onClick={() => history.push(`/training-users/user/${userCrewTraining.training_id}`)}
                    title="Users Checking progress"
                  />

                  <Button
                    icon={<DateRangeIcon />}
                    onClick={() => history.push(`/trainings/schedule/${eventType}/${userCrewTraining.training_id}`)} 
                    title="Scheduled training"
                  />

                  {userCrewTraining.written_test ===1   ? ( 
                    <Button
                    icon={<QuizIcon />}
                    onClick={() => history.push(`/trainings/written-test/${userCrewTraining.training_id}`)}
                    title="Written test"
                    />
                  ) : ""}

                  <Button
                    icon={<LibraryAddCheckIcon />}
                    onClick={() => history.push(`/trainings/checking-progress/${eventType}/${userCrewTraining.training_id}/${userCrewTraining.classroom_attendance}/${userCrewTraining.written_test || userCrewTraining.training.classroom_check}`)}
                    title="Trainiers Checking progress"
                  />

              </TableCell>

            </TableRow>
          )}
          search={["displayname","fullname", "company_name"]}
          pagination
          items={userCrewTrainings}
        />
      ) : null}
      

    </>
  );
};

export default TrainerTrainings