const isAircraft = (entity) => !!entity?.aircraftTypeId;
const notAircraft = (entity) => !entity?.aircraftTypeId;

const byCategory = (categoryId) => (entity) =>
  !entity.positionId && entity.categoryId === categoryId;

const byPosition = (categoryId, positionId) => (entity) =>
  entity.categoryId === categoryId &&
  entity.positionId === positionId &&
  !entity.operationId;

const byOperation = (positionId, operationId) => (entity) =>
  entity.operationId === operationId && entity.positionId === positionId;

const byId = (id) => (entity) => entity.id === id;

const buildCourseMatcher = ({ courses, customCourses, groups }) => (
  matcher
) => ({
  courses: courses.filter(matcher),
  customCourses: customCourses.filter(matcher),
  groups: groups.filter(matcher),
});

const buildOperationsMatcher = (operations) => (matcher) =>
  operations.filter(matcher);

const buildPositionsMatcher = (positions) => (matcher) =>
  positions.filter(matcher);

const filterCourses = (fn) => ({ courses, customCourses, groups }) => ({
  courses: courses.filter(fn),
  customCourses: customCourses.filter(fn),
  groups: groups.filter(fn),
});

const getAircraft = (aircraftList) => (courses) => {
  const ret = {};

  const toAircraft = (prop) => (course) => {
    const aircraftId = course.aircraftTypeId;
    const aircraft = aircraftList.find(byId(aircraftId));
    if (!ret[aircraftId]) {
      ret[aircraftId] = {
        id: aircraftId,
        name: aircraft.name,
        courses: [],
        customCourses: [],
        groups: [],
        specializations: aircraft.specializations || [],
      };
    }
    if (course.specializationId) {
      let specialization = ret[aircraftId].specializations.find(
        byId(course.specializationId)
      );
      if (specialization) {
        specialization.courses = [];
        specialization.customCourses = [];
        specialization.groups = [];
      }
      if (!specialization) {
        const special = aircraft?.specializations?.find(
          byId(course.specializationId)
        );
        if (special) {
          specialization = {
            id: special.id,
            name: special.name,
            courses: [],
            customCourses: [],
            groups: [],
          };
          ret[aircraftId].specializations.push(specialization);
        }
      }
      specialization && specialization[prop].push(course);
    } else {
      ret[aircraftId][prop].push(course);
    }
  };

  courses.courses.forEach(toAircraft("courses"));
  courses.customCourses.forEach(toAircraft("customCourses"));
  courses.groups.forEach(toAircraft("groups"));

  return Object.values(ret);
};

export const buildRelations = (courses) => {
  const tree = {};

  const courseMatcher = buildCourseMatcher(filterCourses(notAircraft)(courses));
  const aircraftCourseMatcher = buildCourseMatcher(
    filterCourses(isAircraft)(courses)
  );

  const positionsMatcher = buildPositionsMatcher(courses.relations.positions);
  const operationsMatcher = buildOperationsMatcher(
    courses.relations.operations
  );
  const aircraftMatcher = getAircraft(courses.relations.aircraft);

  for (const category of courses.relations.categories) {
    const categoryMatcher = byCategory(category.id);

    const positions = positionsMatcher(categoryMatcher).map((position) => {
      const positionId = position.id;
      const positionMatchFn = byPosition(category.id, positionId);
      const { courses, customCourses, groups } = courseMatcher(positionMatchFn);
      const aircraftCourses = aircraftCourseMatcher(positionMatchFn);

      const operations = position.operations.map((operationId) => {
        const operationMatchFn = byOperation(positionId, operationId);
        const operation = operationsMatcher(byId(operationId))[0];
        const { courses, customCourses, groups } = courseMatcher(
          operationMatchFn
        );

        const aircraftCourses = aircraftCourseMatcher(operationMatchFn);

        return {
          ...operation,
          courses,
          customCourses,
          groups,
          aircraft: aircraftMatcher(aircraftCourses),
        };
      });

      return {
        ...position,
        courses,
        customCourses,
        groups,
        operations,
        aircraft: aircraftMatcher(aircraftCourses),
      };
    });

    const { courses, customCourses, groups } = courseMatcher(categoryMatcher);
    const aircraftCourses = aircraftCourseMatcher(categoryMatcher);

    tree[category.id] = {
      ...category,
      courses,
      customCourses,
      groups,
      positions,
      aircraft: aircraftMatcher(aircraftCourses),
    };
  }

  return Object.values(tree);
};

const getCourses = ({ courses, customCourses, groups }) => {
  const all = [];

  all.push(
    ...courses?.map((c) => ({
      id: c.id,
      name: c.name,
    }))
  );

  all.push(
    ...customCourses?.map((cc) => ({
      id: `cc_${cc.id}`,
      name: cc.name,
      isCustom: true,
    }))
  );

  all.push(
    ...groups?.map((gc) => ({
      id: `gc_${gc.id}`,
      name: gc.name,
      isGroup: true,
    }))
  );

  return all;
};

const RelationsService = {
  buildRelations,
  getCourses,
};

export default RelationsService;
