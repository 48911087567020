import React from "react";
import { Switch, Route } from "react-router-dom";

import RelationsTree from "../../features/relations-tree";

const RelationPage = ({ match }) => (
  <Switch>
    <Route
      path={[`${match.path}/:crewId`, `${match.path}`]}
      component={RelationsTree}
    />
  </Switch>
);

export default RelationPage;
