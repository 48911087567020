import React from "react";

import Link from "@mui/material/Link";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { StyledSwitch } from "../../components/Base";

import Button from "../../components/Button";

const GroupAircraftItem = ({ onEdit, onDelete, onStatusChange, groupAircraft }) => {
  return (
    <TableRow>
      <TableCell>{groupAircraft.id}</TableCell>
      <TableCell>{groupAircraft.name}</TableCell>
      <TableCell>
        <StyledSwitch
          title="Change groupAircraft status"
          checked={groupAircraft.status === "active"}
          onChange={() =>
            onStatusChange(
              groupAircraft.id,
              groupAircraft.status === "active" ? "passive" : "active"
            )
          }
        />
      </TableCell>
      <TableCell>
        <Link
          style={{ marginRight: "1rem" }}
          onClick={() => onEdit(groupAircraft.id)}
        >
          <Button title="Edit groupAircraft" icon={<EditIcon />} />
        </Link>
        <Link onClick={() => onDelete(groupAircraft.id)}>
          <Button
            title="Delete group aircraft"
            icon={<DeleteForeverIcon color="error" />}
          />
        </Link>
      </TableCell>
    </TableRow>
  );
};

export default GroupAircraftItem;
