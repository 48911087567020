import React from "react";

import { Switch, Route } from "react-router";
import AircraftTable from "../../features/aircraft-table";
import AircraftAdd from "../../features/aircraft-add";
import AircraftEdit from "../../features/aircraft-edit";

const Aircraft = ({ match, history }) => {
  return (
    <>
      <Switch>
        <Route exact path={`${match.path}`} component={AircraftTable} />

        <Route
          exact
          path={`${match.path}/add_aircraft`}
          component={AircraftAdd}
        />
        <Route
          exact
          path={`${match.path}/:aircraftId`}
          component={AircraftEdit}
        />
      </Switch>
    </>
  );
};

export default Aircraft;
