import React from "react";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import DeleteIcon from "@mui/icons-material/Delete";

import { useCourseRelationsContext } from "../../context/CourseRelationsContext";
import Button from "../Button";

const CourseActions = ({ course }) => {
  const actions = useCourseRelationsContext();
  if (course.crewId || actions.isSuperAdmin()) {
    return (
      <Button
        title={"Remove relation"}
        icon={<DeleteIcon />}
        onClick={() => {
          actions.deleteRelation(course);
        }}
      />
    );
  }
  if (course.hidden) {
    return (
      <Button
        title={`Show this course for this position`}
        icon={<VisibilityIcon />}
        onClick={() => {
          actions.toggleCourseVisibility(course);
        }}
      />
    );
  }

  return (
    <Button
      title={`Hide this course for this position`}
      icon={<VisibilityOffIcon variant="outlined" />}
      onClick={() => {
        actions.toggleCourseVisibility(course);
      }}
    />
  );
};

export default CourseActions;
