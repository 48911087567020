import React from "react";

import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";

import Button from "../Button";
import Autocomplete from "../Form/StyledAutocomplete";
import { useTranslation } from "react-i18next";


const RolesTreeForm = ({
  roles,
  permissions,
  selectedRole,
  selectedPermissions,
  onChange,
  onSave,
  onReset,
  error,
}) => {
  const { t } = useTranslation();
  return (
    <Paper variant="outlined" style={{ marginBottom: "2rem", padding: "1rem" }}>
      <Box mb={"1rem"} display={"flex"}>
        <Box width={"50%"} mr={1}>
          <Autocomplete
            onChange={(item, reason) => onChange("role", item, reason)}
            selected={selectedRole}
            items={roles || []}
            label={t('select_role')}
          />
        </Box>
        <Box width={"50%"}>
          <Autocomplete
            onChange={(item, reason) => onChange("permissions", item, reason)}
            selected={selectedPermissions || []}
            items={permissions || []}
            label={t('select_permission')}
            multiple={true}
          />
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Button onClick={onSave}>Add</Button>
        {selectedRole && selectedPermissions && selectedRole.crewId ? (
          <Button onClick={() => onReset(selectedRole)}>
            Reset to defaults
          </Button>
        ) : null}
      </Box>
      {error ? <Typography color="error">{error}</Typography> : null}
    </Paper>
  );
};

export default RolesTreeForm;
