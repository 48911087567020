import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

import EditIcon from "@mui/icons-material/Edit";

import PostAddIcon from "@mui/icons-material/PostAdd";

import Button from "../../../components/Button";
import { StyledSwitch } from "../../../components/Base";
import TableComponent from "../../../components/TableComponent";

import {
  getBundles,
  changeBundleStatus,
  changeBundleRefresher,
} from "../../../api/courseBundle";

const CourseBundle = ({ match, history }) => {
  const [bundles, setBundles] = useState(null);
  const [refresh, setRefresh] = useState(0);

  useEffect(() => {
    (async () => {
      const res = await getBundles();
      setBundles(res);
    })();
  }, [refresh]);

  return (
    <>
      <Typography variant="h4">Course Bundles</Typography>
      {bundles ? (
        <TableComponent
          TopComponent={
            <Button size="small" onClick={() => history.push(`/bundles/add`)}>
              <PostAddIcon />
              Add New Bundle
            </Button>
          }
          search={["displayname", "description"]}
          header={[
            { title: "ID", style: { width: "5%" } },
            { title: "Name", style: { width: "10%" } },
            { title: "Description", style: { width: "10%" } },
            { title: "Price", style: { width: "10%" } },
            { title: "Notes", style: { width: "10%" } },
            { title: "Status", style: { width: "10%" } },
            { title: "Refresher", style: { width: "10%" } },
            { title: "Actions", style: { width: "10%" } },
          ]}
          items={bundles}
          renderChildren={(bundle) => (
            <TableRow key={bundle.id}>
              <TableCell>{bundle.id}</TableCell>
              <TableCell>{bundle.displayname}</TableCell>
              <TableCell>{bundle.description}</TableCell>
              <TableCell>{bundle.price || "Free"}</TableCell>
              <TableCell>
                {bundle.validPeriod ? `${bundle.validPeriod} months` : ""}
              </TableCell>
              <TableCell>
                <StyledSwitch
                  title="Change bundle status"
                  checked={bundle.active}
                  onClick={async () => {
                    await changeBundleStatus(bundle.id, !bundle.active);
                    setRefresh(refresh + 1);
                  }}
                />
              </TableCell>
              <TableCell>
                <StyledSwitch
                  title="Change bundle refresher"
                  checked={bundle.isRefresher}
                  onClick={async () => {
                    await changeBundleRefresher(bundle.id, !bundle.isRefresher);
                    setRefresh(refresh + 1);
                  }}
                />
              </TableCell>
              <TableCell>
                <Button
                  icon={<EditIcon />}
                  onClick={() => history.push(`/bundles/edit/${bundle.id}`)}
                  title="Edit bundle"
                />
              </TableCell>
            </TableRow>
          )}
        />
      ) : null}
    </>
  );
};

export default CourseBundle;
