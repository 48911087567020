import { useEffect } from "react";

/**
 * @param fn Function to call
 * @param interval period
 * @param deps [optional] optional array with external dependencies - indicate
 *  that useEffect should be "relaunched"
 * @return method which stops interval if needed
 */
export const useTimer = (fn, interval, deps = []) => {
  let running = true;
  let timeout = null;
  let handler = () => {
    if (running) {
      fn();
      timeout = setTimeout(handler, interval);
    }
  };
  const stop = () => {
    running = false;
    clearTimeout(timeout);
  };
  useEffect(() => {
    handler();
    return stop;
  }, deps);

  return stop;
};
