import { useState, useEffect } from "react";
import { Autocomplete, Paper, TextField} from '@mui/material';
import Button from "../../components/Button";

import "./index.scss";
import { getAvailableCourses, addGroupCourse } from "../../api/coursesGroups";

const GROUP_NAME_ERROR_TEXT = "Please enter the name of group";

const GroupAircraftAdd = ({ history }) => {
  const [groupName, setGroupName] = useState("");

  const [courses, setCourses] = useState([]);
  const [selectedCourses, setSelectedCourses] = useState([]);

  const [groupNameErrorText, setGroupNameErrorText] = useState(null);

  useEffect(() => {
    (async () => {
      const newCourses = await getAvailableCourses();
      setCourses(newCourses);
    })();
  }, []);

  function handleGroupName(value) {
    setGroupName(value);
    if (!value) return setGroupNameErrorText(GROUP_NAME_ERROR_TEXT);
    setGroupNameErrorText(null);
  }

  async function addCourseGroup() {
    if (!groupName) return handleGroupName(groupName);
    const result = await addGroupCourse(groupName, selectedCourses);
    if (result.ok) {
      history.push("/courses_groups");
    }
  }

  return (
    <>
      <h2>Add Courses Group</h2>

      <Paper
        variant="outlined"
        className="add-courses-group"
        style={{ padding: "1rem 1.5rem" }}
      >
        <TextField
          className="add-field"
          variant="outlined"
          size="small"
          label="Group Name*"
          value={groupName}
          onChange={(event) => handleGroupName(event.target.value)}
          error={!!groupNameErrorText}
          helperText={groupNameErrorText}
        />
        <Autocomplete
          className="select-course"
          size="small"
          multiple
          options={courses}
          getOptionLabel={(course) => course.name}
          filterSelectedOptions
          onChange={(event, newCourses) => setSelectedCourses(newCourses)}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" label="Select courses" />
          )}
        />
        <Button title="Add new Course Group" onClick={addCourseGroup}>
          Add Course Group
        </Button>
        <Button title="Go back" onClick={() => history.goBack()}>
          Cancel
        </Button>
      </Paper>
    </>
  );
};

export default GroupAircraftAdd;
