import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Header from "../../features/header";
import CrewOverview from "../crew-overview";
import Cockpit from "../cockpit";
import Confirm from "../../components/Confirm";
import ConfirmContext from "../../context/ConfirmContext";
import { ShoppingCartProvider } from "../../context/ShoppingCartContext";
import ShoppingCartService from "../../services/payments";
import useCrew from "../../hooks/useCrew";
import DisplayContext from "../../context/DisplayContext";

import "./index.scss";

const Dashboard = () => {
  
  const [showCockpit, setshowCockpit] = useState(true);
  function toggleCockpit(){
    setshowCockpit(!showCockpit);
  }

  

  const [confirmValues, setConfirmValues] = useState(null);
  const { crew } = useCrew();
  const [shoppingCartItems, setShoppingCartItems] = useState([]);
  const [fullscreen, setFullscreen] = useState(false);

  useEffect(() => {
    (async () => {
      await ShoppingCartService.updateOrderStatus(crew.id);
      const storedItems = ShoppingCartService.getCrewItems(crew.id);
      setShoppingCartItems(storedItems);
    })();
  }, [crew]);

  const confirmActions = {
    show(title, message, onSuccess) {
      setConfirmValues({
        title,
        message,
        onConfirm(isSuccess) {
          if (isSuccess) {
            onSuccess();
          }
          setConfirmValues(null);
        },
      });
    },
    notification(title, message) {
      setConfirmValues({
        title,
        message,
        notification: true,
        onConfirm() {
          setConfirmValues(null);
        },
      });
    },
  };

  const shoppingCart = {
    async clear() {
      await ShoppingCartService.clear(crew.id);
      setShoppingCartItems(ShoppingCartService.getCrewItems(crew.id));
    },
    async add(certificate, userId) {
      if (certificate) {
        const item = {
          courseId: certificate.id,
          courseName: certificate.name,
          userId,
          crewId: crew.id,
          isBundle: !!certificate.isBundle,
        };
        await ShoppingCartService.add(crew.id, item);
        setShoppingCartItems(ShoppingCartService.getCrewItems(crew.id));
      }
    },
    async remove(item) {
      await ShoppingCartService.removeItem(crew.id, item);
      setShoppingCartItems(ShoppingCartService.getCrewItems(crew.id));
    },
    async checkout(billingId) {
      const order = await ShoppingCartService.checkout(crew.id, billingId);
      if (order?.url) {
        this.clear();
        window.location = order?.url;
      } else {
        console.log(
          `failed to create order, there should be an error message in the server's log; crewId=${crew.id} billingId=${billingId}`
        );
        return false;
      }
    },
    async payWithInvoice(billingId,shoppingItems) {
      const orderPayWithInvoice = await ShoppingCartService.payWithInvoice(crew.id, billingId, shoppingItems);

      if (orderPayWithInvoice){
        if (orderPayWithInvoice.orderId === "error"){
          return false;
        } else {
          return true;
        }   
      }else {
        console.log(
          `failed to create order, there should be an error message in the server's log; crewId=${crew.id} billingId=${billingId}`
        );
        return false;
      }
    },
  };

  const toggleFullscreen = () => setFullscreen(!fullscreen);

  const [menuOpen,setMenuOpen] = useState(false);

  return (
    <>
      <ConfirmContext.Provider value={confirmActions}>
        <ShoppingCartProvider
          value={{
            items: shoppingCartItems,
            actions: shoppingCart,
          }}
        >
          <DisplayContext.Provider value={{ fullscreen, toggleFullscreen }}>
            <Box
              display={"flex"}
              flexDirection={"column"}
              alignItems={"stretch"}
              style={{ height: "100vh" }}
              className={`${fullscreen && "fullscreen"}`}
            >
              <Box className={`header-wrapper ${fullscreen && "fullscreen"}`}>
                <Header shoppingCartItems={shoppingCartItems} menuOpen={menuOpen} setMenuOpen={setMenuOpen}/>
              </Box>

              
              <div style={{display: showCockpit?"block":"none"}}>
                  <Cockpit showCockpit = {showCockpit} triggershowCockpit = {toggleCockpit} crew = {crew}/> 
              </div>
              <div style={{display: !showCockpit?"block":"none"}}>
                <CrewOverview menuOpen={menuOpen} setMenuOpen={setMenuOpen}/>
              </div>              
     
   
            </Box>
          </DisplayContext.Provider>
        </ShoppingCartProvider>
      </ConfirmContext.Provider>
      {confirmValues ? (
        <Confirm
          title={confirmValues.title}
          onConfirm={confirmValues.onConfirm}
          notification={confirmValues.notification}
        >
          {confirmValues.message}
        </Confirm>
      ) : null}
    </>
  );
};

export default Dashboard;
