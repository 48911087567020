import request from "./request";

export const getFrats = async (crewId) => {
  const json = await request(`/st/frats/${crewId}`);
  return json;
};

export const getFrat = async (fratId) => {
  const json = await request(`/st/frats/frat/${fratId}`);
  return json;
};

export const saveFrat = async (crewId, fratId, frat) => {
  if (!fratId) {
    const json = await request.post(`/st/frats/`, { crewId, frat });
    return json;
  } else {
    const json = await request.put(`/st/frats/${fratId}`, { crewId, frat });
    return json;
  }
};

export const toggleFratStatus = async (crewId, fratId) => {
  const json = await request.put(`/st/frats/${fratId}/toggle`, { crewId });
  return json;
};
