import React, { useContext, createRef } from "react";

import TextField from "@mui/material/TextField";

import FormContext from "./FormContext";

const FileInput = ({ label, accept, name }) => {
  const context = useContext(FormContext);
  // const inputRef = createRef()
  // const labelRef = createRef()
  // const fieldId = `input_${Math.random() * 10e10}`;

  const { value, error } = context.get(name);
  const title = value?.name;

  const change = (e) => {
    const value = e.target?.files;
    context.set(name, value);
  };

  return (
    <>
      {/*<input id={fieldId} ref={inputRef} type="file" style={{ position: 'fixed', top: -9999999, left: -99999 }} />*/}
      <TextField
        InputLabelProps={{ shrink: true }}
        variant="outlined"
        type="file"
        value={title}
        label={label}
        error={!!error}
        {...(error ? { helperText: error } : {})}
        onChange={change}
        fullWidth
        inputProps={{ accept }}
      />
    </>
  );
};

export default FileInput;
