import React from "react";

import List from "@mui/material/List";
import Paper from "@mui/material/Paper";

import CategoryTreeItem from "./CategoryTreeItem";

const CourseTree = ({ categories }) => (
  <>
    <Paper variant="outlined" elevation={0}>
      <List dense>
        {categories.map((category) => (
          // key must be on the map item level
          <CategoryTreeItem key={category.id} category={category} nodeId={""} />
        ))}
      </List>
    </Paper>
  </>
);

export default CourseTree;
