// import React from 'react';

import { COURSE_STATUS } from "../../constants";
import { formatDate } from "../../services/formatters";

const CertificateStatus = ({ validUntil, status }) => {
  if (status === COURSE_STATUS.MISSING) {
    return "Missing";
  }
  return validUntil ? `${formatDate(validUntil)}` : COURSE_STATUS.NEVER_EXPIRE;
};

export default CertificateStatus;
