import { SelectableObject } from "src/components/Form/types";
import countries from "./countries.json";

export class Country extends SelectableObject {
  constructor(
    public code: string,
    public label: string,
  ) {
    super();
  }

  getDisplayName(): string {
    return this.label;
  }

  getId(): string {
    return this.code;
  }
}

const mapCountry = (value: { label: string; code: string }): Country => {
  return new Country(value.code, value.label);
};

export const getCountryByCode = (code: string): Country | undefined =>
  getCountriesList().find(
    (country) => country.code.toLowerCase() === (code || "").toLowerCase(),
  );

export const getCountriesList = (): Country[] => {
  return countries.map(mapCountry);
};

export const getCountryByName = (name: string): Country | undefined => {
  return getCountriesList().find(
    (country) => country.label.toLowerCase() === name.toLowerCase(),
  );
};
