import { Model } from "src/components/Form/types";

import { Position, mapPosition } from "./positions";
import { ValidPeriod, mapValidPeriod } from "./validPeriods";
import request from "./request";

export class PositionValidPeriod implements Model {
  position?: Position;
  validPeriod?: ValidPeriod;

  constructor(data?: any) {
    if (data && typeof data === "object") {
      this.position = data.position && mapPosition(data.position);
      this.validPeriod = data.validPeriod && mapValidPeriod(data.validPeriod);
    }
  }

  validate(): boolean {
    return Boolean(this.position && this.validPeriod);
  }
}

export const getCustomPositionValidPeriods = async (courseId: number) => {
  const json = await request(
    `/position_valid_periods/custom_courses/${courseId}`,
  );

  return json;
};

export const getMoodlePositionValidPeriods = async (
  courseId: number,
): Promise<PositionValidPeriod[]> => {
  const response = await request(
    `/position_valid_periods/moodle_courses/${courseId}`,
  );
  return response.map((data: any) => new PositionValidPeriod(data));
};
