import React from "react";
import ErrorIcon from "@mui/icons-material/Error"; 
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const TrainingModuleStatus = ({status}) => {
    return <>
            {status === 1  ? ( 
                    <CheckCircleIcon htmlColor="green" />
                ) : (
                    <ErrorIcon htmlColor="red" />
            )}
        </>
  };

export default TrainingModuleStatus;
