import { useContext } from "react";

import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import FormContext from "./FormContext";

import moment from "moment";


const FORMAT = "DD/MM/YYYY HH:mm";

const DateTimePickerCustom = ({ label, name, disableFuture, disabled = false }) => {
  const context = useContext(FormContext);

  const change = (momentDate) => {
    context.set(name, momentDate);
  };

  const { value, error } = context.get(name);
  return (
    <DateTimePicker
      format={FORMAT}
      value={value ? (value instanceof moment) ? value : moment(value) : ""}
      disableFuture={disableFuture}
      disabled={disabled}
      label={label}
      onChange={change}
      ampm={false}
      slotProps={{
        textField: {
          placeholder: FORMAT.toLowerCase(),
          helperText: FORMAT.toLowerCase(),
          fullWidth: true,
          size: "small",
          error: !!error,
          ...(error ? { helperText: error } : {})
        },
      }}
    />
  );
};

export default DateTimePickerCustom;
