import React, { useEffect, useState, useRef } from "react";

import { getAllCourses } from "../../api/courses";
import RelationsService from "../../services/relations";
import RelationTreeService from "./service";

import CourseTree from "../../components/CourseTree";
import AddRelationsForm from "../../components/CourseTree/CourseTreeForm";

import {
  createCourseRelation,
  deleteRelation,
  toggleCourseVisibility,
  getAvailableCourses,
} from "../../api/courses";

import { CourseRelationsContextProvider } from "../../context/CourseRelationsContext";

const RelationsTreeFeature = ({ match }) => {
  const formRef = useRef(null);
  const { crewId } = match.params;
  const isSuperAdmin = !crewId || crewId === 0;
  const [refresh, setRefresh] = useState(0);
  const [relations, setRelations] = useState(null);
  const [availableCourses, setAvailableCourses] = useState(null);
  const [formValues, setFormValues] = useState(null);
  const [selectedFormValues, setSelectedFormValues] = useState(null);
  const [courses, setCourses] = useState([]);
  const [error, setError] = useState(null);
  const [aircraft, setAircraft] = useState(null);

  useEffect(() => {
    (async () => {
      const json = await getAllCourses(crewId);
      const coursesJson = await getAvailableCourses(crewId);
      const rels = RelationsService.buildRelations(json);
      const courses = RelationsService.getCourses(coursesJson);
      const sortedRels = RelationTreeService.sortRelations(rels);
      setAircraft(json.relations.aircraft);
      setRelations(sortedRels);
      setFormValues({ categories: sortedRels, courses });
      setAvailableCourses(courses);
    })();
  }, [crewId, refresh]);

  // TODO: in superadmin mode all actions are available with course, thus
  // it's like all courses are related
  const actions = {
    async toggleCourseVisibility(course) {
      const response = await toggleCourseVisibility(crewId, course);
      if (response.ok) {
        setRefresh(refresh + 1);
      }
    },
    async deleteRelation(relation) {
      const response = await deleteRelation(crewId, relation);
      if (response.ok) {
        setRefresh(refresh + 1);
      }
    },
    async addRelation(relation) {
      const response = await createCourseRelation(crewId, relation);
      if (response.ok) {
        setRefresh(refresh + 1);
      }
    },
    isSuperAdmin() {
      return isSuperAdmin;
    },
    nodeClicked(nodeId) {
      const relation = RelationTreeService.getRelationFromNodeId(nodeId);
      const category = formValues.categories.find(
        (c) => c.id === relation.categoryId
      );
      if (category) {
        const position = category.positions?.find(
          (p) => p.id === relation.positionId
        );
        const operation = position?.operations?.find(
          (o) => o.id === relation.operationId
        );
        const aircraft = (position || category)?.aircraft.find(
          (a) => a.id === relation.aircraftTypeId
        );
        const specialization = aircraft?.specializations.find(
          (s) => s.id === relation.specializationId
        );

        setSelectedFormValues({
          category,
          position,
          operation,
          aircraft,
          specialization,
        });
        formRef?.current?.scrollIntoView({
          behaviour: "smooth",
        });
      }
    },
  };

  const onChange = {
    category(category) {
      const selectedValues = {
        category,
      };
      setSelectedFormValues(selectedValues);
    },
    position(position) {
      const selectedValues = {
        category: selectedFormValues?.category,
        position,
      };
      setSelectedFormValues(selectedValues);
    },
    operation(operation) {
      const selectedValues = {
        category: selectedFormValues?.category,
        position: selectedFormValues?.position,
        operation,
      };
      setSelectedFormValues(selectedValues);
    },
    aircraft(aircraft) {
      const selectedValues = {
        category: selectedFormValues?.category,
        position: selectedFormValues?.position,
        operation: selectedFormValues?.operation,
        aircraft,
      };
      setSelectedFormValues(selectedValues);
    },
    specialization(specialization) {
      const selectedValues = {
        ...(selectedFormValues || {}),
        specialization,
      };
      setSelectedFormValues(selectedValues);
    },
    courses(courses) {
      setCourses(courses);
    },
    async submit() {
      if (!courses.length) {
        setError("Please specify a course");
        return;
      }
      if (!selectedFormValues?.category?.id) {
        setError("Please specify at least a category");
        return;
      } else {
        setError(null);
        for (const course of courses) {
          const relation = {
            id: course?.id,
            isCustom: course?.isCustom,
            isGroup: course?.isGroup,
            categoryId: selectedFormValues?.category?.id,
            positionId: selectedFormValues?.position?.id,
            operationId: selectedFormValues?.operation?.id,
            aircraftTypeId: selectedFormValues?.aircraft?.id,
            specializationId: selectedFormValues?.specialization?.id,
          };
          await actions.addRelation(relation);
        }
        setSelectedFormValues({});
        setCourses([]);
      }
    },
  };

  return (
    <CourseRelationsContextProvider actions={actions}>
      <h2>Relations</h2>
      {availableCourses ? (
        <div ref={formRef}>
          <AddRelationsForm
            aircraftList={aircraft}
            categories={relations}
            selectedValues={selectedFormValues}
            onChange={onChange}
            values={formValues}
            courses={courses}
            error={error}
          />
        </div>
      ) : null}
      {relations ? <CourseTree categories={relations} /> : null}
    </CourseRelationsContextProvider>
  );
};

export default RelationsTreeFeature;
