import Keycloak from "keycloak-js";

const keycloak = Keycloak({
  url: `${process.env.REACT_APP_KEYCLOAK_HOST}/auth`,
  realm: process.env.REACT_APP_KEYCLOAK_REALM_NAME,
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
});

export const initAuth = async () => {
  await keycloak.init({
    flow: "implicit",
    onLoad: "login-required",
    enableLogging: true,
  });
};

export const updateToken = async () => keycloak.updateToken(1800);

export const getToken = async () => {
  try {
    await updateToken();
  } catch (e) {}
  return keycloak.token;
};

export const isLoggedIn = () => keycloak.authenticated;

export const logout = async () => keycloak.logout();

export const startAuthFlow = () => {
  keycloak.login({
    redirectUri: `${process.env.REACT_APP_KEYCLOAK_REDIRECT_URI}`,
  });
};
