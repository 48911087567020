import React from "react";
import moment from "moment";
import Box from "@mui/material/Box";
import Button from "../../components/Button";

import {
  Form,
  DatePicker,
  InputForm,
  Password,
  Validation,
  SelectForm,
  Row,
} from "../Form";
import { getCountriesList, getCountryByCode } from "../../services/countries";
import { getLanguagesList, getLanguageByCode } from "../../services/languages";
import { StyledPaper } from "../Base";

const countries = getCountriesList();
const languages = getLanguagesList();

const EditForm = ({ user, onSubmit }) => {
  return (
    <>
      <StyledPaper variant="outlined">
        <Form
          onSubmit={onSubmit}
          /* 
          validation={{
            newPassword: Validation.verifyPassword,
          }}
           */
          defaultValues={{
            fullname: user.fullname,
            email: user.email,
            birthday: moment(user.birthday),
            fcl: user.fcl,
            country: getCountryByCode(user.country),
            city: user.city,
            language: getLanguageByCode(user.language),
          }}
        >
          <Row>
            <InputForm label="Fullname" name="fullname" />
          </Row>
          <Row>
            <InputForm label="Email" name="email" disabled />
          </Row>
          {/* 
          <Row>
            <Password name="oldPassword" label="Old Password" />
          </Row>
          <Row>
            <Password
              name="newPassword"
              label="New Password"
              hasVerification={true}
              verificationLabel="Confirm New Password"
            />
          </Row>
         */}
          <Row>
            <DatePicker name="birthday" disableFuture label="Birthday" />
          </Row>
          <Row>
            <InputForm label="Fcl" name="fcl" />
          </Row>
          <Row>
            <SelectForm label="Country" name="country" items={countries} />
          </Row>
          <Row>
            <InputForm label="City" name="city" />
          </Row>
          <Row>
            <SelectForm label="Language" name="language" items={languages} />
          </Row>
          <Box>
            <Button type="submit" title="save changes">
              Save
            </Button>
          </Box>
        </Form>
      </StyledPaper>
    </>
  );
};
export default EditForm;
