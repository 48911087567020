import React from "react";

import Tooltip from "@mui/material/Tooltip";
import MuiButton, { ButtonProps } from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";

interface IconButtonProps extends ButtonProps {
  icon?: React.ReactElement;
  iconSize?: "small" | "medium" | "large";
}

const Button: React.FC<IconButtonProps> = ({
  onClick,
  children,
  title = "",
  size,
  type = "button",
  disabled = false,
  icon,
  iconSize = "small",
}) => {
  return (
    <Tooltip title={title}>
      {icon ? (
        <IconButton
          disabled={disabled}
          size="small"
          onClick={onClick}
          className="m-btn"
        >
          {React.cloneElement(icon, { fontSize: iconSize })}
        </IconButton>
      ) : (
        <MuiButton
          variant="outlined"
          style={{
            height: size ? "initial" : "2.5rem",
            marginRight: "1rem",
            width: "max-content",
          }}
          size={size}
          onClick={onClick}
          type={type}
          disabled={disabled}
        >
          {children}
        </MuiButton>
      )}
    </Tooltip>
  );
};

export default Button;
