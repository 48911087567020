import { createContext, useContext } from "react";

const ConfirmContext = createContext({
  show(title, message, onSubmit) {},
  notification(title, message) {},
});

export default ConfirmContext;

export const useConfirm = () => useContext(ConfirmContext);
