import React, { useEffect, useState } from "react";

import Box from "@mui/material/Box";

import Show from "../../components/Show";
import EditProperties from "../../components/EditProperties";
import PropertiesList from "../../components/Properties";

import PropertiesService from "./service";
import useCrew from "../../hooks/useCrew";

import { getCrewRelations } from "../../api/crew";
import {
  getUserProperties,
  addUserProperty,
  removeUserProperty,
} from "../../api/user";

const PropertiesEdit = ({ match }) => {
  const { crew } = useCrew();
  const { userId } = match?.params;
  const crewId = match?.params?.crewId || crew.id;
  const [relations, setRelations] = useState(null);
  const [userProperties, setUserProperties] = useState(null);
  const [refresh, setRefresh] = useState(0);

  useEffect(() => {
    (async () => {
      const crewRelations = await getCrewRelations(crewId);
      const props = await getUserProperties(crewId, userId);

      const userProps = PropertiesService.toDisplayObject(props, crewRelations);
      setUserProperties(userProps);

      const relations = PropertiesService.relationsToDisplayObject(
        crewRelations
      );
      setRelations(relations);
    })();
  }, [crewId, userId, refresh]);

  async function submit(list) {
    let needsRefresh = false;
    for (const data of list) {
      const response = await addUserProperty(crewId, userId, data);
      if (response.ok) {
        needsRefresh = true;
      } else {
        return false;
      }
    }
    if (needsRefresh) {
      setRefresh(refresh + 1);
    }
    return true;
  }

  async function onDelete(data) {
    const response = await removeUserProperty(crewId, userId, data);
    if (response.ok) {
      setRefresh(refresh + 1);
    }
  }

  return (
    <>
      <Show r-if={!!relations}>
        <Box mb={1}>
          <EditProperties relations={relations} onSubmit={submit} />
        </Box>        
      </Show>
      <Show r-if={!!userProperties}>
        <PropertiesList userProperties={userProperties} onDelete={onDelete} />
      </Show>
    </>
  );
};

export default PropertiesEdit;
