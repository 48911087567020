import React from "react";

import { Switch, Route } from "react-router";
import ScheduleAdd from "../../features/schedule-add";
import ScheduleTable from "../../features/schedule-table";


const SchedulEvent = ({ match, history }) => {   
  return (
    <>
      <Switch>
        <Route exact path={`${match.path}`} component={ScheduleTable} />
        <Route
          exact
          path={`${match.path}/schedule-add/:eventType/:trainingId/:userId`}
          component={ScheduleAdd}
        />
      </Switch>
    </>
  );
};

export default SchedulEvent;
