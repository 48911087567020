import React from "react";

import Link from "@mui/material/Link";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { StyledSwitch } from "../../components/Base";
import Button from "../../components/Button";

const OperationItem = ({ onEdit, onDelete, onStatusChange, operation }) => {
  return (
    <TableRow>
      <TableCell>{operation.id}</TableCell>
      <TableCell>{operation.name}</TableCell>
      <TableCell>{operation.positions}</TableCell>
      <TableCell>
        <StyledSwitch
          title="Change operation status"
          checked={operation.status === "active"}
          onChange={() =>
            onStatusChange(
              operation.id,
              operation.status === "active" ? "passive" : "active"
            )
          }
        />
      </TableCell>
      <TableCell>
        <Link
          style={{ marginRight: "1rem" }}
          onClick={() => onEdit(operation.id)}
        >
          <Button title="Edit operation" icon={<EditIcon />} />
        </Link>
        <Link onClick={() => onDelete(operation.id)}>
          <Button
            title="Delete operation"
            icon={<DeleteForeverIcon color="error" />}
          />
        </Link>
      </TableCell>
    </TableRow>
  );
};

export default OperationItem;
