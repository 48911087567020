import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import PostAddIcon from "@mui/icons-material/PostAdd";
import Button from "../../components/Button";
import TableComponent from "../../components/TableComponent";
import { getAssociatePositionProcessStep, deleteAssociatePositionProcessStepById } from "../../api/associate-position-process-step";
import Confirm from "../../components/Confirm";


const AssociatePositionProcessStepTable = ({ match, history }) => {


  const [associatePositionProcessStep, setAssociatePositionProcessStep] = useState(null);
  const [selectedPositionId, setSelectedPositionId] = useState(null);
  const [isDeleteModalShow, setIsDeleteModalShow] = useState(false);
  const [refresh, setRefresh] = useState(1);

  function showDeleteModal(positionId) {
    setSelectedPositionId(positionId);
    setIsDeleteModalShow(true);
  }


  async function confirmDeleteModal(isConfirmed) {
    if (isConfirmed) {
      await deleteAssociatePositionProcessStepById(selectedPositionId);
    }
    setIsDeleteModalShow(false);
    setRefresh(refresh * -1);
  }


  useEffect(() => {
    (async () => {
      const res = await getAssociatePositionProcessStep();

      setAssociatePositionProcessStep(res.sort((a, b) => a.positionId - b.positionId));
    })();
  }, [refresh]);

  return (
    <>
      <Typography variant="h4">Associate position to Process step</Typography>
      {associatePositionProcessStep? (
        <TableComponent
          TopComponent={
            <Button size="small" onClick={() => history.push(`/associate-position-process-step/add`)}>
              <PostAddIcon />
              Add New association
            </Button>
          }
          search={["name"]}
          header={[

            { title: "Position" },
            { title: "Process Step" },
            { title: "Actions" },
          ]}
          items={associatePositionProcessStep}
          renderChildren={(ps) => (
            <TableRow key={ps.positionId}>
              <TableCell>{ps.positionId} - {ps.positionName}</TableCell>
              <TableCell>{ps.data.map(d => (<li key={`${ps.id}-${d.processStepId}`}>{d.processStepId} -  {d.processStepName}</li>))}</TableCell>
              <TableCell> 
                <Button 
                  icon={<EditIcon />}
                  onClick={() => history.push(`/associate-position-process-step/edit/${ps.positionId}`)}
                  title="Edit Associate position"
                />
                <Button
                  icon={<DeleteForeverIcon />}
                  onClick={() => showDeleteModal(ps.positionId)}
                  title="Delete Associate position"
                />
              </TableCell>
            </TableRow>
          )}
        />
      ) : null}
      
      {isDeleteModalShow && selectedPositionId ? (
        <Confirm title={"Delete Associate position"} onConfirm={confirmDeleteModal}>
          <p>Are you sure you want to delete the position?</p>
        </Confirm>
      ) : null}

    </>
  );
};

export default AssociatePositionProcessStepTable;
