import React from "react";

import { Switch, Route } from "react-router";
import MoodleCoursesTable from "../../features/moodle-courses-table";
import MoodleCourseEdit from "../../features/moodle-course-edit";
import CustomCoursesTable from "../../features/custom-courses-table";
import CustomCourseAdd from "../../features/custom-courses-add";

const Courses = ({ match, history }) => {
  return (
    <>
      <Switch>
        <Route
          exact
          path={`${match.path}/moodle`}
          component={MoodleCoursesTable}
        />{" "}
        <Route
          exact
          path={`${match.path}/moodle/edit/:courseId`}
          component={MoodleCourseEdit}
        />{" "}
        <Route
          exact
          path={[
            `${match.path}/custom/add`,
            `${match.path}/custom/edit/:courseId`,
          ]}
          component={CustomCourseAdd}
        />
        <Route
          exact
          path={`${match.path}/custom`}
          component={CustomCoursesTable}
        />
      </Switch>
    </>
  );
};

export default Courses;
