import { BrowserRouter } from "react-router-dom";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider } from "notistack";


import "./App.scss";

import Pages from "./pages";

const theme = createTheme({
  palette: {
    primary: {
      main: "rgb(101, 107, 116)",
    },
  },
  typography: {
    h4: {
      fontSize: "1.2rem",
      fontWeight: "bold",
      lineHeight: "3rem",
    },
  },
});


const App = () => {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={3}>
          <BrowserRouter>
            <Pages />
          </BrowserRouter>
        </SnackbarProvider>
      </ThemeProvider>
    </LocalizationProvider>
)};

export default App;
