import { useEffect } from "react";

const handlers = {};
let fid = 1;

const subscribe = (event, fn) => {
  if (!handlers[event]) {
    handlers[event] = {};
  }
  const uid = fid++;
  handlers[event][uid] = fn;
  return () => {
    delete (handlers[event] || {})[uid];
  };
};

export const usePubsub = (event, fn) => {
  let unsub;
  useEffect(() => {
    // one shouldn't assign vars inside useEffect, thus need to do some further
    // investigation on manual unsubscribing
    unsub = subscribe(event, fn);
    return unsub;
  }, []);
  return unsub; // in case anybody need to unsubscribe before anything
};

export const fireEvent = (event, ...data) => {
  const listeners = Object.values(handlers[event] || {});
  for (const listener of Object.values(listeners)) {
    try {
      listener(...data);
    } catch (e) {
      console.warn("pubsub handler running exception", e);
    }
  }
};
