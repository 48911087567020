import moment from "moment";
import { COURSE_STATUS, DEFAULT_DATE_FORMAT } from "../constants";

/**
 * Add "month" to number or returns "Never expire" if validPeriod is 0
 * @param {number} validPeriod
 * @returns {string}
 */
export const formatValidPeriod = (validPeriod) => {
  return validPeriod === 0
    ? COURSE_STATUS.NEVER_EXPIRE
    : `${validPeriod} month`;
};
/**
 * Add "month" to number
 * @param {number} bufferZone
 * @returns {string}
 */
export const formatBufferZone = (bufferZone) => {
  return `${bufferZone} month`;
};

export const formatDate = (date, format = DEFAULT_DATE_FORMAT) => {
  return moment(date).format(format);
};

// reformat name into: $last, $first
// with $last being the last space-separated part
export const formatLastFirstName = (name) => {
  const parts = name.split(" ");
  if (parts.length === 1) {
    return name;
  }
  return parts[parts.length - 1] + ", " + parts.slice(0, -1);
};

// first name with initial only
export const formatShortName = (name) => {
  const parts = name.split(" ");
  if (parts.length === 1) {
    return name;
  }
  return parts.slice(1).join(" ") + ", " + parts[0][0] + ".";
};



export const convertDateTime = (dateString) => {
  const date = new Date(dateString);
  date.setTime(date.getTime() + (3 * 60 * 60 * 1000));
  const formattedDate = date.toLocaleString('de-DE', {day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute:'2-digit' }).replace(' ', ' - ');
  return formattedDate
}

