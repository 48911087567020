import React from "react";

import Link from "@mui/material/Link";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { StyledSwitch } from "../../components/Base";

import Button from "../../components/Button";

const CategoryItem = ({ onEdit, onDelete, onStatusChange, category }) => {
  return (
    <TableRow>
      <TableCell>{category.id}</TableCell>
      <TableCell>{category.name}</TableCell>
      <TableCell>
        <StyledSwitch
          title="Change category status"
          checked={category.status === "active"}
          onChange={() =>
            onStatusChange(
              category.id,
              category.status === "active" ? "passive" : "active"
            )
          }
        />
      </TableCell>
      <TableCell>
        <Link
          style={{ marginRight: "1rem" }}
          onClick={() => onEdit(category.id)}
        >
          <Button title="Edit category" icon={<EditIcon />} />
        </Link>
        <Link onClick={() => onDelete(category.id)}>
          <Button
            title="Delete category"
            icon={<DeleteForeverIcon color="error" />}
          />
        </Link>
      </TableCell>
    </TableRow>
  );
};

export default CategoryItem;
