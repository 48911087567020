const CourseService = {};

CourseService.matchToProperty = (prop, course) =>
  course.categoryId === prop.categoryId &&
  ((!course.positionId && !prop.positionId) ||
    course.positionId === prop.positionId) &&
  ((!course.operationId && !prop.operationId) ||
    course.operationId === prop.operationId) &&
  ((!course.aircraftTypeId && !prop.aircraftTypeId) ||
    course.aircraftTypeId === prop.aircraftTypeId) &&
  ((!course.specializationId && !prop.specializationId) ||
    course.specializationId === prop.specializationId);

const findCourseById = (course) => (candidate) =>
  candidate.id === course.id &&
  CourseService.matchToProperty(candidate, course);
const findGroupById = (group) => (candidate) =>
  candidate.id === group.groupId &&
  CourseService.matchToProperty(candidate, group);

const filterMap = (hidden, searchFn) => (course) => {
  const hiddenCourse = hidden.find(searchFn(course));
  if (hiddenCourse) {
    return {
      ...course,
      hidden: true,
    };
  }
  return course;
};

CourseService.hide = ({
  courses,
  customCourses,
  hidden,
  groups,
  relations,
}) => ({
  relations,
  courses: courses.map(filterMap(hidden.courses, findCourseById)),
  customCourses: customCourses.map(
    filterMap(hidden.customCourses, findCourseById)
  ),
  groups: groups.map(filterMap(hidden.groups, findGroupById)),
});

CourseService.isAircraftCourse = (course) =>
  !!course.aircraftTypeId || !!course.specializationId;

CourseService.isPositionCourse = (course) =>
  !CourseService.isAircraftCourse(course) && !course.operationId;

CourseService.isOperationCourse = (course) =>
  !CourseService.isAircraftCourse(course) && !!course.operationId;

CourseService.deduplicate = (acc, course) => {
  if (!acc.find((c) => c.id === course.id)) acc.push(course);
  return acc;
};

CourseService.groupDeduplicate = (acc, group) => {
  if (!acc.find((c) => c.groupId === group.groupId)) acc.push(group);
  return acc;
};

CourseService.sortCourses = (a, b) =>
  a?.categoryId - b?.categoryId ||
  a?.positionId - b?.positionId ||
  a?.operationId - b?.operationId ||
  0;

export default CourseService;
