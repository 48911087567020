import React from "react";

import Link from "@mui/material/Link";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { StyledSwitch } from "../../components/Base";
import Button from "../../components/Button";

const PositionItem = ({ onEdit, onDelete, onStatusChange, position }) => {
  return (
    <TableRow>
      <TableCell>{position.id}</TableCell>
      <TableCell>{position.name}</TableCell>
      <TableCell>{position.category.name}</TableCell>
      <TableCell>
        <StyledSwitch
          title="Change position status"
          checked={position.status === "active"}
          onChange={() =>
            onStatusChange(
              position.id,
              position.status === "active" ? "passive" : "active"
            )
          }
        />
      </TableCell>
      <TableCell>
        <Link
          style={{ marginRight: "1rem" }}
          onClick={() => onEdit(position.id)}
        >
          <Button title="Edit Position" icon={<EditIcon />} />
        </Link>
        <Link onClick={() => onDelete(position.id)}>
          <Button
            title="Delete Position"
            icon={<DeleteForeverIcon color="error" />}
          />
        </Link>
      </TableCell>
    </TableRow>
  );
};

export default PositionItem;
