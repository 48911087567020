import React from "react";

import { Switch, Route } from "react-router";
import CrewsAdd from "../../features/crews-add";
import CrewsEdit from "../../features/crews-edit";
import CrewsTable from "../../features/crews-table";

const Crews = ({ match, history }) => {
  return (
    <>
      <Switch>
        <Route exact path={`${match.path}`} component={CrewsTable} />
        <Route exact path={`${match.path}/add_crew`} component={CrewsAdd} />
        <Route exact path={`${match.path}/:crewId`} component={CrewsEdit} />
      </Switch>
    </>
  );
};

export default Crews;
