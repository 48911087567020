import React from "react";

import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Link from "@mui/material/Link";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Button from "../../components/Button";

const CoursesGroupItem = ({ onEdit, onDelete, group }) => {
  const groupCoursesList = (
    <ul>
      {group.groupCourses
        .sort((a, b) => a.startDate - b.startDate)
        .map((course) => (
          <li key={course.id} style={{opacity: course.visible ? 1 : 0.5}}>
            ({course.id}) {course.name}
          </li>
        ))}
    </ul>
  );
  return (
    <TableRow>
      <TableCell>{group.id}</TableCell>
      <TableCell>{group.groupName}</TableCell>
      <TableCell>{groupCoursesList}</TableCell>
      <TableCell>
        <Link style={{ marginRight: "1rem" }} onClick={() => onEdit(group.id)}>
          <Button title="Edit course group" icon={<EditIcon />} />
        </Link>
        <Link onClick={() => onDelete(group.id)}>
          <Button
            title="Delete course group"
            icon={<DeleteForeverIcon color="error" />}
          />
        </Link>
      </TableCell>
    </TableRow>
  );
};

export default CoursesGroupItem;
