import React, { useState, useEffect } from "react";
import { Scheduler } from "@aldabil/react-scheduler";
import { EVENTS } from "./events";
import { enGB } from 'date-fns/locale'
import { getAllTrainingsWithEvents,  } from "../../api/training";
import {  deleteScheduleSlotById } from "../../api/schedule";


const OverviewTrainingCourses = ({ match, history }) => {
  const [trainings, setTrainings] = useState([]);
  const [refresh, setRefresh] = useState(1);

  useEffect(() => {
    (async () => {
      //const res = await getTrainings();
      const res = await getAllTrainingsWithEvents();

      let newValue =[];
      let color = '';
      res.forEach(training => {
        training.Schedulers.forEach((elem) => {
          if (elem.event_type == 'practical') {
            color = '#B6C2D1';
          } else {
            color = '#526A85';
          }
          newValue.push({
            event_id: elem.id,
            title: training.Course.displayname,
            start: new Date(elem.start_date),
            end: new Date(elem.end_date),
            color: color
          })
        })
      }); 
    
      setTrainings(newValue);
    })();
  }, [refresh]);

  const fetchRemote = async (query) => {
    return EVENTS
  };

  const myFetchRemote = async () => {
    resp = getTrainings();
    return EVENTS
  };  

  const valLocale = ''
  const handleConfirm = async (event, action) => {
      if (action === "edit") {
        const newScheduleTrainings = { 
          'scheduleTrainingStartDate' :  event.start,
          'scheduleTrainingEndDate': event.end
        }; 
        //const result = await updateScheduleTraining(event.event_id, newScheduleTrainings);
        
        /** PUT event to remote DB */
      } else if (action === "create") {
        /**POST event to remote DB */
      }
      /**
       * Make sure to return 4 mandatory fields:
       * event_id: string|number
       * title: string
       * start: Date|string
       * end: Date|string
       * ....extra other fields depend on your custom fields/editor properties
       */
      // Simulate http request: return added/edited event
      return new Promise((res, rej) => {
        setTimeout(() => {
          res({
            ...event,
            event_id: event.event_id || Math.random()
          });
        }, 1000);
      });
    }; 

    const handleDelete = async (deletedId) => {
      // Simulate http request: return the deleted id
      await deleteScheduleSlotById(deletedId);
      return new Promise((res, rej) => {
        setTimeout(() => {
          res(deletedId);
        }, 1000);
      });
    };
  return (
    <>
      <h2>Trainings Overview</h2>
    <Scheduler
          events={trainings}
          onConfirm={handleConfirm}
          onDelete={handleDelete}
          //selectedDate={new Date(2022, 8, 5)}
          locale = {enGB}
          view = {"month"}
          month={{
            weekDays: [0, 1, 2, 3, 4, 5, 6],
            weekStartOn: 1, 
            startHour: 5, 
            endHour: 21,
          }}
          week={{
            weekDays: [0, 1, 2, 3, 4, 5, 6],
            weekStartOn: 1,
            startHour: 5, 
            endHour: 21,
            step: 60

          }}
          day={{
            startHour: 5, 
            endHour: 21, 
            step: 60,

          }}
        />
    </>
  );
};

export default OverviewTrainingCourses;