import { SelectableWithIdObject } from "src/components/Form/types";
import { formatValidPeriod } from "src/services/formatters";

import request from "./request";

interface ValidPeriodResponse {
  id: number;
  validPeriod: number;
}

export class ValidPeriod extends SelectableWithIdObject {
  public validPeriod: number;

  constructor(data: ValidPeriodResponse) {
    super(data.id);
    this.validPeriod = data.validPeriod;
  }

  getDisplayName(): string {
    return formatValidPeriod(this.validPeriod);
  }
}

export const mapValidPeriod = (data: ValidPeriodResponse): ValidPeriod => {
  return new ValidPeriod(data);
};

export const getValidPeriods = async (): Promise<ValidPeriod[]> => {
  const response = await request(`/valid_periods`);
  return response.map((data: ValidPeriodResponse) => mapValidPeriod(data));
};
