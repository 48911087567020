import React from "react";
import Box from "@mui/material/Box";

import Button from "../Button";

import {
  Form,
  DatePicker,
  InputForm,
  Validation,
  SelectForm,
  Row,
} from "../Form";
import { getCountriesList } from "../../services/countries";
import { getLanguagesList } from "../../services/languages";
import { StyledPaper } from "../Base";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { checkExistUser } from "../../api/user";

const countries = getCountriesList();
const languages = getLanguagesList();

const genpwd = () => {
  let pwd = "";
  while (pwd.length < 9) {
    let n;
    while (
      (n = String.fromCharCode(~~(Math.random() * 128))) &&
      !/[a-zA-Z0-9]/.test(n)
    );
    pwd += n;
  }

  return pwd;
};

function isValidEmail(email) {
  // Espressione regolare per verificare il formato dell'email
  var regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // Verifica se l'email corrisponde al formato regex
  return regex.test(email);
}

const AddUserForm = ({ onSubmit }) => {
  const [open, setOpen] = React.useState(false);

  const [textMail, setTextMail] = React.useState("");
  const [completeForm, setCompleteForm] = React.useState(true);
  const [openError, setOpenError] = React.useState(false);
  const [errorText, setErrorText] = React.useState("");
  const [errorTitle, setErrorTitle] = React.useState("");

  const errorClose = () => {
    setOpenError(false);
  };

  let formSetValueFn = null;
  const [valuesEmail, setValuesEmail] = React.useState("");

  const submit = async (values) => {
    const response = await checkExistUser(
      values.firstname,
      values.lastname,
      values.birthday,
    );
    if (response.exist) {
      //setCompleteForm(true);
      setOpenError(true);
      setErrorTitle("Warning");
      setErrorText(
        "A User with this name and birthday already exists, please contact support",
      );
    } else {
      if (
        values.email === "" ||
        values.email === null ||
        values.email === undefined ||
        !isValidEmail(values.email)
      ) {
        return {
          email: `Please enter a valid email`,
        };
      }
      if (
        values.password === "" ||
        values.password === null ||
        values.password === undefined
      ) {
        return {
          password: `Please enter password`,
        };
      }
      const data = {
        ...values,
        language: values.language?.value,
        country: values.country?.value,
      };
      const errorMessage = await onSubmit(data);
      if (errorMessage === "Mail Exists") {
        setOpenError(true);
        setErrorTitle("Warning");
        setErrorText(
          "User with this email already exists, please contact support",
        );
      } else {
        setOpenError(true);
        setErrorTitle("Error");
        setErrorText("Please contact support");
      }
    }
  };

  const genpassword = (e) => {
    e.preventDefault();
    if (formSetValueFn) {
      formSetValueFn("password", genpwd());
    }
  };

  return (
    <>
      <StyledPaper variant="outlined">
        <Form
          unsafe_valueSetter={(fn) => {
            formSetValueFn = fn;
          }}
          onSubmit={submit}
          validation={{
            firstname: Validation.required,
            lastname: Validation.required,
            birthday: Validation.required,
          }}
          defaultValues={{
            country: countries.find((c) => c.code === "CH"),
            language: languages[0],
          }}
        >
          <Row>
            <InputForm label="First name*" name="firstname" />
          </Row>
          <Row>
            <InputForm label="Last name*" name="lastname" />
          </Row>
          <Row>
            <DatePicker name="birthday" disableFuture label="Birthday*" />
          </Row>

          {completeForm && (
            <>
              <Row>
                <InputForm label="Email*" name="email" value={valuesEmail} />
              </Row>
              <Box mb={"1rem"} flexDirection={"row"} display={"flex"}>
                <InputForm name="password" label="Password*" />
                <Box display={"flex"} ml={"1rem"}>
                  <Button title="Generate password" onClick={genpassword}>
                    Generate
                  </Button>
                </Box>
              </Box>

              <Row>
                <SelectForm label="Country" name="country" items={countries} />
              </Row>
              <Row>
                <InputForm label="City" name="city" />
              </Row>
              <Row>
                <SelectForm
                  label="Language*"
                  name="language"
                  items={languages}
                />
              </Row>
            </>
          )}
          <Box>
            <Button type="submit" title="Add new member">
              Add Member
            </Button>
          </Box>
        </Form>
      </StyledPaper>

      <Dialog
        open={openError}
        onClose={errorClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{errorTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {errorText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={errorClose} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddUserForm;
