import React from "react";

import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Link from "@mui/material/Link";
import EditIcon from "@mui/icons-material/Edit";
import { StyledSwitch } from "../../components/Base";
import Button from "../../components/Button";


const CrewItem = ({ onEdit, onStatusChange, onEfbChange, crew, loading }) => {
  //const [loading, setLoading] = React.useState(crew.load);
  //const [success, setSuccess] = React.useState(false);

  return (

    <TableRow>
      <TableCell>{crew.id}</TableCell>
      <TableCell>{crew.companyName}</TableCell>
      <TableCell>{crew.contactPerson}</TableCell>
      <TableCell>{crew.email}</TableCell>
      <TableCell>{crew.phone}</TableCell>
      <TableCell>
            <StyledSwitch
            title="Change company status"
            checked={crew.status === "active"}
            onChange={() =>
              onStatusChange(
                crew.id,
                crew.status === "active" ? "passive" : "active"
              )
            }
          />
      </TableCell>
      {/* 
      <TableCell>
            <StyledSwitch
              title="Permit Efb"
              checked={crew.efb === "yes"}
              disabled = {loading}
              onChange={() =>
                onEfbChange(
                  crew.id,
                  crew.efb === "yes" ? "no" : "yes",
                  crew.companyName
                )
              }
            />   
      </TableCell>
      */}
      <TableCell>{crew.address}</TableCell>
      <TableCell>{crew.postcode}</TableCell>
      <TableCell>{crew.city}</TableCell>
      <TableCell>
        <Link style={{ marginRight: "1rem" }} onClick={() => onEdit(crew.id)}>
          <Button title="Edit company" icon={<EditIcon />} />
        </Link>
      </TableCell>
    </TableRow>
  );
};

export default CrewItem;
