import React from "react";

import { useHistory } from "react-router-dom";

import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import GetAppIcon from "@mui/icons-material/GetApp";
import HistoryIcon from "@mui/icons-material/History";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import PlayCircleIcon from "@mui/icons-material/PlayCircleFilled";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import PanToolIcon from '@mui/icons-material/PanTool';
import LoopIcon from "@mui/icons-material/Loop";
import { pick, sortBy } from "lodash"; 

import Button from "../../components/Button";
import { COURSE_STATUS } from "../../constants";
import useContextUserCrew from "../../hooks/useContextUserCrew";
import { useCertificateActions } from "../../context/CertificateActions";
import { useShoppingCart } from "../../context/ShoppingCartContext";


import useUser from "../../hooks/useUser";
import { useConfirm } from "../../context/ConfirmContext";

import { goToCourse , openDownloadWindow, openExportWindow } from "../../api/";
import { getTrainingByCourseId, addUserToTrainingCourse, getUserToTrainingCourse } from "../../api/training";

const isCustom = (cert) => /cc_/.test(cert?.id);
const isGroup = (cert) => cert && (/gc_/.test(cert.id) || cert.group);

function sleep(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

const certificateExportable = (certificate) =>
  certificate.status !== COURSE_STATUS.MISSING &&
  !(certificate.fileName || certificate.certificateFile) &&
  !isCustom(certificate);



export const ExportActions = ({ certificate, userId }) => {
  const exportId = certificate.latestRefresherId || certificate.id;

  return (
    <>
      {" "}
      {!certificate.isBundle &&
      (certificate.fileName || certificate.certificateFile) ? (
        <Button
          title="Download Certificate"
          icon={<GetAppIcon />}
          onClick={() => openDownloadWindow(certificate, userId)}
        />   
      ) : null}
      {!certificate.isBundle && certificateExportable(certificate) ? (
        <Button
          title="Download Certificate"
          icon={<GetAppIcon />}
          onClick={() => openExportWindow(exportId, userId)}
        />
      ) : null}
    </>
  );
};

const Actions = ({ certificate: c }) => {
  const me = useUser();
  const { userId, crewId } = useContextUserCrew();
  const history = useHistory();
  const actions = useCertificateActions();
  const shoppingCart = useShoppingCart();
  const dialog = useConfirm();

  const boughtProducts = actions?.getBoughtCourses();
  const boughtCourses = boughtProducts?.courses || [];
  const boughtBundles = boughtProducts?.bundles || [];
  const pendingApprovalCourses = boughtProducts?.pending?.courses;
  const pendingApprovalCustomCourses = boughtProducts?.pending?.customCourses;

  const isCustomCourse = isCustom(c) || c.isBundle;
  const isOk = c.status === COURSE_STATUS.OK;
  const actionsRequired = !isCustomCourse && !isOk;
  const coursePriceFree = !+c.price;

  let actCertificateId = c.id;
  const latestGroupCertificate = c.group?.latest;
  if (latestGroupCertificate) {
    actCertificateId = latestGroupCertificate?.id;
  }

  if (c.latestRefresherId) {
    actCertificateId = c.latestRefresherId;
  }

  // groups + refreshers:
  // "actions we need to take" (buy, play; including info for manager) should be based on:
  // either already-purchased same-or-newer "refresher" course
  // or just the latest refresher course

  let nextCourse = c;
  
  if (c.status === COURSE_STATUS.BUFFER){
    console.log({nextCourse})
    console.log({boughtCourses})
  }

  if (!isOk) {
    let children;
    if (c.refreshers) {
      children = c.refreshers;
      

      if (children?.length) {
        children = sortBy(children, "startDate").reverse();
        nextCourse = children.find(c => boughtCourses.includes(c.id) && c.visible) || children.find(c => c.visible) || c;
      }
    } else if (c.group) {
      children = c.group.courses;
      if (children?.length) {
        children = sortBy(children, "startDate").reverse();
        nextCourse = children.find(c => c.visible) || c;
      }
    }
  }

  let showCartIcon =
    !isCustomCourse &&
    (c.status === COURSE_STATUS.MISSING ||
      c.status === COURSE_STATUS.EXPIRED ||
      c.status === COURSE_STATUS.BUFFER) &&
    nextCourse.visible &&
    !coursePriceFree &&
    !boughtCourses.includes(nextCourse.id);

   /* 

  if (c.group){
    console.log({c})
    c.latestRefresherId  = c.group?.latest?.id
  }
  */


  const showBoughtIcon =
    actionsRequired &&
    nextCourse.id != c.latestRefresherId &&
    me.id !== userId &&
    !c.isBundle &&
    ( (boughtCourses.includes(nextCourse.id) && c.visible) || coursePriceFree && nextCourse.visible);




  const showWaitIcon =
    actionsRequired &&
    nextCourse.id == c.latestRefresherId  

  const showPendingIcon =
    !isOk &&
    ((isCustomCourse && pendingApprovalCustomCourses?.includes(actCertificateId)) ||
      pendingApprovalCourses?.includes(actCertificateId) ||
      pendingApprovalCourses?.includes(c.id));

  const bundleBought =
    c.isBundle && boughtBundles.includes(c.id);

  const showPlayIcon =
    actionsRequired &&
    //me.id === userId &&
    (boughtCourses.includes(nextCourse.id) && c.visible || coursePriceFree) &&
    !isCustomCourse &&
    nextCourse.visible;

  const mayUpload = c.uploadable && !isOk;
  const mayRefresh = !mayUpload;

  return (
    <div style={{ display: "flex", justifyContent: "start" }}>
      {mayUpload ? (
        <Button
          title="Upload certificate to cloud"
          icon={<CloudUploadIcon />}
          onClick={() =>
            history.push(
              `/certificates/${crewId}/members/${userId}/upload/${actCertificateId}`
            )
          }
        />
      ) : null}
      {mayRefresh ? (
        <Button
          title="Update certificate in cloud"
          icon={<CloudUploadIcon color="disabled" />}
          onClick={() =>
            history.push(
              `/certificates/${crewId}/members/${userId}/upload/${actCertificateId}`
            )
          }  
        />
      ) : null}
      <ExportActions userId={userId} certificate={c} />
      {!c.isBundle && actions.showHistory ? (
        <Button
          title="Show history"
          icon={<HistoryIcon />}
          onClick={() => actions.showHistory(c)}
        />
      ) : null}
      {showCartIcon || (c.isBundle && !bundleBought) ? (
        <Button
          title={`Add to cart: (${nextCourse.id}) ${nextCourse.name}, ${nextCourse.price}`}
          icon={<AddShoppingCartIcon />}
          onClick={() => shoppingCart.actions.add(nextCourse, userId)}
        />
      ) : null}
      {bundleBought ? (
        <Button
          title={`User already subscribed to bundle`}
          icon={<AssignmentTurnedInIcon />}F
          onClick={() => {}}
        />
      ) : null}
      {showBoughtIcon ? (
        <Button
          title={`Course bought: (${nextCourse.id})`}
          icon={<AssignmentTurnedInIcon />}
          onClick={() => {}}
        />
      ) : null}

      {showWaitIcon ? (
        <Button
          title={`new courseware not yet available`}
          icon={<PanToolIcon />}
          onClick={() => {}}
        />
      ) : null}

      {showPlayIcon ? (
        <Button
          title="Go to Course"
          icon={<PlayCircleIcon />}
          onClick={async () => {
            if (nextCourse.type === "training") {
              const training = await getTrainingByCourseId(nextCourse.id)
              const userToTraining = await getUserToTrainingCourse(training.id, userId, crewId);
              if (!userToTraining) {
                await addUserToTrainingCourse(training.id, userId, crewId, nextCourse.id, 'user', '');
              }
              history.push(`/training-users/user/details/${training.id}/${userId}/${nextCourse.id}`);
            } else {
              const url = await goToCourse(nextCourse.id, userId);
              if (!url) {
                dialog.notification(
                  "Error enrolling course",
                  "Please try again later"
                );
              } else {
                var iframe = document.createElement('iframe');
                iframe.src = process.env.REACT_APP_MOODLE_HOST_URI + "/login/co-logout.php"; 
                document.body.appendChild(iframe);
                await sleep(1000)
                window.open(url);
              }
            }

          }}
        />
      ) : null}
      {showPendingIcon ? (
        <Button
          title="Certificate is waiting for approval"
          icon={<LoopIcon />}
        />
      ) : null}
    </div>
  );
};

export default Actions;
