import React, { useState, useEffect } from "react";
import moment from "moment";

import Typography from "@mui/material/Typography";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

import DeleteIcon from "@mui/icons-material/Delete";

import { DEFAULT_DATE_FORMAT } from "../../constants";

import Button from "../../components/Button";

import TableComponent from "../../components/TableComponent";

import { getActiveSubscriptions, cancelSubscription } from "../../api/payment";

import { useConfirm } from "../../context/ConfirmContext";

const CrewSubscriptions = ({ match }) => {
  const confirmDialog = useConfirm();
  const crewId = match.params.crewId;
  const superadmin = !crewId;
  const [subscriptions, setSubscriptions] = useState(null);
  const [refresh, setRefresh] = useState(0);

  useEffect(() => {
    (async () => {
      const subscriptions = await getActiveSubscriptions(crewId);
      setSubscriptions(subscriptions);
    })();
  }, [refresh]);

  const cancel = (subscription) => {
    confirmDialog.show(
      "Subscription termination",
      `Please confirm termination of bundle refresher "${subscription.bundle}" for ${subscription.user}  `,
      async () => {
        const res = await cancelSubscription(subscription.id, subscription.bundleId, subscription.userId);
        if (res?.ok) {
          setRefresh(refresh + 1);
        }
      }
    );
  };

  const header = [
    { title: "Member", sort: "user", style: { width: "30%" } },
    { title: "Bundle", sort: "bundle", style: { width: "30%" } },
    { title: "Renewal Date", sort: "expiration", style: { width: "30%" } },
    { title: "", style: { width: "10%" } },
  ];

  if (superadmin) {
    header.unshift({ title: "Organisation", sort: "crew" });
  }

  return (
    <>
      <Typography variant="h4">Active Subscriptions</Typography>
      {subscriptions ? (
        <TableComponent
          search={["user", "bundle"]}
          pagination
          header={header}
          items={subscriptions}
          renderChildren={(sub) => (
            <TableRow key={`${superadmin ? sub.crew + "-" : ""}${sub.user}-${sub.bundle}`}>
              {superadmin ? <TableCell>{sub.crew}</TableCell> : null}
              <TableCell>{sub.user}</TableCell>
              <TableCell>{sub.bundle}</TableCell>
              <TableCell>
                {moment(sub.expiration).format(DEFAULT_DATE_FORMAT)}
              </TableCell>
              <TableCell>
                <Button
                  onClick={() => {
                    cancel(sub);
                  }}
                  icon={<DeleteIcon color="error" />}
                  title="Cancel subscription renewal"
                />
              </TableCell>
            </TableRow>
          )}
        />
      ) : null}
    </>
  );
};

export default CrewSubscriptions;
