import { SelectableWithIdObject } from "src/components/Form/types";
import request from "./request";

export const getCrewOperations = async (crewId: string) => {
  const json = await request(`/crew/${crewId}/operations`);
  return json;
};

export const getCrewPositions = async (crewId: string) => {
  const json = await request(`/crew/${crewId}/positions`);
  return json;
};

export const getCrewOverview = async (crewId: string) => {
  const json = await getCrewOperations(crewId);
  const users = await request(`/crew/${crewId}/users/overview`);

  return {
    positions: json,
    users,
  };
};

export const addCrew = async (crew: any) => {
  const response = await request(`/crew`, "POST", crew);
  return response;
};

export const getCrewById = async (crewId: string) => {
  const json = await request(`./crew/${crewId}`);

  return json;
};

export const updateCrew = async (crewId: string, newCrew: any) => {
  const response = await request(`/crew/${crewId}`, "PUT", newCrew);

  return response;
};

export const inscribeCrewToEfb = async (crewId: string, companyName: any) => {
  const response = await request(`/crew/${crewId}/efb`, "POST", {
    companyName,
  });
  return response;
};

export const uninscribeCrewToEfb = async (crewId: string, companyName: any) => {
  const response = await request(`/crew/${crewId}/efb`, "DELETE", {
    companyName,
  });
  return response;
};

export const deleteCrewById = async (crewId: string) => {
  const json = await request(`/crew/${crewId}`, "DELETE");

  return json;
};

export const getCrewCourses = async (crewId: string) => {
  const json = await request(`/crew/${crewId}/courses`);
  return json;
};

export const getCrewAircrafts = async (crewId: string) => {
  const json = await request(`/crew/${crewId}/aircraft`);
  return json;
};

interface CrewResponse {
  id: number;
  companyName: string;
  contactPerson: string;
  email: string;
  phone: string;
  status: string;
  efb: string;
  address: string;
  postcode: string;
  city: string;
}

export class Crew extends SelectableWithIdObject {
  public companyName: string;
  public contactPerson: string;
  public email: string;
  public phone: string;
  public status: string;
  public efb: string;
  public address: string;
  public postcode: string;
  public city: string;

  constructor(data: CrewResponse) {
    super(data.id);
    this.companyName = data.companyName;
    this.contactPerson = data.contactPerson;
    this.email = data.email;
    this.phone = data.phone;
    this.status = data.status;
    this.efb = data.efb;
    this.address = data.address;
    this.postcode = data.postcode;
    this.city = data.city;
  }

  getDisplayName(): string {
    return this.companyName;
  }
}

export const mapCrew = (data: CrewResponse): Crew => {
  return new Crew(data);
};

export const getCrews = async (): Promise<Crew[]> => {
  const response = await request(`/crew`);
  return response.map((data: CrewResponse) => mapCrew(data));
};

export const getCrewApps = async (crewId: string) => {
  const json = await request(`/crew/${crewId}/apps`);
  return json;
};

export const getMembersList = async (crewId: string) => {
  const json = await request(`/crew/${crewId}/users`);
  return json;
};

export const removeMemberFromCrew = async (crewId: string, userId: string) => {
  const json = await request.delete(`/crew/${crewId}/users/${userId}`);
  return json;
};

export const getCrewRelations = async (crewId: string) => {
  const json = await request(`/relation/${crewId}`);
  return json;
};

export const getAircraftSpecialization = async (crewId: string) => {
  const json = await request(`/relation/aircraft/${crewId}`);
  return json;
};

export const getUserRoles = async (crewId: string, userId: string) => {
  const json = await request(`/crew/${crewId}/user/${userId}/roles`);
  return json;
};

export const setUserRoles = async (
  crewId: string,
  userId: string,
  roles: any,
) => {
  const json = await request.post(
    `/crew/${crewId}/user/${userId}/roles`,
    roles,
  );
  return json;
};
