import request from "./request";

export const getOperations = async () => {
  const json = await request(`/operations`);

  return json;
};

export const addOperation = async (name, positionIdList) => {
  const response = await request(`/operations`, "POST", {
    name: name,
    positionIdList: positionIdList,
  });

  return response;
};

export const getOperationById = async (operationId) => {
  const json = await request(`/operations/${operationId}`);

  return json;
};

export const updateOperation = async (operationId, newOperation) => {
  const response = await request(
    `/operations/${operationId}`,
    "PUT",
    newOperation
  );

  return response;
};

export const deleteOperationById = async (operationId) => {
  const json = await request(`/operations/${operationId}`, "DELETE");

  return json;
};
