import React from "react";

import { Switch, Route } from "react-router";
import CategoriesTable from "../../features/categories-table";
import CategoriesAdd from "../../features/categories-add";
import CategoriesEdit from "../../features/categories-edit";

const Categories = ({ match, history }) => {
  return (
    <>
      <Switch>
        <Route exact path={`${match.path}`} component={CategoriesTable} />
        <Route
          exact
          path={`${match.path}/add_category`}
          component={CategoriesAdd}
        />
        <Route
          exact
          path={`${match.path}/:categoryId`}
          component={CategoriesEdit}
        />
      </Switch>
    </>
  );
};

export default Categories;
