import React, { createContext, useContext } from "react";

const CourseRelationsContext = createContext({
  toggleCourseVisibility(course) {},
  addRelation(course, property) {},
  deleteRelation(relation) {},
  nodeClicked(nodeId) {},
  isSuperAdmin() {},
});

CourseRelationsContext.displayName = "CourseRelationsContext";

export default CourseRelationsContext;

export const CourseRelationsContextProvider = ({ actions, children }) => {
  return (
    <CourseRelationsContext.Provider value={actions}>
      {children}
    </CourseRelationsContext.Provider>
  );
};

export const useCourseRelationsContext = () =>
  useContext(CourseRelationsContext);
