import { useState, useEffect, useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Box, TextField, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { BasePaper, Row, StyledSwitch } from "../../components/Base";
import Button from "../../components/Button";
import { Form, Input, Select, Switch, Textarea } from "../../components/Form";
import DynamicList from "../../components/DynamicList";

import { getValidPeriods, ValidPeriod } from "../../api/validPeriods";
import { getPositionsActive, Position } from "../../api/positions";
import {
  getMoodleCourseById,
  getMoodleCourses,
  CourseResponse,
  updateMoodleCourse,
  RefresherCourseResponse,
  CourseRequest,
} from "../../api/courses";
import { BUFFER_ZONES } from "../../constants";
import {
  getMoodlePositionValidPeriods,
  PositionValidPeriod,
} from "../../api/positionValidPeriods";

import RefresherRow, { RefreshCourseRowModel } from "./refresherRow";
import PositionPeriodRow from "./positionPeriodRow";
import { useBufferZones } from "src/api/bufferZone";
import { SelectableObject } from "src/components/Form/types";

interface CourseForm {
  summary: string;
  summary2?: string;
  showAtAllCourses: boolean;
}

const mapCourseForm = (course: CourseResponse): CourseForm => ({
  summary: course.summary,
  summary2: course.summary2,
  showAtAllCourses: !!course.showAtAllCourses,
});

const MoodleCourseEdit = () => {
  const history = useHistory();
  const goBack = () => history.goBack();
  const { t } = useTranslation();
  const { courseId } = useParams<{ courseId: string }>();

  // Form state
  const [course, setCourse] = useState<CourseForm | null>(null);
  const [selectedPositionValidPeriods, setSelectedPositionValidPeriods] =
    useState<PositionValidPeriod[]>([]);
  const [selectedRefreshers, setSelectedRefreshers] = useState<
    RefreshCourseRowModel[]
  >([]);

  // Read only fields
  const [courseName, setCourseName] = useState<string>("");

  // Price is handeled out of the form becaouse of Free switch
  const [coursePrice, setCoursePrice] = useState(0);
  const [isCoursePriceFree, setIsCoursePriceFree] = useState<boolean>(false);

  // Default validity period is handeled out of the form because Buffer zone depends on it
  const [defaultValidPeriod, setDefaultValidPeriod] = useState<
    SelectableObject | ""
  >("");
  const [bufferZone, setBufferZone] = useState<SelectableObject | "">("");

  // Select and autocomplete options
  const bufferZones = useBufferZones();
  const [allPositions, setAllPositions] = useState<Position[]>([]);
  const [allValidPeriods, setAllValidPeriods] = useState<ValidPeriod[]>([]);
  const [allRefreshers, setAllRefreshers] = useState<RefreshCourseRowModel[]>(
    [],
  );

  function toggleCoursePriceFree(isCoursePriceFree: boolean) {
    setIsCoursePriceFree(isCoursePriceFree);
    if (isCoursePriceFree) {
      setCoursePrice(0);
    }
  }

  const isValidPeriodExpire = useMemo(() => {
    return !!defaultValidPeriod && +defaultValidPeriod.getId() > 1;
  }, [defaultValidPeriod]);

  useEffect(() => {
    (async () => {
      const [moodleCourse, pvp, allPositions, allValidPeriods, allRefreshers] =
        await Promise.all([
          getMoodleCourseById(courseId),
          getMoodlePositionValidPeriods(+courseId),
          getPositionsActive(),
          getValidPeriods(),
          getMoodleCourses(),
        ]);

      setSelectedPositionValidPeriods(pvp);
      setAllPositions(allPositions);
      setAllValidPeriods(allValidPeriods);
      setAllRefreshers(
        allRefreshers.map((r: CourseResponse) => new RefreshCourseRowModel(r)),
      );

      setCoursePrice(Number(moodleCourse.price ?? 0));
      setIsCoursePriceFree(moodleCourse.price === null);

      if (moodleCourse.validPeriodId) {
        const validPeriod = allValidPeriods.find((vp) =>
          vp.isEqual(moodleCourse.validPeriodId),
        );
        setDefaultValidPeriod(validPeriod || "");
      }
      const bufferZone = bufferZones.find((bz) =>
        bz.isEqual(moodleCourse.buffer ?? BUFFER_ZONES[2]),
      )!;
      setBufferZone(bufferZone);

      setSelectedRefreshers(
        moodleCourse.refreshers?.map(
          (r: RefresherCourseResponse) => new RefreshCourseRowModel(r),
        ) || [],
      );
      setCourseName(moodleCourse.name);
      setCourse(mapCourseForm(moodleCourse));
    })();
  }, [courseId]);

  const onSubmit = async (courseForm: CourseForm) => {
    const courseRequest = {
      ...courseForm,
      summary: courseForm.summary || null,
      summary2: courseForm.summary2 || null,
      validPeriodId: (defaultValidPeriod && defaultValidPeriod.getId()) || null,
      bufferZone: isValidPeriodExpire ? bufferZone && bufferZone.getId() : null,
      price: coursePrice,
      showAtAllCourses: courseForm.showAtAllCourses ? 1 : 0,
      positionValidPeriods: selectedPositionValidPeriods.map((pvp) => ({
        positionId: pvp.position!.id,
        validPeriodId: pvp.validPeriod!.id,
      })),
      refreshers: selectedRefreshers.map((refresher) => ({
        id: refresher.id,
        isActive: refresher.isActive ? 1 : 0,
      })),
    };

    const result = await updateMoodleCourse(
      courseId,
      courseRequest as CourseRequest,
    );
    if (result.ok) {
      history.push("/courses/moodle");
    }
  };

  return (
    <>
      <Typography component="h2" variant="h4">
        <Button icon={<ArrowBackIcon />} onClick={goBack} />
        {t("course.edit_moodle_action")}
      </Typography>
      {course && (
        <BasePaper>
          <Form defaultValues={{ ...course }} onSubmit={onSubmit}>
            <Row>
              <Input label={t("course_id")} value={courseId} disabled />
            </Row>
            <Row>
              <Input
                label={`${t("course.full_name")}*`}
                value={courseName}
                disabled
              />
            </Row>
            <Row>
              <Box
                sx={(theme) => ({
                  display: "flex",
                  alignItems: "center",
                  gap: theme.spacing(2),
                })}
              >
                <TextField
                  sx={{ width: "100%" }}
                  variant="outlined"
                  size="small"
                  label={t("course.price")}
                  value={coursePrice}
                  type="number"
                  onChange={(event) => {
                    setCoursePrice(+event.target.value);
                  }}
                  disabled={isCoursePriceFree}
                />
                <Typography component="span">{`${t("free")}:`}</Typography>
                <StyledSwitch
                  title={t("course.price_free_toggle")}
                  checked={isCoursePriceFree}
                  onChange={() => toggleCoursePriceFree(!isCoursePriceFree)}
                />
              </Box>
            </Row>
            <Row>
              <Select
                label={`${t("course.select_validity_period")}`}
                value={defaultValidPeriod}
                items={allValidPeriods}
                onChange={(selected) => setDefaultValidPeriod(selected)}
              />
            </Row>
            {isValidPeriodExpire && (
              <Row>
                <Select
                  label={t("course.select_buffer_zone")}
                  value={bufferZone!}
                  items={bufferZones}
                  onChange={(selected) => setBufferZone(selected!)}
                />
              </Row>
            )}
            <Row>
              <Typography
                component="span"
                sx={{ paddingLeft: "1rem" }}
              >{`${t("course.show_all_courses")}:`}</Typography>
              <Switch name="showAtAllCourses" />
            </Row>
            <DynamicList<PositionValidPeriod>
              renderChild={(props) => (
                <PositionPeriodRow
                  {...props}
                  allPositions={allPositions}
                  allPeriods={allValidPeriods}
                />
              )}
              initialList={selectedPositionValidPeriods}
              createItem={() => new PositionValidPeriod()}
              onChange={(positionPeriods) =>
                setSelectedPositionValidPeriods(positionPeriods)
              }
              addButtonText={t("course.add_position_period")}
            />
            <Row>
              <Textarea name="summary" label={t("summary")} />
            </Row>
            <Row>
              <Textarea name="summary2" label={`${t("summary")}2`} />
            </Row>
            <DynamicList<RefreshCourseRowModel>
              renderChild={(props) => (
                <RefresherRow
                  {...props}
                  allOptions={allRefreshers}
                  excludeOptions={selectedRefreshers}
                />
              )}
              initialList={selectedRefreshers}
              onChange={(refreshers) => setSelectedRefreshers(refreshers)}
              addButtonText={t("course.add_refresher_action")}
            />
            <Row>
              <Button type="submit">{t("course.save")}</Button>
              <Button onClick={goBack}>{t("cancel")}</Button>
            </Row>
          </Form>
        </BasePaper>
      )}
    </>
  );
};

export default MoodleCourseEdit;
