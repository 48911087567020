import React from "react";

import Tab from "@mui/material/Tab";
import { StyledTabs } from "../../components/Base";

const UserNavigation = ({ match, history }) => {
  const { crewId, userId, pageId } = match.params;

  const navigateTo = (_, value) => {
    const subpage = v(value);
    history.push(
      crewId
        ? `/crew/${crewId}/members/${userId}/${subpage}`
        : `/user/${subpage}`
    );
  };

  const pages = [
    "positions",
    "operations",
    "aircraft",
    "specializations",
    "all",
    "bundles",
  ];

  const rv = (subpage) => pages.indexOf(subpage);
  const v = (i) => pages[i];

  return (
    <StyledTabs
      variant="fullWidth"
      value={rv(pageId)}
      indicatorColor="primary"
      onChange={navigateTo}
    >
      <Tab label="Positions" />
      <Tab label="Operations" />
      <Tab label="Aircraft" />
      <Tab label="Specializations" />
      <Tab label="All Courses" />
      <Tab label="Bundles" />
    </StyledTabs>
  );
};

export default UserNavigation;
