import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";

import { getPendingCertificates, resolvePending } from "../../api/certificates";

import PendingCertificatesList from "../../components/PendingCertificateList";
import Confirm from "../../components/Confirm";

import { fireEvent } from "../../hooks/usePubsub";

const getUserName = (item) => item.user?.name || "";
const getCertificateDescription = (item) =>
  `(${(item.isCustom ? `cc_` : "") + item.courseId}) ${item.name}`;

const PendingCertificates = ({ match }) => {
  const { crewId } = match.params;
  const [pending, setPending] = useState(null);
  const [confirmItem, setConfirmItem] = useState(null);
  const [refresh, setRefresh] = useState(1);
  useEffect(() => {
    (async () => {
      const pendingCertificates = await getPendingCertificates(crewId);
      setPending(pendingCertificates);
    })();
  }, [crewId, refresh]);

  const onResolve = (item, status) => {
    setConfirmItem({
      item,
      status,
    });
  };
  const onConfirm = async (state) => {
    if (state) {
      const item = confirmItem.item;
      await resolvePending(crewId, item.id, confirmItem.status, item.isCustom);
      fireEvent("pending-changed");
    }
    setConfirmItem(null);
    setRefresh(refresh + 1);
  };

  return (
    <>
      <Typography variant="h4">Approve External Certificate</Typography>
      {pending ? (
        <PendingCertificatesList pending={pending} resolve={onResolve} />
      ) : null}
      {confirmItem ? (
        <Confirm title={"Confirmation"} onConfirm={onConfirm}>
          Are you sure you want to{" "}
          {confirmItem.status === "approve" ? " approve " : " decline "}
          certificate {getCertificateDescription(confirmItem.item)} for{" "}
          {getUserName(confirmItem.item)}? This action cannot be undone.
        </Confirm>
      ) : null}
    </>
  );
};

export default PendingCertificates;
