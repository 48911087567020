import React from "react";

import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Link from "@mui/material/Link";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import Button from "../../components/Button";
import TableComponent from "../TableComponent";

const CrewList = ({ crews, onExit }) => {
  return (
    <TableComponent
      header={[
        { title: "ID", sort: "id", style: { width: "5%" } },
        { title: "Name", sort: "name" },
        { title: "Status" },
        { title: "Actions" },
      ]}
      renderChildren={(crew) => (
        <TableRow key={crew.id}>
          <TableCell>{crew.id}</TableCell>
          <TableCell>{crew.name}</TableCell>
          <TableCell>{crew.status}</TableCell>
          <TableCell>
            <Link onClick={() => onExit(crew.id)}>
              <Button title="Delete affilation" icon={<ExitToAppIcon />} />
            </Link>
          </TableCell>
        </TableRow>
      )}
      items={crews}
    />
  );
};

export default CrewList;
