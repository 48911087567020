import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";

import TextField from "@mui/material/TextField";
import Button from "../../components/Button";
import { updateCategory, getCategoryById } from "../../api/categories";

import "./index.scss";

const CATEGORY_ERROR_TEXT = "Please enter the name of Category";

const CategoriesEdit = ({ match, history }) => {
  const [newCategoryName, setNewCategoryName] = useState("");
  const [isCategoryFetching, setIsCategoryNameFetching] = useState(false);

  useEffect(() => {
    (async () => {
      setIsCategoryNameFetching(true);
      const category = await getCategoryById(match.params.categoryId);
      setNewCategoryName(category.name);
      setIsCategoryNameFetching(false);
    })();
  }, [match.params.categoryId]);

  async function editCategoryName() {
    if (!newCategoryName) return;
    const categoryId = match.params.categoryId;

    const result = await updateCategory(categoryId, { name: newCategoryName });
    if (result.ok) {
      history.push("/categories");
    }
  }

  return (
    <>
      <h2>Edit category</h2>
      <Paper variant="outlined" style={{ padding: "1rem 1.5rem" }}>
        {!isCategoryFetching ? (
          <>
            <TextField
              className="edit-field"
              variant="outlined"
              size="small"
              label="Category name*"
              value={newCategoryName}
              onChange={(event) => setNewCategoryName(event.target.value)}
              error={!newCategoryName}
              helperText={newCategoryName ? null : CATEGORY_ERROR_TEXT}
            />
            <Button title="Save category name" onClick={editCategoryName}>
              Save Category
            </Button>
            <Button title="Go back" onClick={() => history.goBack()}>
              Cancel
            </Button>
          </>
        ) : null}
      </Paper>
    </>
  );
};
export default CategoriesEdit;
