import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";

import TextField from "@mui/material/TextField";
import Button from "../../components/Button";
import { updateAircraft, getAircraftById } from "../../api/aircraft";

import "./index.scss";

const AIRCRAFT_ERROR_TEXT = "Please enter the name of aircraft";

const AircraftEdit = ({ match, history }) => {
  const [newAircraftName, setNewAircraftName] = useState("");
  const [isAircraftFetching, setIsAircraftNameFetching] = useState(false);

  useEffect(() => {
    (async () => {
      setIsAircraftNameFetching(true);
      const aircraft = await getAircraftById(match.params.aircraftId);
      setNewAircraftName(aircraft.name);
      setIsAircraftNameFetching(false);
    })();
  }, [match.params.aircraftId]);

  async function editAircraftName() {
    if (!newAircraftName) return;
    const aircraftId = match.params.aircraftId;

    const result = await updateAircraft(aircraftId, { name: newAircraftName });
    if (result.ok) {
      history.push("/aircraft");
    }
  }

  return (
    <>
      <h2>Edit aircraft</h2>
      <Paper variant="outlined" style={{ padding: "1rem 1.5rem" }}>
        {!isAircraftFetching ? (
          <>
            <TextField
              className="edit-field"
              variant="outlined"
              size="small"
              label="Aircraft name*"
              value={newAircraftName}
              onChange={(event) => setNewAircraftName(event.target.value)}
              error={!newAircraftName}
              helperText={newAircraftName ? null : AIRCRAFT_ERROR_TEXT}
            />
            <Button title="Save Aircraft name" onClick={editAircraftName}>
              Save Aircraft
            </Button>
            <Button title="Go back" onClick={() => history.goBack()}>
              Cancel
            </Button>
          </>
        ) : null}
      </Paper>
    </>
  );
};
export default AircraftEdit;
