import React, { useState, useEffect } from "react";
import GetAppIcon from "@mui/icons-material/GetApp";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSnackbar } from "notistack";
import Button from "../../components/Button";
import Confirm from "../../components/Confirm";
import TableComponent from "../../components/TableComponent";
import HistoryService from "./service";
import { openDownloadWindow, openExportWindow } from "../../api";
import { formatDate } from "../../services/formatters";
import { COURSE_STATUS } from "../../constants";
import useUser from "../../hooks/useUser";
import useCrew from "../../hooks/useCrew";
import request from "../../api/request";
import {getUserCertificates, getUserRoles} from "../../api/user";

const openOrDownload = (certificate) => {
  const c = { ...certificate };
  if (c.isCustom && !/cc_/.test(c.id)) c.id = `cc_${c.id}`;
  if (c.fileName) {
    openDownloadWindow(c, c.userId);
  } else {
    openExportWindow(c.id, c.userId);
  }
};

const CourseHistory = ({
  userId,
  crewId,
  courses,
  certificate,
  userCertificates,
  onClose,
  className,
}) => {
  const user = useUser();
  const crew = useCrew();
  const { enqueueSnackbar: snack } = useSnackbar();
  const [historyItems, setHistoryItems] = useState([]);
  const [openDialog, setOpenDialog] = useState(true);
  const [refresh, setRefresh] = useState(1);
  const [isDeleteModalShow, setIsDeleteModalShow] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [isCustom, setIsCustom] = useState(null);

  const [isCrewAdmin, setIsCrewAdmin] = useState(false);


  function showDeleteModal(id, isCustom) {
    setSelectedItemId(id);
    setIsCustom(isCustom);
    setIsDeleteModalShow(true);
  }

  async function confirmDeleteModal(isConfirmed) {
    if (isConfirmed) {
      const { ok } = await request(`/user/${userId}/certificates/${selectedItemId}?custom=${isCustom}`, "DELETE");
      if (ok) {
        snack("Deleted", { variant: "success" });
      } else {
        snack("Failed to delete", { variant: "error" });
      }
    }
    setIsDeleteModalShow(false)
    setRefresh(refresh * -1);
  }

  useEffect(() => {
    (async () => {
      const userAmdin = user.crews.find( el => (el.id === crew.crew.id && el.admin));
      //const userAmdin = false
      const userRoles =  await getUserRoles(user.id);
      const userRolesInCrew =  userRoles.find((el) => el.id == crew.crew.id)
      if (userRolesInCrew) {
        const isNominatedPersonCrewTraining = userRolesInCrew.roles.find((el) => el.name === 'Nominated Person Crew Training')
        if (isNominatedPersonCrewTraining ){
          setIsCrewAdmin(true);
        }
      }


      const userCertificates = await getUserCertificates(userId, crew.crew.id);
      const historyItems = HistoryService.getHistory(
        certificate,
        courses,
        userCertificates,
        userId
      );
      setHistoryItems(historyItems);
    })();
  }, [refresh]);

  return (
    <Dialog open={openDialog} onClose={onClose} maxWidth="lg" className={className}>
      <DialogTitle>Course History and Refresher Courses</DialogTitle>
      <DialogContent>
        <TableComponent
          header={[
            { title: "Id", style: { width: "10%" } },   
            { title: "Course Name", style: { width: "30%" } },
            { title: "Completion Date", style: { width: "20%" } },
            { title: "Valid Until", style: { width: "20%" } },
            { title: "Uploaded on" },
            { title: "Approved on" },
            { title: "Approved by" },
            { title: "Export File", style: { width: "20%" } },
          ]}
          items={historyItems}
          renderChildren={(item) => (
            <TableRow key={item.id}>
            <TableCell>{item.id}</TableCell>
            <TableCell>{item.name}</TableCell>
            <TableCell>{formatDate(item.completedDate)}</TableCell>
            <TableCell>
              {item.validUntil
                ? formatDate(item.validUntil)
                : COURSE_STATUS.NEVER_EXPIRE}
            </TableCell>
            <TableCell>{item.uploadedAt}</TableCell> 
            <TableCell>{item.approvedAt}</TableCell>
            <TableCell>{item.approvedBy || ""}</TableCell>
            <TableCell>
              <Button
                title="Download Certificate"
                icon={<GetAppIcon />}
                onClick={() => openOrDownload(item)}
              />
              {(item.isCustom || item.fileName) && (user.sa || isCrewAdmin) && item.uploadId &&
                <Button
                  title="Delete Upload"
                  icon={<DeleteIcon />}
                  onClick={() => showDeleteModal(item.uploadId, item.isCustom)}
                />
              }
            </TableCell>
          </TableRow>
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()}>Close</Button>
      </DialogActions>

      {isDeleteModalShow && selectedItemId ? (
        <Confirm title={"Delete"} onConfirm={confirmDeleteModal}>
          <p>Are you sure you want to delete?</p>
        </Confirm>
      ) : null}
    </Dialog>
  );
};

export default CourseHistory;
