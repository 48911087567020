import React from "react";
import { Route } from "react-router-dom";

import Observalbe from "../../features/observable";
import EditObservalbe from "../../features/observable/edit";

const Observable = ({ match }) => (
  <>
    <Route path={`${match.path}/add`} component={EditObservalbe} />
    <Route path={`${match.path}/edit/:observableId`} component={EditObservalbe} />
    <Route exact path={`${match.path}`} component={Observalbe} />  
  </>
);

export default Observable;
