import React from "react";

import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";

import Button from "../Button";
import Autocomplete from "../Form/StyledAutocomplete";
import { useTranslation } from "react-i18next";

const TrainingDependenciesForm = ({
  trainings,
  dependencies,
  selectedTraining,
  selectedDependencies,
  onChange,
  onSave,
  error,
}) => {
  const { t } = useTranslation();
  return (
    <Paper variant="outlined" style={{ marginBottom: "2rem", padding: "1rem" }}>
      <Box mb={"1rem"} display={"flex"}>
        <Box width={"50%"} mr={1}>
          <Autocomplete
            onChange={(item, reason) => onChange("training", item, reason)}
            selected={selectedTraining}
            items={trainings || []}
            label="Select training"
          />
        </Box>
        <Box width={"50%"}>
          <Autocomplete
            onChange={(item, reason) => onChange("dependencies", item, reason)}
            selected={selectedDependencies || []}
            items={dependencies || []}
            label="Select dependecies training"
            multiple={true}
          />
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Button onClick={onSave}>Add</Button>
      </Box>
      {error ? <Typography color="error">{error}</Typography> : null}
    </Paper>
  );
};

export default TrainingDependenciesForm;
