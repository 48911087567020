export const parseUserName = (username) => {
  const [firstName, ...lastname] = username.split(" ");
  return {
    firstName: firstName || "",
    lastName: lastname?.join(" ") || "",
  };
};


export const concatRolesUsers = (userList) =>{
  const newRes = []
  userList.forEach( (item) => {
    const searchObject= newRes.find((e) => e.training_id === item.training_id && e.crew_id === item.crew_id && e.user_id === item.user_id);
    if (searchObject) {
      if (searchObject.role_name && item.role_name) {
        searchObject.role_name = searchObject.role_name.concat(', ', item.role_name);
      } 
    } else {
      newRes.push(item);
    }
  })
  return newRes
} 


export const concatOperationUsers = (userList) =>{
  const newRes = []
  userList.forEach( (item) => {
    const searchObject= newRes.find((e) => e.user_id === item.user_id && e.crew_id === item.crew_id && e.user_id === item.user_id);
    if (searchObject) {
      if (searchObject.operation_name && item.operation_name) {
        searchObject.operation_name = searchObject.operation_name.concat(', ', item.operation_name);
      } 
    } else {
      newRes.push(item);
    }
  })
  return newRes
} 

