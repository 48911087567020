import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import useCrew from "../../hooks/useCrew";
import useUser from "../../hooks/useUser";
import React, { useState, useEffect } from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Button from "../../components/Button";
import TableComponent from "../../components/TableComponent";
import { getCrewTrainings  } from "../../api/training";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';



function formatDate(string){
  return new Date(string).toLocaleString('de-CH', {day: '2-digit', month:'2-digit', year:'2-digit'});
}


function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const CrewTrainings = ({ match, history }) => {

  const { crew } = useCrew();
  const { t } = useTranslation();
  const crewId = crew.id;
  const [crewTrainings, setCrewTrainings] = useState([]);
  const user = useUser();     
  //const eventType = 'trainer';

  useEffect(() => {
    (async () => {
      const res = await getCrewTrainings(crewId);   
      setCrewTrainings(res);
    })();
  }, [crew]);

  return (  
    <>
      <Typography variant="h4">Crew Training</Typography>
      {crewTrainings? (
        <TableComponent
          header={[
            { title: "ID", style: { width: "5%" } },
            { title: "Course ID", style: { width: "5%" } },
            { title: "Training" },
            { title: "Start Date" },
            { title: "End Date"},
            { title: "Positions"},
            // { title: "Actions" },
          ]}


          renderChildren={(crewTraining) => (
            <TableRow key={crewTraining.id}>
              <TableCell>{crewTraining.id}</TableCell>
              <TableCell>{crewTraining.course_id}</TableCell>
              <TableCell>{crewTraining.displayname}</TableCell>
              <TableCell>{formatDate(crewTraining.training_start_date)}</TableCell>
              <TableCell>{formatDate(crewTraining.training_end_date)}</TableCell>
              <TableCell>{crewTraining.positions}</TableCell>
{/*             
              <TableCell>
                  <Button
                    icon={<ManageAccountsIcon />}
                    onClick={() => history.push(`/training-users/user/inscribe/${crewTraining.id}/${crewTraining.course_id}`)}
                    title="Manage users"
                  />
              </TableCell>

 */}
            </TableRow>
          )}
          search={["id","displayname"]}
          pagination
          items={crewTrainings}
        />
      ) : null}
      

    </>
  );
};

export default CrewTrainings