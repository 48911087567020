import React, { useState, useEffect, useRef } from "react";
import Typography from "@mui/material/Typography";
import TrainingDependenciesForm from "../../components/TrainingDependencies/TrainingDependenciesForm";
import { useTranslation } from "react-i18next";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Link from "@mui/material/Link";
import Button from "../../components/Button";
import Confirm from "../../components/Confirm";

import {
  getTrainingsInitial,
  setNewDependencies,
  getTrainingDependencies,
  deleteDependenciesFromTraining,
} from "../../api/training";


function getStringToShowById(id, trainings) {
  let item = trainings.find(item => item.id === id);
  return item ? `(${item.id}) ${item.name}`  : null;
}

const TrainingDependencies = ({  }) => {
  const scrollToRef = useRef();
  const [trainings, setTrainings] = useState(null);
  const [dependencies, setDependencies] = useState(null);
  const [selectedTraining, setSelectedTraining] = useState(null);
  const [selectedDependencies, setSelectedDependencies] = useState([]);
  const [trainingDependencies, setTrainingDependencies] = useState(null);
  const [isDeleteModalShow, setIsDeleteModalShow] = useState(false);
  const [selectedTrainingId, setSelectedTrainingId] = useState(null);
  const [error, setError] = useState(null);
  const [refresh, setRefresh] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      const trainings = await getTrainingsInitial();
      const trainingDependencies = await getTrainingDependencies()

      let resultArray = Object.keys(trainingDependencies).map(key => ({
        training_id: parseInt(key),
        dependecies_id: trainingDependencies[key]
      }));

      let resultToShow = resultArray.map(item => ({
        ...item,
        id: item.training_id,
        training: getStringToShowById(item.training_id, trainings),
        dependecies: item.dependecies_id.map(id => getStringToShowById(id, trainings)),
      }));
      // filter out from trainings the ones that are already in the resultArray item.id
      const trainingsToSet = trainings.filter(item => !resultArray.find(i => i.training_id === item.id));
      setTrainingDependencies(resultToShow);
      setTrainings(trainingsToSet);
      setDependencies(trainings);
    })();
  }, [refresh]);

  const onChange = (type, item) => {
    setError(null);
  
    if (type === "training") {
      setSelectedTraining(item);
    } else {
      setSelectedDependencies(item || []);
      
    }
    scrollToRef.current.scrollIntoView();
  };

  const onSave = async () => {
    if (!selectedTraining) {
      setError("Please select training");
    }
    const training = selectedTraining.id;
    const dependencies = selectedDependencies?.map((p) => p.id);
    const response = await setNewDependencies(training, dependencies);
    if (response.ok) {
      onChange("training", null);
      setSelectedDependencies([])
      setRefresh(refresh + 1);
    }
  };

  const onDelete = async (trainingId) => {
    setIsDeleteModalShow(true);
    setSelectedTrainingId(trainingId);
  };

  async function confirmDeleteModal(isConfirmed) {
    if (isConfirmed) {
      const response = await deleteDependenciesFromTraining(
        selectedTrainingId,
      );
    }
    setIsDeleteModalShow(false);
    setRefresh(refresh + 1);
  }
  return (
    <>
      <Typography variant="h4">Trainings dependencies</Typography>
      {trainings ? (
        <div ref={scrollToRef}>
          <TrainingDependenciesForm
            trainings={trainings}
            dependencies={dependencies}
            selectedTraining={selectedTraining}
            selectedDependencies={selectedDependencies}
            onChange={onChange}
            onSave={onSave}
            error={error}
          />{" "}
        </div>
      ) : null}

      {trainingDependencies ? (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Training</TableCell>
                <TableCell >Dependencies</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {trainingDependencies.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">{row.training}</TableCell>
                  <TableCell>
                    <ul>
                      {row.dependecies.map((item, index) => (
                        <li key={index}>{item}</li>
                      ))}
                    </ul>                    
                  </TableCell>

                  <TableCell align="right">
                  <Link onClick={() => onDelete(row.id)}>
                    <Button
                      title="Delete dependecies"
                      icon={<DeleteForeverIcon color="error" />}
                    />
                  </Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : null}
      {isDeleteModalShow  ? (
        <Confirm title={"Delete Training Dependecies"} onConfirm={confirmDeleteModal}>
          <p>Are you sure you want to delete the training dependecies?</p>
        </Confirm>
      ) : null}
    </>
  );
};

export default TrainingDependencies;
