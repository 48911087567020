import React, { useState } from "react";

import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import Button from "../../components/Button";

import CertificateActions, {
  useCertificateActions,
} from "../../context/CertificateActions";

import CertificateStatus from "./CertificateStatus";
import Actions from "./Actions";
import CourseStatusIcon from "../CourseStatusIcon";
import CertificateList from "./CertificateList";

const Refresher = ({ certificate }) => {
  const [visible, setVisible] = useState(false);
  const lastRefresherArray = [
    ...certificate.refreshers.sort((a, b) => b.validUntil - a.validUntil),
  ];
  const style = {};
  if (visible) {
    style.border = 0;
  }
  const actions = useCertificateActions();

  const name = certificate.name.replace(/ \W initial$/i, '');

  return (
    <>
      <TableRow style={style}>
        <TableCell style={{ ...style, width: "2%" }}>
          <CourseStatusIcon status={certificate.status} />
        </TableCell>
        <TableCell onClick={() => setVisible(!visible)} style={style}>
          ({certificate.id}) {name}
          <Button
            title={visible ? "Close" : "Open"}
            icon={visible ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          />
        </TableCell>
        <TableCell style={style}>
          {certificate.validPeriod ? `${certificate.validPeriod} months` : ""}
        </TableCell>
        <TableCell style={{ ...style, width: "15%" }}>
          <CertificateStatus
            validUntil={certificate.validUntil}
            status={certificate.status}
          />
        </TableCell>
        <TableCell style={{ ...style, width: "12%" }} align={"justify"}>
          <Actions certificate={certificate} />
        </TableCell>
      </TableRow>
      {visible ? (
        <TableRow>
          <CertificateActions.Provider
            value={{
              getBoughtCourses() {
                return actions.getBoughtCourses();
              },
            }}
          >
            <TableCell colSpan={5} style={{ padding: 0 }}>
              <CertificateList
                certificates={lastRefresherArray}
                header={false}
                variant="borderless"
              />
            </TableCell>
          </CertificateActions.Provider>
        </TableRow>
      ) : null}
    </>
  );
};

export default Refresher;
