import request from "./request";

/* 
export const getScheduleTraining = async (courseTrainingId) => {
  const json = await request(`/schedule-training/${courseTrainingId}`);
  return json;
};



export const addScheduleTrainings = async (body) => {
  const response = await request(`/schedule-training`, "POST", body);
  return response;
};


export const getScheduleTrainingById = async (scheduleTrainingById, trainingId) => {
  const json = await request(`/schedule-training/${scheduleTrainingById}/${trainingId}`);
  return json;
};


export const updateScheduleTraining = async (id, newScheduleTrainings) => {
  const response = await request(`/schedule-training/${id}`, "PUT", newScheduleTrainings);
  return response;
};

export const getSchedulePractical = async (courseTrainingId) => {
  const json = await request(`/schedule/practical/${courseTrainingId}`);
  return json;
};


 */

export const getAllEventScheduled = async () => {
  const json = await request(`/schedule`);   
  return json;
};

export const getEventScheduled = async (eventType, trainingId, crewId) => {
  const json = await request(`/schedule/${eventType}/${trainingId}/${crewId}`);    
  return json;
};


export const addSchedule = async (body) => {
  
  body.startDateText = body.scheduleTrainingStartDate.format('DD.MM.YYYY HH:mm');
  body.endDateText = body.scheduleTrainingEndDate.format('DD.MM.YYYY HH:mm');
  const response = await request(`/schedule`, "POST", body);
  return response;
};

export const deleteScheduleSlotById = async (slotId) => {
  const json = await request(`/schedule/${slotId}`, "DELETE");
  return json;
};





