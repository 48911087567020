import React from "react";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AlarmOnIcon from "@mui/icons-material/AlarmOn";
import ErrorIcon from "@mui/icons-material/Error";

import { COURSE_STATUS } from "../../constants";

const CourseStatusIcon = ({ status, constants = COURSE_STATUS }) => {
  if (!status) return <div style={{ width: 20, height: 20 }} />;

  return status === constants.EXPIRED ? (
    <ErrorIcon htmlColor="red" />
  ) : status === constants.MISSING ? (
    <ErrorIcon htmlColor="red" />
  ) : status === constants.BUFFER ? (
    <AlarmOnIcon htmlColor="#ffbf00" />
  ) : (
    <CheckCircleIcon htmlColor="green" />
  );
};

export default CourseStatusIcon;
