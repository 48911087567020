import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { StyledSwitch } from "../../../components/Base";
import Button from "../../../components/Button";
import TableComponent from "../../../components/TableComponent";
import Confirm from "../../../components/Confirm";
import { getUsersTraining, getUsersTrainingByTrainingId, inscribeOrUniscribeUserToCourse, getTraining } from "../../../api/training";
import { concatRolesUsers } from "../../../services/user";



const InscribeTraining = ({ match, history }) => {

  const [usersToInscribe, setUsersToInscribe] = useState(null);
  const [trainingCourse, setTrainingCourse] = useState(null);
  const [refresh, setRefresh] = useState(1);
  const [isModalShow, setIsModalShow] = useState(false);

  useEffect(() => {
    (async () => {
      const trainingId = match.params.trainingId;
      const usersToInscribe = await getUsersTrainingByTrainingId(trainingId);  
      setUsersToInscribe(usersToInscribe.filter(item => !['trainer', 'instructor', 'checker', 'assessor'].includes(item.role_name) ))

    })();
  }, [refresh]);

  async function confirmModal() {
    setIsModalShow(false)
  }

  return (
    <>
      <Typography variant="h4">Incribed users</Typography>
      {usersToInscribe? (
        <TableComponent
          header={[
            { title: "User ID", style: { width: "5%" } },
            { title: "User"},
            { title: "Operation"},
            { title: "Role"},
            { title: "Crew" },
            { title: "Inscribe" },
          ]}
          renderChildren={(userToInscribe,  index) => (
            <TableRow key={index}>
              <TableCell>{userToInscribe.user_id}</TableCell>
              <TableCell>{userToInscribe.fullname}</TableCell>
              <TableCell>{userToInscribe.operation_name}</TableCell>
              <TableCell>{userToInscribe.role_name}</TableCell>
              <TableCell>{userToInscribe.company_name}</TableCell>
              <TableCell>
                <StyledSwitch
                  title="Inscribe user to course"
                  checked={userToInscribe.inscribe_id != null}
                  onChange={async () => {
/*                     
                    if (trainingCourse.userInscribe < trainingCourse.trainingSlot) {
                      await inscribeOrUniscribeUserToCourse(userToInscribe.inscribe_id, userToInscribe.id, userToInscribe.user_id, userToInscribe.crew_id, userToInscribe.course_id, 'user', userToInscribe.valid_period);  
                    } else if  (trainingCourse.userInscribe == trainingCourse.trainingSlot && !event.target.checked) { 
                      await inscribeOrUniscribeUserToCourse(userToInscribe.inscribe_id, userToInscribe.id, userToInscribe.user_id, userToInscribe.crew_id, userToInscribe.course_id, 'user', userToInscribe.valid_period); 
                    } else {
                      setIsModalShow(true)
                    }       
 */
                    await inscribeOrUniscribeUserToCourse(userToInscribe.inscribe_id, userToInscribe.id, userToInscribe.user_id, userToInscribe.crew_id, userToInscribe.course_id, 'user', userToInscribe.valid_period);
                    setRefresh(refresh + 1);
                  }}
                />
              </TableCell>
            </TableRow>
          )}
          search={["user_id","fullname", "company_name"]}
          pagination
          items={usersToInscribe}
        />
      ) : null}
      <Button
            title="Go back"
            type="button"
            onClick={() => history.push("/trainings")}
          >
            Return to all Trainings
      </Button>

      {isModalShow  ? (
        <Confirm title={"Max slots"} notification={true} onConfirm={confirmModal}>
          <p>The maximum number of places available has been reached</p>
        </Confirm>
      ) : null}
      
    </>
  );
};

export default InscribeTraining;