const PropertiesService = {
  toDisplayObject(userProperties, relations) {
    return userProperties.map((property) => {
      const category = relations.categories.find(
        (cat) => cat.id === property.categoryId
      );
      const position = relations.positions.find(
        (cat) => cat.id === property.positionId
      );
      const operation = relations.operations.find(
        (cat) => cat.id === property.operationId
      );

      const label = [category.name, position?.name, operation?.name]
        .filter((x) => !!x)
        .join(" - ");

      return {
        label,
        categoryId: property.categoryId,
        positionId: property.positionId,
        operationId: property.operationId,
      };
    });
  },
  relationsToDisplayObject(relations) {
    const categories = relations.categories.reduce((acc, val) => {
      acc[val.id] = {
        ...val,
        positions: relations.positions
          .filter((p) => p.categoryId === val.id)
          .map((position) => {
            const operations = relations.operations.filter((operation) =>
              position.operations.includes(operation.id)
            );
            return {
              ...position,
              operations,
            };
          }),
      };
      return acc;
    }, {});

    return Object.values(categories);
  },
};

export default PropertiesService;
