import React from "react";
import { Route } from "react-router-dom";

import AssociatePositionProcessStepTable from "../../features/associate-position-process-step";
import EditAssociatePositionProcessStep from "../../features/associate-position-process-step/edit";

const AssociatePositionProcessStep = ({ match }) => (
  <>
    <Route path={`${match.path}/add`} component={EditAssociatePositionProcessStep} />
    <Route path={`${match.path}/edit/:associatePositionProcessStepId`} component={EditAssociatePositionProcessStep} />
    <Route exact path={`${match.path}`} component={AssociatePositionProcessStepTable} />  
  </>
);

export default AssociatePositionProcessStep;
