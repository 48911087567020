import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Button from "../../components/Button";

import { getCategories } from "../../api/categories";
import { addPosition } from "../../api/positions";

import "./index.scss";

const POSITION_ERROR_TEXT = "Please enter the name of position";
const POSITION_CATEGORY_ERROR_TEXT = "Please select the category";

const PositionsAdd = ({ history }) => {
  const [positionName, setPositionName] = useState("");
  const [categories, setCategories] = useState([]);
  const [positionCategoryId, setPositionCategoryId] = useState("");

  const [positionErrorText, setPositionErrorText] = useState(null);
  const [positionCategoryErrorText, setPositionCategoryErrorText] = useState(
    null
  );

  useEffect(() => {
    (async () => {
      let newCategories = await getCategories();
      setCategories(newCategories);
    })();
  }, []);

  function handlePositionName(value) {
    setPositionName(value);
    if (!value) return setPositionErrorText(POSITION_ERROR_TEXT);
    setPositionErrorText(null);
  }

  function handlePositionCategoryName(value) {
    setPositionCategoryId(value);
    if (!value)
      return setPositionCategoryErrorText(POSITION_CATEGORY_ERROR_TEXT);
    setPositionCategoryErrorText(null);
  }

  async function addNewPosition() {
    if (!positionCategoryId)
      return handlePositionCategoryName(positionCategoryId);
    if (!positionName) return handlePositionName(positionName);

    const result = await addPosition(positionName, positionCategoryId);
    if (result.ok) {
      history.push("/positions");
    }
  }

  return (
    <>
      <h2>Add position</h2>
      <Paper
        className="add-position"
        variant="outlined"
        style={{ padding: "1rem 1.5rem" }}
      >
        <FormControl
          variant="outlined"
          size="small"
          error={!!positionCategoryErrorText}
        >
          <InputLabel id="selectCategory">Select Category</InputLabel>
          <Select
            className="select-category"
            labelId="selectCategory"
            label="Select Category"
            value={positionCategoryId}
            onChange={(event) => handlePositionCategoryName(event.target.value)}
          >
            {categories.map((category) => (
              <MenuItem key={category.id} value={category.id}>
                {category.name}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{positionCategoryErrorText}</FormHelperText>
        </FormControl>

        <TextField
          className="add-field"
          variant="outlined"
          size="small"
          label="Position name*"
          value={positionName}
          onChange={(event) => handlePositionName(event.target.value)}
          error={!!positionErrorText}
          helperText={positionErrorText}
        />
        <Button title="Add new position" onClick={addNewPosition}>
          Add Position
        </Button>
        <Button title="Go back" onClick={() => history.goBack()}>
          Cancel
        </Button>
      </Paper>
    </>
  );
};

export default PositionsAdd;
