import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import { sortBy } from "lodash";
import TextField from "@mui/material/TextField";
import Button from "../../components/Button";
import { Row } from "../../components/Form";
import "./index.scss";
import {updateNgftApp,getNgftAppById} from "../../api/ngftApp";
import { getCrews } from "../../api/crew";
import { getRoles } from "../../api/roles";
import Autocomplete from "../../components/Form/StyledAutocomplete";


const NgftAppsEdit = ({ match, history }) => {
  const [isDataFetched, setIsDataFetched] = useState(false);
  const [newName, setNewName] = useState("");
  const [newShortName, setNewShortName] = useState("");
  const [newColor, setNewColor] = useState("");
  const [newTextColor, setNewTextColor] = useState("");
  const [newIcon, setNewIcon] = useState("");
  const [newLink, setnewLink] = useState("");
  const [crews, setCrews] = useState([]);
  const [selectedCrews, setSelectedCrews] = useState([]);
  const [roles, setRoles] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);



  useEffect(() => {
    (async () => {
      
      const ngftApp = await getNgftAppById(match.params.ngftAppsId);
      setIsDataFetched(true)
      setNewName(ngftApp.name || "");
      setNewShortName(ngftApp.short_name || "");
      setNewColor(ngftApp.color || "");
      setNewTextColor(ngftApp.text_color || "");
      setNewIcon(ngftApp.icon || "");
      setnewLink(ngftApp.link || "");

      // get crews
      const crews = await getCrews();
      setCrews(crews);
      const apps2Companies = ngftApp.Apps2Companies.map(a2c => a2c.company_id);
      const filteredCrews = crews.filter(crew => apps2Companies.includes(crew.id));
      setSelectedCrews(filteredCrews);

      // get roles
      const roles = await getRoles();
      setRoles(roles);
      const apps2Roles = ngftApp.Apps2Roles.map(a2c => a2c.role_id);
      const filteredRoles = roles.filter(role => apps2Roles.includes(role.id));
      setSelectedRoles(filteredRoles);

    })();
  }, []);

  const [groupNameErrorText, setGroupNameErrorText] = useState(null);

  async function save() {

    if (newName == "" || newShortName == "" || newColor == "" || newTextColor == "" || newIcon == ""  ){
      return
    } else {

      const id = match.params.ngftAppsId;
      const result = await updateNgftApp(id, {
        name: newName,
        shortName: newShortName,
        color: newColor,
        textColor: newTextColor,
        icon: newIcon,
        link: newLink,
        inscribedCrews: selectedCrews.map(crew => crew.id),
        associatedRoles: selectedRoles.map(role => role.id),

      });
      if (result.ok) {
        history.push("/ngft_apps");
      }
    }
  }
  return (
    <>
      <h2>Edit Apps</h2>
      <Paper
        variant="outlined"
        style={{ padding: "1rem 1.5rem" }}
      >
        {isDataFetched ? (
          <>
              <Row>
                <TextField fullWidth 
                  variant="outlined"
                  size="small"
                  label="Name*"
                  value={newName}
                  onChange={(event) => setNewName(event.target.value)}
                  error={newName === ""}
                  helperText={newName === "" ? "Please add value!" : " "}
                />
              </Row>
              <Row>
                <TextField fullWidth 
                  variant="outlined"
                  size="small"
                  label="Short Name*"
                  value={newShortName}
                  onChange={(event) => setNewShortName(event.target.value)}
                  error={newShortName === ""}
                  helperText={newShortName === "" ? "Please add value!" : " "}
                />
              </Row>
              <Row>
                <TextField fullWidth 
                  variant="outlined"
                  size="small"
                  label="Color*"
                  value={newColor}
                  onChange={(event) => setNewColor(event.target.value)}
                  error={newColor === ""}
                  helperText={newColor === "" ? "Please add value!" : " "}    
                />
              </Row>
              <Row>
                <TextField fullWidth 
                  variant="outlined"
                  size="small"
                  label="Text color*"
                  value={newTextColor}
                  onChange={(event) => setNewTextColor(event.target.value)}
                  error={newTextColor === ""}
                  helperText={newTextColor === "" ? "Please add value!" : " "}     
                /> 
              </Row>
              <Row>
                <TextField fullWidth 
                  variant="outlined"    
                  size="small"
                  label="Link*"
                  value={newLink}
                  onChange={(event) => setnewLink(event.target.value)}
                /> 
              </Row>
              <Row>
                <TextField fullWidth 
                  variant="outlined"
                  size="small"
                  label="Icon*"
                  value={newIcon}
                  onChange={(event) => setNewIcon(event.target.value)}
                  error={newIcon === ""}
                  helperText={newIcon === "" ? "Please add value!" : " "}     
                /> 
              </Row>
              <Row>
                <Autocomplete
                  multiple
                  name="Roles"
                  label="Roles to add"
                  items={roles}
                  selected={selectedRoles}
                  onChange={(items) => {
                    setSelectedRoles(items);
                  }}
                  formatLabel={(item) => item.name}
                />
              </Row>
              <Row>
                <Autocomplete
                  multiple
                  name="crew"
                  label="Crews to add"
                  items={crews}
                  selected={selectedCrews}
                  onChange={(items) => {
                    setSelectedCrews(items);
                  }}
                  formatLabel={(item) => item.companyName}
                />
              </Row>
              <Button title="Save" onClick={save}>
                Save
              </Button>

              <Button
                title="Go back"
                type="button"
                onClick={() => history.goBack()}
              >
                Cancel
              </Button>
          </>
        ) : null}
      </Paper>
    </>
  );
};

export default NgftAppsEdit;
