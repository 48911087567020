import React from "react";

import Typography from "@mui/material/Typography";
import { Row } from "../../components/Base";

export const OrderPaidSuccess = () => (
  <>
    <Row>
      <Typography variant="h4">The courses are ready.</Typography>
    </Row>

  </>
);

export const OrderPaidSuccessUpdateFailed = () => ( 
  <>
    <Row>
      <Typography variant="h4">
      
        There was an error processing your payment. You have already paid
      </Typography>
    </Row>
    <Row>
      <Typography>Please wait for an receipt-mail not repeat the order.</Typography>
    </Row>
    <Typography>
      If you or your users need an assistance, please let us know at{" "}
      <a href="mailto:support@ngft.com">support@ngft.com</a>
    </Typography>
  </>
);


export const OrderPaidFail = () => (
  <>
    <Row>
      <Typography variant="h4">
        There was an error processing your payment. 
      </Typography>
    </Row>
    <Row>
      <Typography>An error occured while processing your payment.</Typography>
    </Row>
    <Typography>
      If you or your users need an assistance, please let us know at{" "}
      <a href="mailto:support@ngft.com">support@ngft.com</a>
    </Typography>
  </>
);
