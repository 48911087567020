
import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import Button from "../../components/Button";
import TableComponent from "../../components/TableComponent";
import {  deleteWrittenTest,  getUsersInscribed, getUsersAttemps } from "../../api/training";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Confirm from "../../components/Confirm";
import InfoIcon from '@mui/icons-material/Info';
import request from "../../api/request"; 
import TrainingModuleStatus from "../../components/TrainingModuleStatus";
import useCrew from "../../hooks/useCrew";


export async function getSSID() {
  const ssid = await request(`/sts`);
  return ssid;
}


const downloadWrittenTest = async (fileName) => {
  const ssid = await getSSID();
  window.open(
    `${process.env.REACT_APP_API_URL}/files/download/written-test/${fileName}?ssid=${ssid}`,
    "_blank"
  );
};

const WrittenTestTraining = ({ match, history }) => {
  const [usersWrittenTest, setUsersWrittenTest] = useState([]);
  const [selectedTrainingId, setTrainingId] = useState(null);
  const [selectedOperationId, setSelectedOperationId] = useState(null);
  const [isDeleteModalShow, setIsDeleteModalShow] = useState(false);
  const [refresh, setRefresh] = useState(1);
  const  {crew}  = useCrew();  

  function showDeleteModal(id) {
    setSelectedOperationId(id);
    setIsDeleteModalShow(true);
  }

  async function confirmDeleteModal(isConfirmed) {
    if (isConfirmed) {
      await deleteWrittenTest(selectedOperationId);
    }
    setIsDeleteModalShow(false);
    setRefresh(refresh * -1);
  }


  useEffect(() => {
    (async () => {
      const trainingId = match.params.trainingId;
      setTrainingId(trainingId);
      const usersInscribed = await getUsersInscribed(trainingId, crew.id); 
      const usersAttemps = await getUsersAttemps(trainingId,usersInscribed); 


      setUsersWrittenTest(usersAttemps)
    })();
  }, [refresh]);


  return (
    <>
      <Typography variant="h4">Written test</Typography>
      {usersWrittenTest? (
        <TableComponent
          header={[
            { title: "User ID", style: { width: "5%" } },
            { title: "User"},
            { title: "Attemps"},
            { title: "Passed"},
            { title: "Actions" },
          ]}
          //writtenTestId
          renderChildren={(userWrittenTest,  index) => (
            <TableRow key={index}>
              <TableCell>{userWrittenTest.userId}</TableCell>
              <TableCell>{userWrittenTest.fullname}</TableCell>
              <TableCell>{userWrittenTest.attemps}</TableCell> 
              <TableCell>
                <TrainingModuleStatus status={userWrittenTest.passed}  />
              </TableCell> 

              <TableCell>
              <Button
                      icon={<InfoIcon />}
                      onClick={() => history.push(`/training-users/user/written-test-info/${selectedTrainingId}/${userWrittenTest.userId}`)} 
                      title="Written test info"  
                  />   
              { (userWrittenTest.attemps < process.env.REACT_APP_ATTEMPS) && (userWrittenTest.passed === 0) ? (
                  <>
                      <Button
                      icon={<AddCircleIcon />}
                      onClick={() => history.push(`/training-users/user/written-test/${selectedTrainingId}/${userWrittenTest.userId}`)} 
                      title="Add written test"  
                      />   
                  </>             
                ) : null}                  



              </TableCell>
              
            </TableRow>
          )}
          search={["user_id","fullname", "company_name"]}
          pagination
          items={usersWrittenTest}
        />
      ) : null}
      <Button title="Back" onClick={() => history.goBack()}>
              Back
      </Button>
      {isDeleteModalShow && selectedOperationId ? (
        <Confirm title={"Delete Written Test"} onConfirm={confirmDeleteModal}>
          <p>Are you sure you want to delete the written Test?</p>
        </Confirm>
      ) : null}
    </>
  );
};

export default WrittenTestTraining;
