import React, { useState, useEffect } from "react";
import PostAddIcon from "@mui/icons-material/PostAdd";

import Button from "../../components/Button";
import TableComponent from "../../components/TableComponent";
import Confirm from "../../components/Confirm";

import NewsItem from "./NewsItem";

import {
  getNews,
  deleteNewsById,
} from "../../api/news";



const NewsTable = ({ match, history }) => {
  const [newsList, setNewsList] = useState([]);
  const [selectedOperationId, setSelectedOperationId] = useState(null);
  const [isDeleteModalShow, setIsDeleteModalShow] = useState(false);
  const [refresh, setRefresh] = useState(1);

  function showDeleteModal(id) {
    setSelectedOperationId(id);
    setIsDeleteModalShow(true);
  }

  function onEdit(id) {
    history.push(`${match.url}/edit/${id}`);
  }



  async function confirmDeleteModal(isConfirmed) {
    if (isConfirmed) {
      await deleteNewsById(selectedOperationId);
    }
    setIsDeleteModalShow(false);

    setRefresh(refresh * -1);
  }

  useEffect(() => {
    (async () => {
      const newsList = await getNews();
      setNewsList(newsList);
    })();
  }, [refresh]);

  return (
    <>
      <h2>News</h2>
      <TableComponent
        TopComponent={
          <Button
            size="small"
            onClick={() => history.push(`${match.path}/add_news`)}
          >
            <PostAddIcon />
            Add News
          </Button>
        }
        header={[
          { title: "ID", sort: "id", style: { width: "5%" } },
          { title: "Source" },
          { title: "Title" },
          { title: "Description", style: { width: "35%" }},
          { title: "Link" },
          { title: "Published", style: { width: "5%" }},
          { title: "Actions", style: { width: "5%" }}, 
        ]}
        renderChildren={(news) => (
          <NewsItem
            key={news.id}
            onDelete={showDeleteModal}
            onEdit={onEdit}
            news={news}
          />
        )}
        items={newsList}
        search={["title", "id"]}
        pagination
      />
      {isDeleteModalShow && selectedOperationId ? (
        <Confirm title={"Delete Operation"} onConfirm={confirmDeleteModal}>
          <p>Are you sure you want to delete the operation?</p>
        </Confirm>
      ) : null}
    </>
  );
};

export default NewsTable;
