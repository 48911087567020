import React, { useState, useEffect } from "react";

import { useHistory } from "react-router-dom";

import useUser from "../../hooks/useUser";
import { fireEvent } from "../../hooks/usePubsub";
import { getCourse, uploadCourseCertificate } from "../../api/courses";
import CertificateUpload from "../../components/CertificateUpload";
import Button from "../../components/Button";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const UploadCertificate = ({ match }) => {
  const history = useHistory();
  const [course, setCourse] = useState(null);
  const { certificateId, crewId } = match.params;
  const user = useUser();
  const userId = match.params?.userId || user.id;
  const [openWarning, setOpenWarning] = React.useState(false);
  const [warningText, setWarningText] = React.useState("");
  const [warningTitle, setWarningTitle] = React.useState("");


  const warningClose = () => {
    setOpenWarning(false);
    history.goBack();
    fireEvent("pending-changed");
  };


  let isCustom = false;
  if (certificateId.startsWith(`cc_`)) {
    isCustom = true;
  }

  const courseId = isCustom ? certificateId.replace(`cc_`, "") : certificateId;

  const submit = async (fields) => {
    const result = await uploadCourseCertificate({
      userId,
      custom: isCustom,
      courseId: certificateId,
      files: fields.certificateFile,
      completed: fields.completionDate,
      validUntil: fields.validPeriod,
      comment: fields.comment,
      crewId: crewId || null,
    });
    if (result.status === "ok") {
      setOpenWarning(true);
      setWarningTitle("Certificate is under evaluation");
      setWarningText("Once it is confirmed by the authorized person, the position will turn green. Please note that this may take a few days. Click close to continue"); 

      return null;
    } else {
      return result?.reason;
    }
  };

  useEffect(() => {
    (async () => {
      const json = await getCourse(courseId, isCustom);
      setCourse(json);
    })();
  }, [courseId, isCustom]);

  return (
    <>
      {isCustom ? <h2>Custom Course</h2> : <h2>Course</h2>}
      {course ? (
        <CertificateUpload
          course={course}
          onSubmit={submit}
          isCustom={isCustom}
        />
      ) : null}
    <Dialog
          open={openWarning}
          onClose={warningClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {warningTitle}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
            {warningText}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={warningClose} autoFocus>Close</Button>
          </DialogActions>
    </Dialog>
    </>

  );
};

export default UploadCertificate;
