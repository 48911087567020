import React from "react";

import Link from "@mui/material/Link";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";

import ThumbsUp from "@mui/icons-material/ThumbUp";
import ThumbDown from "@mui/icons-material/ThumbDown";

import Button from "../../components/Button";
import { openDownloadWindow } from "../../api";
import TableComponent from "../TableComponent";
import { formatDate } from "../../services/formatters";

const ApproveIcon = styled(ThumbsUp)(({ theme }) => ({
  color: theme.palette.success.main,
}));

const DeclineButton = styled(ThumbDown)(({ theme }) => ({
  color: theme.palette.error.main,
}));

const PendingCertificateList = ({ pending, resolve }) => (
  <TableComponent
    pagination
    search={["name", "userName", "userEmail", "userId", "courseId", "comments"]}
    items={pending}
    header={[
      { title: "Member Fullname / Email", style: { width: "25%" } },
      { title: "Course Name", style: { width: "25%" } },
      { title: "Completed Date", style: { width: "8%" } },
      { title: "Validity period", style: { width: "8%" } },
      { title: "Comments", style: { width: "15%" } },
      { title: "Certificate File", style: { width: "10%" } },
      { title: "Action", style: { width: "9%" } },
    ]}
    renderChildren={(item) => (
      <TableRow>
        <TableCell>
          ({item.userId}) {item.userName} / {item.userEmail}
        </TableCell>
        <TableCell>
          ({item.isCustom ? `cc_${item.courseId}` : `${item.courseId}`}){" "}
          {item.name}
        </TableCell>
        <TableCell>{formatDate(item.completedDate)}</TableCell>
        <TableCell>
          {item.validPeriod ? formatDate(item.validPeriod) : ""}
        </TableCell>
        <TableCell>{item.comments || ""}</TableCell>
        <TableCell>
          {item.fileName ? (
            <Link button onClick={() => openDownloadWindow(item, item.userId)}>
              {item.fileName}
            </Link>
          ) : null}
        </TableCell>
        <TableCell>
          <Button
            title="Approve Certificate"
            icon={<ApproveIcon />}
            onClick={() => resolve(item, "approve")}
          />
          <Button
            title="Decline Certificate"
            icon={<DeclineButton />}
            onClick={() => resolve(item, "decline")}
          />
        </TableCell>
      </TableRow>
    )}
  />
);

export default PendingCertificateList;
