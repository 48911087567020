import React, { useState, useEffect } from "react";
import { editUser, getMyInfo, getUserInfo } from "../../api/user";
import EditForm from "../../components/EditForm";
import EditMemberForm from "../../components/EditForm/member";

const UserEdit = ({ match, history }) => {
  const { userId } = match.params;
  const [user, setUser] = useState(null);

  useEffect(() => {
    (async () => {
      if (userId) {
        const userData = await getUserInfo(userId);
        setUser(userData);
      }
    })();
  }, [userId]);

  useEffect(() => {
    (async () => {
      if (!userId) {
        const userData = await getMyInfo();
        setUser(userData);
      }
    })();
  }, [userId]);

  async function submit(userData) {
    const newUserData = {
      city: userData.city,
      country: userData.country?.code,
      fcl: userData.fcl,
      fullname: userData.fullname,
      language: userData.language?.value,
      email: userData.email,
      birthday: userData.birthday?.isValid()
        ? userData.birthday.format("YYYY-MM-DD")
        : undefined,
    };
    const response = await editUser(user.id, newUserData);
    if (response.ok && userId) {
      history.goBack();
    }
  }

  return (
    <>
      {user ? (
        userId ? (
          <EditMemberForm user={user} onSubmit={submit} />
        ) : (
          <EditForm user={user} onSubmit={submit} />
        )
      ) : null}
    </>
  );
};

export default UserEdit;
