import React from "react";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";

import useCrew from "../../hooks/useCrew";

const TrainerOverview = () => {
  const { t } = useTranslation();
  const { crew } = useCrew();

  return <Typography variant="h2">{t('welcome_message')} NGFT TrainerOverview</Typography>;
};

export default TrainerOverview;
