import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Button from "../../components/Button";
import TableComponent from "../../components/TableComponent";
import { getUsersInscribed, updateCheckingProgress, updateModuleStatus, updateStatusCertificate} from "../../api/training";
import {  StyledSwitch } from "../../components/Base";
import useUser from "../../hooks/useUser";
import useCrew from "../../hooks/useCrew";


const TrainerCheckingProgress = ({ match, history }) => {
  const [usersInscribed, setUsersInscribed] = useState([]);
  const [trainingComplete, setTrainingComplete] = useState(0);
  const [refresh, setRefresh] = useState(1);
  const trainingId = match.params.trainingId;  
  const classroomAttendance = match.params.classroomAttendance;
  let classroomTest = match.params.classroomTest;
  const classroom = match.params.classroom;
  const evaluator = useUser();
  const crew = useCrew();


  useEffect(() => {
    (async () => {
      const usersInscribed = await getUsersInscribed(trainingId, crew.crew.id); 
      setUsersInscribed(usersInscribed)
    })();
  }, [refresh]);


  const onEventSwitch = async(id, field, value, userInscribed) => {
    const res = await updateCheckingProgress(id, field,value, evaluator.id);

    let resAfterUpdateModule = null;
    if (classroomAttendance == res.classroom_attendance && classroomTest == res.classroom_test) {
      resAfterUpdateModule = await updateModuleStatus(id, 'classroom_status',1)
    } else{
      resAfterUpdateModule = await updateModuleStatus(id, 'classroom_status',0)
    }

    let validPeriod = null;
    if (userInscribed.Training.Course.ValidPeriod.valid_period){
      validPeriod = userInscribed.Training.Course.ValidPeriod.valid_period;
    }
    const  userId = userInscribed.user_id
    const courseId = userInscribed.Training.Course.id
    //Update Certificate
    if (userInscribed.Training.is_e_learning == resAfterUpdateModule.elearning_status && 
        userInscribed.Training.is_classroom == resAfterUpdateModule.classroom_status &&
        userInscribed.Training.is_practical == resAfterUpdateModule.practical_status) 
    {
      await updateStatusCertificate(userId, courseId , 1, validPeriod)
    } else{
      await updateStatusCertificate(userId, courseId,  0, validPeriod)
    }

    setRefresh(refresh * -1);
  };

  return (
    <>
      <Typography variant="h4">Training checking progress</Typography>
      {usersInscribed? (
        <TableComponent
          header={[ 
            { title: "User ID", style: { width: "5%" } },
            { title: "User"},
            { title: "Classroom Attendance " },
            { title: "Classroom Test" },
          ]}
          renderChildren={(userInscribed,  index) => (
            <TableRow key={index}>
              <TableCell>{userInscribed.user_id}</TableCell>
              <TableCell>{userInscribed.User.fullname}</TableCell>

              <TableCell>
              {classroomAttendance == 1  &&
                <StyledSwitch
                  title="Classroom Attendance"
                  checked={userInscribed.classroom_attendance===1}
                  onChange={() => {
                    const valSwitch = userInscribed.classroom_attendance === 1 ? 0 : 1;
                    onEventSwitch(userInscribed.id, 'classroom_attendance', valSwitch, userInscribed) 
                    }
                  }
                />
              }
              </TableCell>
              <TableCell>
              {classroomTest == 1  &&
                <StyledSwitch
                  title="Training test"
                  checked={userInscribed.classroom_test === 1}
                  onChange={() => {
                    const valSwitch = userInscribed.classroom_test === 1 ? 0 : 1;
                    onEventSwitch(userInscribed.id, 'classroom_test', valSwitch, userInscribed) 
                    }
                  }
                />
              }
              </TableCell>

            </TableRow>
          )}
          search={["user_id","fullname"]}
          pagination
          items={usersInscribed}
        />
      ) : null}
      <Button title="Back" onClick={() => history.goBack()}>
              Back
      </Button>
    </>
  );
};

export default TrainerCheckingProgress;
