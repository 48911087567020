import React from "react";
import { Route } from "react-router-dom";

import CourseBundle from "../../features/admin/course-bundle";
import EditBundle from "../../features/admin/course-bundle/edit";

const Bundles = ({ match }) => (
  <>
    <Route path={`${match.path}/add`} component={EditBundle} />
    <Route path={`${match.path}/edit/:bundleId`} component={EditBundle} />
    <Route exact path={`${match.path}`} component={CourseBundle} />
  </>
);

export default Bundles;
