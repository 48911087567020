import React from "react";

import { Switch, Route } from "react-router";
import TransactionsBankTable from "../../features/transactions-bank-table";

const TransactionsBank = ({ match, history }) => {
  return (
    <>
      <Switch>
        <Route exact path={`${match.path}`} component={TransactionsBankTable} />
      </Switch>
    </>
  );
};

export default TransactionsBank;
