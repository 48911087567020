import React, { useState } from "react";
import { useLocation } from "react-router";

import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Box from "@mui/material/Box";

import Actions from "../Certificate/Actions";

import TableComponent from "../TableComponent";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const CourseList = ({ courses, showDescriptions }) => {
  const only = useQuery().get("only");
  const [filtered, setFiltered] = useState(!!only?.length);
  if (filtered && only?.length) {
    const m = only.split(",").reduce((m, it) => ({ ...m, [+it]: true }), {});
    courses = courses.filter((c) => m[c.id]);
    
  }

  const header = [
    { title: "ID", style: { width: "5%" } },
    { title: "Name", style: { width: "30%" } },
    { title: "Description", style: { width: "35%" } },
    { title: "Type", style: { width: "35%" } },
    { title: "Price", style: { width: "15%" } },
    { title: "Actions", style: { width: "15%" } },
  ];
  if (!showDescriptions) {
    header.splice(2, 1);
    header[1].style.width = "65%";
  }
  return (
    <>
      {filtered && (
        <Box style={{ paddingBottom: 15 }}>
          Currently showing only some courses.{" "}
          <button onClick={() => setFiltered(false)}>Show all</button>
        </Box>
      )}
      <TableComponent
        pagination
        pageSize={25}
        items={courses}
        search={["id","name"]}
        header={header}
        renderChildren={(course) => (
          <TableRow key={course.id}>
            <TableCell>{course.id}</TableCell>
            <TableCell>{course.name}</TableCell>
            {showDescriptions && <TableCell>{course.description}</TableCell>}
            <TableCell>{course.type}</TableCell> 
            <TableCell>{+course.price ? course.price : "Free"}</TableCell>
            <TableCell style={{ width: "5%" }}>
              <Actions certificate={course} />
            </TableCell>
          </TableRow>
        )}
      />
    </>
  );
};

export default CourseList;
