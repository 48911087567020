import React, { useState, useEffect } from "react";

import Typography from "@mui/material/Typography";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Form, InputForm, Validation, DatePicker } from "../../components/Form";
import Autocomplete from "../../components/Form/StyledAutocomplete";
import { BasePaper, Row } from "../../components/Base";
import Button from "../../components/Button";
import { getObservables } from "../../api/observable";
import { StyledSwitch } from "../../components/Base";
import {  getActivitiesTrainingById, createActivitiesTraining, updateActivitiesTraining  } from "../../api/activities-training";





const EditActivitiesTraining = ({ match, history }) => {

  const { activitiesTrainingId } = match.params;
  const isAdd = !activitiesTrainingId;  
  const [activitiesTraining, setActivitiesTraining] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [Observables, setObservables] = useState([]);
  const [selectedObservable, setSelectedObservable] = useState([]);
  const [selectedStatus, setStatus] = useState("active");


  useEffect(() => {
    (async () => {

      const observables = await getObservables();
      setObservables(observables);
      if (activitiesTrainingId) {
        const activitiesTrainingRes = await getActivitiesTrainingById(activitiesTrainingId);
        setActivitiesTraining(activitiesTrainingRes);
        setStatus(activitiesTrainingRes.status);
        setSelectedObservable(activitiesTrainingRes.observable || []);
      }
      setLoaded(true);
    })();
  }, [activitiesTrainingId]);

  const goBack = () => history.goBack();

  const onSubmit = async (values) => {

    values.status =selectedStatus;
    const observablesIds = selectedObservable.map((c) => c.id);

    let result;
    if (isAdd) {
      result = await createActivitiesTraining(values, observablesIds);
    } else {
      result = await updateActivitiesTraining(activitiesTrainingId, values, observablesIds );
    }   
    if (result.ok) {
      history.push("/activities-training");
    } 
  };

  const title = isAdd ? "Add new activities training" : "Edit activities training";

  return (
    <>
      <Typography variant="h4">
        <Button icon={<ArrowBackIcon />} onClick={goBack} />
        {title}
      </Typography>
      {loaded ? (
        <BasePaper>
          <Form
            defaultValues={{
              name: activitiesTraining?.name,
            }}
            validation={{
              name: Validation.required,
            }}
            onSubmit={onSubmit}
          >
            <Row>
              <InputForm name="name" label="activitiesTraining name*" />
            </Row>

            <Row>
              <Autocomplete
                label="Observables List"
                items={Observables}
                selected={selectedObservable}
                multiple
                onChange={(items) => {
                  setSelectedObservable(items);
                }}
                formatLabel={(item) => `(${item.id}) ${item.name}`}
              />
            </Row>
            <Row>
                Status <StyledSwitch
                  title="Change status"
                  checked={selectedStatus === "active"}
                  onChange={() =>
                    setStatus(selectedStatus=== "active" ? "passive" : "active")
                  }
                /> 
            </Row>

            <Row>
              <Button type="submit">Save</Button>
              <Button onClick={goBack}>Cancel</Button>
            </Row>
          </Form>
        </BasePaper>
      ) : null}
    </>
  );
};

export default EditActivitiesTraining;
