import React from "react";

import WorkIcon from "@mui/icons-material/Work";

import CollapsibleListItem from "../List/CollapsibleList";
import CoursesList from "./CoursesList";
import AircraftTreeItem from "./AircraftTreeItem";
import OperationTreeItem from "./OperationTreeItem";
import { useCourseRelationsContext } from "../../context/CourseRelationsContext";

const PositionTreeItem = ({ nodeId, position }) => {
  const actions = useCourseRelationsContext();
  const key = `${nodeId}/p${position.id}`;
  const { courses, customCourses, groups, operations, aircraft } = position;
  const hasItems =
    courses?.length ||
    customCourses?.length ||
    groups?.length ||
    operations?.length ||
    aircraft?.length;

  return (
    <CollapsibleListItem
      nodeId={key}
      icon={<WorkIcon />}
      isExpandable={hasItems}
      title={position.name}
      onClick={() => actions.nodeClicked(key)}
    >
      <CoursesList
        nodeId={key}
        courses={courses}
        customCourses={customCourses}
        groups={groups}
      />

      {aircraft?.length
        ? aircraft.map((aircraft) => (
            <AircraftTreeItem key={`${key}/a${aircraft.id}`} aircraft={aircraft} nodeId={key} />
          ))
        : null}

      {operations?.length
        ? operations.map((operation) => (
            <OperationTreeItem key={`${key}/o${operation.id}`} operation={operation} nodeId={key} />
          ))
        : null}
    </CollapsibleListItem>
  );
};

export default PositionTreeItem;
