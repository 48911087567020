import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import UserTitle from "../../components/UserTitle";
import { getUserInfo } from "../../api/user";
import { StyledTabs } from "../../components/Base";

const UserEditNavigation = ({ match, history }) => {
  const { userId, pageId } = match.params;

  const [user, setUser] = useState();
  useEffect(() => {
    (async () => {
      const userInfo = await getUserInfo(userId);
      setUser(userInfo);
    })();
  }, [userId]);
  const navigateTo = (_, value) => {
    const subpage = v(value);
    history.push(`/users/${userId}/${subpage}`);
  };

  const pages = ["edit", "roles"];
  const titles = ["Edit", "Roles And Companies"];

  const rv = (subpage) => pages.indexOf(subpage);
  const v = (i) => pages[i];
  return (
    <>
      {user ? (
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h4">{titles[rv(pageId)]}</Typography>
          <UserTitle id={`${user.id}`} name={user.name} email={user.email} />
        </Box>
      ) : null}
      <StyledTabs
        variant="fullWidth"
        value={rv(pageId)}
        indicatorColor="primary"
        onChange={navigateTo}
        style={{ marginBottom: "1rem" }}
      >
        <Tab label="Edit" />
        <Tab label="Roles" />
      </StyledTabs>
    </>
  );
};

export default UserEditNavigation;
