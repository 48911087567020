import React, { useState, useEffect } from "react";


import TableComponent from "../../components/TableComponent";
import useCrew from "../../hooks/useCrew";

import OrderItem from "./OrderItem";
import UserSession from "../../services/session";

import {
  getOrderCrew,
} from "../../api/orders";
import { ControlCameraOutlined } from "@mui/icons-material";

const OrdersTable = ({ match, history }) => {
  const [orders, setOrders] = useState([]);
  const [refresh, setRefresh] = useState(1);
  const { crew, setCrew } = useCrew();
  


  useEffect(() => {
    (async () => {
      const crewdId =crew.id;
      let newOrders = await getOrderCrew(crewdId);
      setOrders(newOrders);
    })();
  }, [refresh]);

  return (
    <>
      <h2>Orders</h2>
      <TableComponent
        header={[
          { title: "ID", sort: "id", style: { width: "5%" } },
          { title: "Buyer", sort: "buyer", style: { width: "10%" } },
          { title: "Date", sort: "date", style: { width: "10%" } },
          { title: "Items", sort: "items" },
          { title: "Paid with", sort: "payment_type" },
          { title: "Total",  style: { width: "5%" } },
          { title: "Status", sort: "status", style: { width: "10%" } },
          { title: "Invoice", style: { width: "15%" }}, 
        ]}
        renderChildren={(order) => (
          <OrderItem
            key={`order-${order.order_id}`}
            order={order}
          />
        )}
        items={orders}
        search={[ "order_id", "buyer", "status"]}
        pagination
      />
    </>
  );
};

export default OrdersTable;
