import { useLocalStorage } from "@rehooks/local-storage";
import { useState } from "react";
import { useRouteMatch } from "react-router";

import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { Theme } from "@mui/material/styles";

import SearchField from "./SearchField";
import { StyledSwitch } from "../Base";

const SORT_DIRECTION = {
  ASC: -1,
  DESC: 1,
};

const StyledTableHead = styled(TableHead)(({ theme }: { theme: Theme }) => ({
  backgroundColor: theme.palette.background.default,
}));

interface TableComponentProps {
  TopComponent?: React.ReactElement;
  renderChildren: (item: any) => React.ReactElement;
  header?: { title: string; sort?: string; style?: object }[];
  pagination?: boolean;
  search?: string[];
  filter?: { filterBy: string; controlLabel: string };
  pageSize?: number;
  items: Record<string, any>[];
  users?: boolean;
  WrapperComponent?: React.ComponentType;
  tableId?: string;
}

const TableComponent: React.FC<TableComponentProps> = ({
  TopComponent,
  renderChildren,
  header,
  pagination = false,
  search = null,
  filter = null,
  pageSize: defaultPageSize = 10,
  items,
  users,
  WrapperComponent = null,
  tableId = "",
}) => {
  const storageKey =
    "table." + useRouteMatch().path + (tableId ? "." + tableId : "");
  const [prefs, setPrefs] = useLocalStorage(storageKey, {
    pageSize: defaultPageSize,
    filter: filter ? true : false,
  });
  const rowsPerPage = pagination ? prefs.pageSize : items.length;
  const filterOn = prefs.filter ?? true;

  const [page, setPage] = useState(0);
  const [searchTerm, setSearch] = useState("");
  const [sort, setSort] = useState<string>("");
  const [sortDirection, setSortDirection] = useState(SORT_DIRECTION.ASC);

  const setRowsPerPage = (pageSize: number) => {
    setPrefs({ ...prefs, pageSize });
  };

  const sortBy = (key: string) => {
    if (sort === key) {
      setSortDirection(-sortDirection);
    } else {
      setSort(key);
    }
  };

  const sortFn = (a: any, b: any) => {
    const v1 = a[sort];
    const v2 = b[sort];
    const p1 = +v1 === v1 ? v1 : `${v1}`.toLowerCase();
    const p2 = +v2 === v2 ? v2 : `${v2}`.toLowerCase();
    return p1 > p2 ? sortDirection : -sortDirection;
  };

  const doSort = (items: any[]) => (sort ? items.sort(sortFn) : items);

  const doFilter = (items: any[]) => {
    if (filter && filterOn) {
      return items.filter((item) => item[filter.filterBy]);
    }
    return items;
  };

  const doSearchWithExactMatchPriority = (items: any[]) => {
    if (search && searchTerm) {
      const cleanedSearchTerm = searchTerm.trim().toLowerCase();
      // the Boolean function is a way no how to filter out empty strings
      const searchWords = cleanedSearchTerm.split(" ").filter(Boolean);
      const searchTermResults = items.filter((item) =>
        search.some((key) =>
          String(item[key]).toLowerCase().includes(cleanedSearchTerm),
        ),
      );

      if (searchWords.length < 2) {
        return searchTermResults;
      }

      const searchWordsResults = items.filter((item) =>
        search.some((key) =>
          searchWords.every((word) =>
            String(item[key]).toLowerCase().includes(word),
          ),
        ),
      );

      return [
        ...searchTermResults,
        ...searchWordsResults.filter((x) => !searchTermResults.includes(x)),
      ];
    }
    return items;
  };

  const paginate = (items: any[]) =>
    pagination
      ? items.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      : items;

  const filteredItems = doSort(doSearchWithExactMatchPriority(doFilter(items)));
  const drawItems = paginate(filteredItems);

  return (
    <>
      <TableContainer component={WrapperComponent || Paper} variant="outlined">
        {search || filter || TopComponent ? (
          <>
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              height={"3rem"}
              alignItems={"center"}
              p={"1rem 0.5rem"}
              className="input-wrapper"
            >
              {users ? <h3 className="d-none user-title">All User</h3> : null}
              {search ? (
                <SearchField
                  value={searchTerm}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setSearch(e.target.value);
                    setPage(0);
                  }}
                />
              ) : null}
              {filter ? (
                // TODO: figure out how the styling works and remove next line
                <span style={{ minWidth: "240px" }}>
                  <StyledSwitch
                    title={filter.controlLabel}
                    checked={!filterOn}
                    onChange={() => {
                      setPrefs({ ...prefs, ...{ filter: !filterOn } });
                      setPage(0);
                    }}
                  />
                  {filter.controlLabel}
                </span>
              ) : null}
              {TopComponent || null}
            </Box>
            <Divider />
          </>
        ) : null}
        <Table size="small" className="user-table">
          {header && header?.length > 0 ? (
            <StyledTableHead>
              <TableRow className={users ? "custom-row" : undefined}>
                {header.map((item) => (
                  <TableCell key={item.title} style={item.style}>
                    {item.sort ? (
                      <TableSortLabel
                        direction={sortDirection > 0 ? "asc" : "desc"}
                        onClick={() => sortBy(item.sort!)}
                      >
                        {" "}
                        {item.title}{" "}
                      </TableSortLabel>
                    ) : (
                      item.title
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </StyledTableHead>
          ) : null}
          <TableBody>{drawItems.map(renderChildren)}</TableBody>
        </Table>
      </TableContainer>
      {pagination ? (
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={filteredItems.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(_, page) => setPage(page)}
          onRowsPerPageChange={(e) => {
            setRowsPerPage(+e?.target?.value || 10);
            setPage(0);
          }}
        />
      ) : null}
    </>
  );
};

export default TableComponent;
