import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import PostAddIcon from "@mui/icons-material/PostAdd";
import Button from "../../components/Button";
import TableComponent from "../../components/TableComponent";
import { getObservables, deleteObservableById } from "../../api/observable";
import Confirm from "../../components/Confirm";

const Observable = ({ match, history }) => {
  const [observable, setObservable] = useState(null);

  const [selectedOperationId, setSelectedOperationId] = useState(null);
  const [isDeleteModalShow, setIsDeleteModalShow] = useState(false);
  const [refresh, setRefresh] = useState(1);

  function showDeleteModal(id) {
    setSelectedOperationId(id);
    setIsDeleteModalShow(true);
  }


  async function confirmDeleteModal(isConfirmed) {
    if (isConfirmed) {
      await deleteObservableById(selectedOperationId);
    }
    setIsDeleteModalShow(false);
    setRefresh(refresh * -1);
  }

  useEffect(() => {
    (async () => {
      const res = await getObservables();
      setObservable(res);
    })();
  }, [refresh]);

  return (
    <>
      <Typography variant="h4">Observable</Typography>
      {observable? (
        <TableComponent
          TopComponent={
            <Button size="small" onClick={() => history.push(`/observable/add`)}>
              <PostAddIcon />
              Add New observable
            </Button>
          }
          search={["name"]}
          header={[
            { title: "ID", style: { width: "5%" } },
            { title: "Name" },
            { title: "Test Type" },
            { title: "Area" },
            { title: "Status"},
            { title: "Actions" },
          ]}
          items={observable}
          renderChildren={(observable) => (
            <TableRow key={observable.id}>
              <TableCell>{observable.id}</TableCell>
              <TableCell>{observable.name}</TableCell>
              <TableCell>{observable.test_type}</TableCell>
              <TableCell>{observable.area}</TableCell>
              <TableCell>{observable.status}</TableCell>

              <TableCell>
                <Button
                  icon={<EditIcon />}
                  onClick={() => history.push(`/observable/edit/${observable.id}`)}
                  title="Edit observable"
                />
                <Button
                  icon={<DeleteForeverIcon />}
                  onClick={() => showDeleteModal(observable.id)}
                  title="Delete observable"
                />
              </TableCell>
            </TableRow>
          )}
        />
      ) : null}
      
      {isDeleteModalShow && selectedOperationId ? (
        <Confirm title={"Delete Operation"} onConfirm={confirmDeleteModal}>
          <p>Are you sure you want to delete the operation?</p>
        </Confirm>
      ) : null}

    </>
  );
};

export default Observable;
