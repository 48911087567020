import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Button from "../../components/Button";
import "./index.scss";
import {  addNews } from "../../api/news";
import { getNgftApp, } from "../../api/ngftApp.js";

import {
  Form,
  Row,
  InputForm,
  Validation,
  Textarea,
} from "../../components/Form";
import Autocomplete from "../../components/Form/Autocomplete.deprecated";

const validation = {
  title: Validation.required,
  // source: Validation.required,
  description: Validation.required,
  link: Validation.required,
};



const NewsAdd = ({ history }) => {
  const [source, setsource] = useState([]);
  const [news, setNews] = useState(null);
  const [selectedSources, setSelectedSources] = useState([]);

  useEffect(() => { 
    (async () => {
      let newSources = await getNgftApp();
      setsource(newSources);
      setSelectedSources(newSources);
    })();
  }, []);


  async function addNewsForm(data) {
    const sources = selectedSources.map((c) => c.id);

    const result = await addNews(
      // TODO: fix validation and remove first item from sources as default
        data.source?.id || sources[0],
        data.title,
        data.description,
        data.link,
      );
    if (result.ok) {
      history.push("/news");
    }
  
  }

  return (
    <>
      <h2>Add News</h2>
      <Paper
        variant="outlined"
        style={{ padding: "1rem 1.5rem" }}
      >
        <Form            
            defaultValues={{
              source: news?.source,
              title: news?.title,
              description: news?.description,
              link: news?.link
            }}
             validation={validation}
             onSubmit={addNewsForm}  
             >

          <Row>
              <Autocomplete
                name="source"
                label="Source app"
                items={source}
                multiple
                onChange={(items) => {
                  setSelectedSources(items);
                }}
                selected={selectedSources}
                formatLabel={(item) => item.name}
              />
            </Row>
          <Row>
            <InputForm label="Title*" name="title" />
          </Row>  
          <Row>
            <Textarea
              label="Description*"
              name="description"
            />
          </Row>
          <Row>
            <InputForm label="Link*" name="link" />
          </Row>


          <Button type="submit" title="Add neww">Add News</Button>
          <Button
            title="Go back"
            type="button"
            onClick={() => history.goBack()}
          >
            Cancel
          </Button>
        </Form>
      </Paper>
    </>
  );
};

export default NewsAdd;
