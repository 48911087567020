import React from "react";

import Paper from "@mui/material/Paper";
import List from "@mui/material/List";

import RoleTreeItem from "./RoleTreeItem";

const RolesTree = ({ roles, onClick, onRemove }) => (
  <Paper variant="outlined" elevation={0}>
    <List dense>
      {roles.map((role) => (
        <RoleTreeItem
          key={role.id}
          role={role}
          onClick={() => onClick(role)}
          nodeId={""}
          onRemove={(permission) => onRemove(role.id, permission.id)}
        />
      ))}
    </List>
  </Paper>
);

export default RolesTree;
