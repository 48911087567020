import request from "./request";

export const getCategories = async () => {
  const json = await request(`/categories`);

  return json;
};

export const getCategoryById = async (categoryId) => {
  const json = await request(`./categories/${categoryId}`);

  return json;
};

export const addCategory = async (categoryName) => {
  const response = await request(`/categories`, "POST", {
    name: categoryName,
  });

  return response;
};

export const updateCategory = async (categoryId, newCategory) => {
  const response = await request(
    `/categories/${categoryId}`,
    "PUT",
    newCategory
  );

  return response;
};

export const deleteCategoryById = async (categoryId) => {
  const json = await request(`./categories/${categoryId}`, "DELETE");

  return json;
};
