import React from "react";
import { Route } from "react-router-dom";


import UsersTrainingList from "./users-training-list";
import AssessmentTraining from "./assessment";
import ProgressTraining from "./progress";
import DetailsTraining from "./details";
import WrittenTestTraining from "./written-test";
import WrittenTestTrainingInfo from "./written-test-info";
import InscribeUser from "./inscribe-user";







const TrainingUsers = ({ match }) => (
  <>

    <Route path={`${match.path}/assessment/:trainingUserId/:positionId`} component={AssessmentTraining} />
    <Route path={`${match.path}/progress/:trainingId/:userId/:courseId`} component={ProgressTraining} />
    <Route path={`${match.path}/details/:trainingId/:userId/:courseId`} component={DetailsTraining} />
    <Route path={`${match.path}/written-test/:trainingId/:userId`} component={WrittenTestTraining} />
    <Route path={`${match.path}/written-test-info/:trainingId/:userId`} component={WrittenTestTrainingInfo} />
    <Route path={`${match.path}/inscribe/:trainingId/:courseId`} component={InscribeUser} />
    
    <Route exact path={`${match.path}/:trainingId`} component={UsersTrainingList} />
  </>
);

export default TrainingUsers;
