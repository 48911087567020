import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';

import Typography from "@mui/material/Typography";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Button from "../../components/Button";
import TableComponent from "../../components/TableComponent";
import useCrew from "../../hooks/useCrew";
import { getCrewUsersTrainings,   } from "../../api/training";
import {  getMoodleCourseById  } from "../../api/courses";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import { useShoppingCart } from "../../context/ShoppingCartContext";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import GetAppIcon from "@mui/icons-material/GetApp";
import {openExportWindow } from "../../api/";

const InscribeUser = ({ match, history }) => {
  const [usersToInscribe, setUsersToInscribe] = useState([]);
  const [course, setCourse] = useState(null);
  const [refresh, setRefresh] = useState(1);
  const { crew } = useCrew();
  const shoppingCart = useShoppingCart();
  const trainingId = match.params.trainingId;  
  const courseId  = match.params.courseId

  useEffect(() => {
    (async () => {
      const nextCourse = await getMoodleCourseById(courseId);
      setCourse(nextCourse);
      const newRes = await getCrewUsersTrainings(crew.id); 
      setUsersToInscribe(newRes);
    })();
  }, [refresh]);  


  return (
    <>    
      <Typography variant="h4">List Training users</Typography>
      {usersToInscribe? (
        <TableComponent
          header={[
            { title: "User ID", style: { width: "5%" } },
            { title: "User"},
            { title: "Course"},
            { title: "Price"},
            { title: "Actions" },
          ]}
          renderChildren={(userToInscribe) => (
            <TableRow key={userToInscribe.unique_key}>
              <TableCell>{userToInscribe.userId}</TableCell>
              <TableCell>{userToInscribe.fullname}</TableCell>
              <TableCell>{course.name}</TableCell>
              <TableCell>{course.price}</TableCell>
              <TableCell>
                {userToInscribe.bought ? (
                <>
                    <Button
                      title={`Training bought: (${course.id})`}
                      icon={<AssignmentTurnedInIcon />}
                      onClick={() => {}}
                    />
                    <Link to={`/training-users/user/details/${trainingId}/${userToInscribe.id}/${courseId}`}>
                        <Button
                          title={`Checking progress: (${course.id})`}
                          icon={<PublishedWithChangesIcon />}
                        />
                    </Link>
                        {userToInscribe.hasCertificate ? ( 
                          <>
                            <Button
                              title="Download Certificate"
                              icon={<GetAppIcon />}
                              onClick={() => openExportWindow(userToInscribe.trainingCourseId, userToInscribe.userId)}
                            />
                          </>
                        ) : null}
                </>
                ) : 
                  <Button
                    title={`Add to cart: (${course.id}) ${course.name}, ${course.price}`}
                    icon={<AddShoppingCartIcon />}
                    onClick={() => shoppingCart.actions.add(course, userToInscribe.userId)}
                  />
                }   
              </TableCell>
            </TableRow>
          )}
          search={["userId","fullname"]}
          pagination
          items={usersToInscribe}git checkou
        />
      ) : null}
      
      <Button
            title="Go back"
            type="button"
            onClick={() => history.goBack()}
          >
            Return to crew Trainings
      </Button>
    </>
  );
};

export default InscribeUser;
