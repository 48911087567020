import React, { useEffect, useState } from "react";
import { useRouteMatch } from "react-router";

import CourseList from "../../components/CourseList";
import BundleList from "../../components/BundleList";

import CrewCoursesService from "./service";
import { getAvailableProducts } from "../../api/payment";

export const BundleCourses = ({ crewId }) => {
  const [bundles, setBundles] = useState(null);

  useEffect(() => {
    (async () => {
      const available = await getAvailableProducts(crewId);
      const bundlesData = CrewCoursesService.formatBundles(available.bundles);
      setBundles(bundlesData);
    })();
  }, []);

  return bundles ? <BundleList courses={bundles} /> : null;
};

const CrewCourses = ({ certificates, crewId }) => {
  const match = useRouteMatch();
  const [products, setCrewProducts] = useState(null);

  const userId = match?.params?.userId;

  useEffect(() => {
    (async () => {
      const available = await getAvailableProducts(crewId, userId);

      const productsData = CrewCoursesService.formatProducts(
        available.courses,
        certificates.certificates
      );
      setCrewProducts(productsData);
    })();
  }, []);

  return products ? <CourseList courses={products} /> : null;
};

export default CrewCourses;
