import React, { useState, useEffect } from "react";
import moment from "moment";

import Box from "@mui/material/Box";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import GetAppIcon from "@mui/icons-material/GetApp";

import UserTitle from "../../components/UserTitle";
import TableComponent from "../../components/TableComponent";
import Button from "../../components/Button";
import { StyledTabs } from "../../components/Base";
import CourseStatusIcon from "../../components/CourseStatusIcon";

import { ExportActions } from "../../components/Certificate/Actions";

import { getUserCertificates, getShortUserInfo } from "../../api/user";
import useUser from "../../hooks/useUser";

import { formatDate } from "../../services/formatters";
import { COURSE_STATUS, APPROVE_STATUS } from "../../constants";
import { openAllDownloadWindow } from "../../api";

const CertificateStatusIcon = ({ status }) => {
  if (!status) return null;

  let tooltipText = "";
  if (status === APPROVE_STATUS.BUFFER) {
    tooltipText = "Awaiting approval from administrator";
  }
  if (status === APPROVE_STATUS.EXPIRED) {
    tooltipText = "Certificate was rejected by administrator";
  }

  return (
    <Button
      disabled={status === "approve"}
      title={tooltipText}
      icon={<CourseStatusIcon status={status} constants={APPROVE_STATUS} />}
    />
  );
};

const byCompletedDate = (a, b) =>
  moment(b.completedDate).utc().diff(moment(a.completedDate).utc());

const AllUserCertificates = ({ match, history }) => {
  const user = useUser();
  const { userId } = match.params;
  const uid = userId ? userId : user.id;

  const [selectedTab, setSelectedTab] = useState(0);
  const [userCertificates, setUserCertificates] = useState(null);
  const [userCustomCertificates, setUserCustomCertificates] = useState(null);
  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    (async () => {
      if (uid) {
        const userInfo = await getShortUserInfo(uid);
        const { certificates, customCertificates } = await getUserCertificates(
          uid,
          null,
          true
        );
        setUserCertificates(certificates.sort(byCompletedDate));
        setUserCustomCertificates(
          customCertificates.sort(byCompletedDate).map((c) => ({
            ...c,
            isCustom: true,
          }))
        );

        setUserInfo(userInfo);
      }
    })();
  }, [uid]);

  return (
    <>
      <Box display={"flex"} justifyContent="space-between">
        <Typography variant="h4">
          <Button
            title="Go back"
            onClick={() => history.goBack()}
            icon={<NavigateBeforeIcon />}
          />
          All User Certificates
        </Typography>
        <Box>
          {userInfo ? (
            <UserTitle
              id={userInfo.id}
              name={userInfo.fullname}
              email={userInfo.email}
            />
          ) : null}
          {userCertificates?.filter(
            (c) => !!c.certificateFile || c.type === "moodle"
          ).length ||
          userCustomCertificates?.filter((c) => !!c.certificateFile).length ? (
            <Box m="1.25rem 0">
              <Button
                title="Download All Certificates"
                onClick={() => openAllDownloadWindow(uid)}
              >
                Download All Certificates <GetAppIcon />
              </Button>
            </Box>
          ) : null}
        </Box>
      </Box>
      <Box mb={2}>
        <StyledTabs
          variant="fullWidth"
          value={selectedTab}
          indicatorColor="primary"
          onChange={(_, value) => setSelectedTab(value)}
        >
          <Tab label="All Moodle Courses" />
          <Tab label="All Custom Courses" />
        </StyledTabs>
      </Box>
      {selectedTab === 0 && userCertificates?.length ? (
        <TableComponent  
          header={[
            { title: "Course ID", style: { width: "5%" }, sort: "id" },
            {
              title: "Course Full Name",
              style: { width: "15%" },
              sort: "name",
            },
            {
              title: "Completed Date",
              style: { width: "15%" },
              sort: "completedDate",
            },
            {
              title: "Valid Until",
              style: { width: "15%" },
              sort: "validPeriod",
            },
            { title: "Company", style: { width: "15%" } },
            { title: "Actions", style: { width: "10%" } },
          ]}
          pagination
          items={userCertificates}
          search={["name", "id"]}
          renderChildren={(certificate) => (
            <TableRow key={certificate.id}>
              <TableCell>{certificate.id}</TableCell>
              <TableCell>{certificate.name}</TableCell>
              <TableCell>
                {certificate.completedDate
                  ? formatDate(certificate.completedDate)
                  : "-"}{" "}
              </TableCell>
              <TableCell>
                {certificate.validPeriod
                  ? formatDate(certificate.validPeriod)
                  : COURSE_STATUS.NEVER_EXPIRE}
              </TableCell>
              <TableCell>
                {certificate.crewName ? (
                  <>
                    <CertificateStatusIcon
                      status={certificate?.approveStatus}
                    />
                    {certificate.crewName}
                  </>
                ) : null}
              </TableCell>
              <TableCell>
                <ExportActions userId={uid} certificate={certificate} />
              </TableCell>
            </TableRow>
          )}
        />
      ) : null}
      {selectedTab === 1 && userCustomCertificates?.length ? (
        <>
          <TableComponent
            header={[
              { title: "Course ID", style: { width: "5%" }, sort: "id" },
              {
                title: "Course Full Name",
                style: { width: "20%" },
                sort: "name",
              },
              {
                title: "Completed Date",
                style: { width: "15%" },
                sort: "completedDate",
              },
              {
                title: "Valid Until",
                style: { width: "20%" },
                sort: "validPeriod",
              },
              { title: "Company", style: { width: "15%" }, sort: "crewName" },
              { title: "Actions", style: { width: "10%" } },
            ]}
            pagination
            search={["name", "id"]}
            items={userCustomCertificates}
            renderChildren={(certificate) => (
              <TableRow>
                <TableCell>{certificate.id}</TableCell>
                <TableCell>{certificate.name}</TableCell>
                <TableCell>
                  {certificate.completedDate
                    ? formatDate(certificate.completedDate)
                    : "-"}{" "}
                </TableCell>
                <TableCell>
                  {certificate.validPeriod
                    ? formatDate(certificate.validPeriod)
                    : COURSE_STATUS.NEVER_EXPIRE}
                </TableCell>
                <TableCell>
                  {certificate.crewName ? (
                    <>
                      <CertificateStatusIcon
                        status={certificate?.approveStatus}
                      />
                      {certificate.crewName}
                    </>
                  ) : null}
                </TableCell>
                <TableCell>
                  <ExportActions userId={uid} certificate={certificate} />
                </TableCell>
              </TableRow>
            )}
          />
        </>
      ) : null}
    </>
  );
};

export default AllUserCertificates;
