const AircraftSpecializationService = {
  getCrewAircraft({ aircraft, specializations, relations }) {
    return relations.map((relation) => {
      const a = aircraft.find((a) => a.id === relation.id);
      const specs = [];
      for (const sid of relation?.specializations || []) {
        const s = specializations.find((s) => s.id === sid);
        if (s) {
          specs.push({
            id: s.id,
            name: s.name,
          });
        }
      }
      return {
        id: a.id,
        name: a.name,
        specializations: specs,
      };
    });
  },
};

export default AircraftSpecializationService;
