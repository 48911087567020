import request from "./request";

export const getGroupsCourses = async () => {
  const json = await request(`/groups`);
  return json;
};

export const getAvailableCourses = async () => {
  const json = await request(`/groups/courses/available`);
  return json;
};

export const addGroupCourse = async (groupName, selectedCourses) => {
  const response = await request(`/groups`, "POST", {
    groupName,
    selectedCourses,
  });
  return response;
};

export const getCoursesGroupById = async (groupCourseId) => {
  const json = await request(`/groups/${groupCourseId}`);

  return json;
};

export const updateGroupCourse = async (id, newGroupCourse) => {
  const response = await request(`/groups/${id}`, "PUT", newGroupCourse);

  return response;
};

export const deleteCoursesGroupById = async (groupCourseId) => {
  const json = await request(`/groups/${groupCourseId}`, "DELETE");

  return json;
};
