import React from "react";

import { Switch, Route } from "react-router";
import NewsAdd from "../../features/news-add";
import NewsTable from "../../features/news-table";
import NewsEdit from "../../features/news-edit";

const News = ({ match, history }) => {
  return (
    <>
      <Switch>
        <Route exact path={`${match.path}`} component={NewsTable} />
        <Route
          exact
          path={`${match.path}/add_news`}
          component={NewsAdd}
        />
        <Route
          exact
          path={`${match.path}/edit/:newsId`}
          component={NewsEdit}
        />
      </Switch>
    </>
  );
};

export default News;
