const RolesService = {
  map({ roles, permissions, relations }, crewId) {
    const isSuperAdmin = !crewId;
    const tree = [];

    for (const role of roles) {
      const relation = relations.find((rel) => rel.id === role.id);

      const rolePermissions = [];
      if (relation) {
        for (const pid of relation.permissions) {
          const permission = permissions.find((p) => p.id === pid);
          if (permission) {
            rolePermissions.push({
              id: permission.id,
              name: permission.name,
              removable: isSuperAdmin || !!relation.crewId,
              crewId: relation.crewId,
            });
          }
        }
      }

      tree.push({
        id: role.id,
        name: role.name,
        crewId: rolePermissions.find((r) => r.crewId)?.crewId || null,
        permissions: rolePermissions,
      });
    }

    return tree.sort((a, b) => (a.name > b.name ? 1 : -1));
  },
};

export default RolesService;
