import React, { useState, useEffect } from "react";

import PostAddIcon from "@mui/icons-material/PostAdd";
import TableComponent from "../../components/TableComponent";
import Button from "../../components/Button";
import Confirm from "../../components/Confirm";
import CategoryItem from "./CategoryItem";

import {
  getCategories,
  deleteCategoryById,
  updateCategory,
} from "../../api/categories";

const CategoriesTable = ({ match, history }) => {
  const [categories, setCategories] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [isDeleteModalShow, setIsDeleteModalShow] = useState(false);
  const [refresh, setRefresh] = useState(1);

  function showDeleteModal(id) {
    setSelectedCategoryId(id);
    setIsDeleteModalShow(true);
  }

  function onEdit(id) {
    history.push(`${match.path}/${id}`);
  }

  async function onStatusChange(id, newStatus) {
    await updateCategory(id, { status: newStatus });

    setRefresh(refresh * -1);
  }

  async function confirmDeleteModal(isConfirmed) {
    if (isConfirmed) {
      await deleteCategoryById(selectedCategoryId);
    }
    setIsDeleteModalShow(false);

    setRefresh(refresh * -1);
  }

  useEffect(() => {
    (async () => {
      let newCategories = await getCategories();
      setCategories(newCategories);
    })();
  }, [refresh]);

  return (
    <>
      <h2>Categories</h2>
      <TableComponent
        TopComponent={
          <Button
            size="small"
            onClick={() => history.push(`${match.path}/add_category`)}
          >
            <PostAddIcon />
            Category
          </Button>
        }
        header={[
          { title: "ID", sort: "id", style: { width: "5%" } },
          { title: "Name", sort: "name" },
          { title: "Status", sort: "status", style: { width: "15%" } },
          { title: "Actions", style: { width: "15%" } },
        ]}
        renderChildren={(category) => (
          <CategoryItem
            key={category.id}
            onEdit={onEdit}
            onDelete={showDeleteModal}
            onStatusChange={onStatusChange}
            category={category}
          />
        )}
        items={categories}
        search={["name", "id"]}
        pagination
      />
      {isDeleteModalShow && selectedCategoryId ? (
        <Confirm title={"Delete Category"} onConfirm={confirmDeleteModal}>
          <p>Are you sure you want to delete the category?</p>
        </Confirm>
      ) : null}
    </>
  );
};

export default CategoriesTable;
