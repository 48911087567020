import React from "react";

import Certificate from "./Certificate";
import Refresher from "./Refresher";
import Group from "./Group";

import TableComponent from "../TableComponent";

const renderCertificate = (variant) => (certificate) => {
  if (!certificate) {
    return null;
  }
  if (certificate.group) {
    return <Group certificate={certificate} key={certificate.id} />;
  } else if (certificate.refreshers?.length) {
    return <Refresher certificate={certificate} key={certificate.id} />;
  }
  return (
    <Certificate
      variant={variant}
      certificate={certificate}
      key={certificate.id}
    />
  );
};

const CertificateList = ({ certificates, header = true, variant }) => (
  <TableComponent
    header={
      header
        ? [
            { title: "", style: { width: "5%" } },
            { title: "Name", style: { width: "70%" } },
            { title: "Validity period", style: { width: "10%" } },
            { title: "Valid until", style: { width: "10%" } },
            { title: "Actions", style: { width: "5%" } },
          ]
        : null
    }
    WrapperComponent={"div"}
    items={certificates}
    renderChildren={renderCertificate(variant)}
  />
);

export default CertificateList;
