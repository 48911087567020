import { getToken, logout } from "../services/auth";

const API_BASE_URL = process.env.REACT_APP_API_URL;

/**
 *
 * @param {string} url
 * @param {string=} method "GET" is default
 * @param {object=} body
 */
const request = async (url, method = "GET", body = undefined) => {
  const u = `${API_BASE_URL}/v1/${url}`.replace(/([^:])(\/\/)/g, "$1/");
  const token = await getToken();

  const options = {
    method,
    headers: {
      authorization: `Bearer ${token}`,
    },
  };
  if (body) {
    if (body instanceof FormData) {
      options.body = body;
    } else {
      options.headers["Content-Type"] = "application/json";
      options.body = JSON.stringify(body);
    }
  }

  const response = await fetch(u, options);
  if (response.status < 300) {
    try {
      return await response.json();
    } catch (e) {
      return await response.body();
    }
  } else {
    if (response.status === 401 || response.status === 500) {
      await logout();
    } else if (response.status === 403) {
      return false;
    }
    throw response.status;
  }
};

request.post = async (url, body) => request(url, "POST", body);
request.put = async (url, body) => request(url, "PUT", body);
request.delete = async (url) => request(url, "DELETE");

export default request;
