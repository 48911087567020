import request from "./request";

export const getCrewGroupAircraft = async (crewId) => {
  const json = await request(`/group-aircraft/${crewId}`);
  return json;
};

export const getGroupAircraftById = async (groupAircraftId) => {
  const json = await request(`/group-aircraft/by-group-id/${groupAircraftId}`);
  return json;
};

export const addGroupAircraft = async (groupAircraftName, crewId) => {
  const response = await request(`/group-aircraft`, "POST", {
    name: groupAircraftName,
    crewId
  });

  return response;
};

export const updateGroupAircraft = async (groupAircraftId, newGroupAircraft) => {
  const response = await request(
    `/group-aircraft/${groupAircraftId}`,
    "PUT",
    newGroupAircraft
  );

  return response;
};

export const deleteGroupAircraftById = async (groupAircraftId) => {
  const json = await request(`./group-aircraft/${groupAircraftId}`, "DELETE");

  return json;
};
