import React, { useState, useEffect } from "react";

import { default as MuiBadge } from "@mui/material/Badge";

import { styled } from "@mui/material/styles";

import useCrew from "../../hooks/useCrew";
import { usePubsub } from "../../hooks/usePubsub";
import { useTimer } from "../../hooks/useTimer";

import { hasPermission } from "../../services/permissions";

import { getPendingCertificatesCount } from "../../api/certificates";

const Badge = styled(MuiBadge)({
  marginLeft: "1rem",
});

// Every 10 seconds, maybe increase to 20 sec?
const DEFAULT_REFRESH_VALUE = 10000;

const PendingCertificatesBadge = () => {
  const { crew } = useCrew();
  const [pending, setPending] = useState(0);
  const canSubmit = hasPermission("approve_extern_certificates");

  const doRefresh = async () => {
    if (canSubmit) {
      const pendingResponse = await getPendingCertificatesCount(crew?.id);
      setPending(pendingResponse || 0);
    }
  };

  usePubsub("pending-changed", doRefresh); // react to own actions instantly
  useTimer(doRefresh, DEFAULT_REFRESH_VALUE, [crew.id]);

  return pending > 0 ? <Badge badgeContent={pending} color="warning" /> : null;
};

export default PendingCertificatesBadge;
