import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";

import Button from "../../components/Button";

import { addAircraft } from "../../api/aircraft";

import "./index.scss";

const AIRCRAFT_ERROR_TEXT = "Please enter the name of aircraft";

const AircraftAdd = ({ history }) => {
  const [aircraftName, setAircraftName] = useState("");
  const [aircraftErrorText, setAircraftErrorText] = useState(null);

  function handleAircraftName(value) {
    setAircraftName(value);
    if (!value) return setAircraftErrorText(AIRCRAFT_ERROR_TEXT);
    setAircraftErrorText(null);
  }

  async function addNewAircraft() {
    if (!aircraftName) return handleAircraftName(aircraftName);

    const result = await addAircraft(aircraftName);
    if (result.ok) {
      history.push("/aircraft");
    }
  }

  return (
    <>
      <h2>Add aircraft</h2>
      <Paper variant="outlined" style={{ padding: "1rem 1.5rem" }}>
        <TextField
          className="add-field"
          variant="outlined"
          size="small"
          label="Aircraft name*"
          value={aircraftName}
          onChange={(event) => handleAircraftName(event.target.value)}
          error={!!aircraftErrorText}
          helperText={aircraftErrorText}
        />
        <Button title="Add new Aircraft" onClick={addNewAircraft}>
          Add Aircraft
        </Button>
        <Button title="Go back" onClick={() => history.goBack()}>
          Cancel
        </Button>
      </Paper>
    </>
  );
};

export default AircraftAdd;
