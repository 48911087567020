import React from "react";

import BuildIcon from "@mui/icons-material/Build";

import CollapsibleListItem from "../List/CollapsibleList";
import AircraftTreeItem from "./AircraftTreeItem";
import CoursesList from "./CoursesList";
import { useCourseRelationsContext } from "../../context/CourseRelationsContext";

const OperationTreeItem = ({ nodeId, operation }) => {
  const key = `${nodeId}/o${operation.id}`;
  const actions = useCourseRelationsContext();
  const { courses, customCourses, groups, aircraft } = operation;
  const hasItems =
    courses?.length ||
    customCourses?.length ||
    groups?.length ||
    aircraft?.length;

  return (
    <CollapsibleListItem
      icon={<BuildIcon />}
      title={operation.name}
      isExpandable={hasItems}
      nodeId={key}
      onClick={() => actions.nodeClicked(key)}
    >
      <CoursesList
        nodeId={key}
        courses={courses}
        customCourses={customCourses}
        groups={groups}
      />

      {aircraft?.length
        ? aircraft.map((aircraft) => (
            <AircraftTreeItem key={`${key}/a${aircraft.id}`} aircraft={aircraft} nodeId={key} />
          ))
        : null}
    </CollapsibleListItem>
  );
};

export default OperationTreeItem;
