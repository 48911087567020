import React from "react";

import { Switch, Route } from "react-router";
import OrdersTable from "../../features/orders-table";

const Orders = ({ match, history }) => {
  return (
    <>
      <Switch>
        <Route exact path={`${match.path}`} component={OrdersTable} />
        <Route exact path={`${match.path}/:crewId/:transactionId`} component={OrdersTable} />
      </Switch>
    </>
  );
};

export default Orders;
