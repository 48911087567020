import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Button from "../../components/Button";
import TableComponent from "../../components/TableComponent";
import { getUsersInscribed, updateCheckingProgress, updateModuleStatus, updateStatusCertificate} from "../../api/training";
import {  StyledSwitch } from "../../components/Base";
import useUser from "../../hooks/useUser";
import useCrew from "../../hooks/useCrew";


const CheckerCheckingProgress = ({ match, history }) => {
  const [usersInscribed, setUsersInscribed] = useState([]);
  const [refresh, setRefresh] = useState(1);
  const trainingId = match.params.trainingId;  
  const instructorComplete = match.params.instructorComplete; 
  const assessorPassed = match.params.assessorPassed; 
  const checkerPassed = match.params.checkerPassed; 
  const evaluator = useUser();
  const  {crew}  = useCrew();  

  useEffect(() => {
    (async () => {
      const usersInscribed = await getUsersInscribed(trainingId, crew.id); 
      setUsersInscribed(usersInscribed)
    })();
  }, [refresh]);


  const onEventSwitch = async(id, field, value, userInscribed) => {
    const res = await updateCheckingProgress(id, field,value, evaluator.id);

    //Update module
    let resAfterUpdateModule = null;
    if (instructorComplete == res.instructor_complete && assessorPassed == res.assessor_passed && checkerPassed== res.checker_passed) {
      resAfterUpdateModule = await updateModuleStatus(id, 'practical_status',1)
    } else{
      resAfterUpdateModule = await updateModuleStatus(id, 'practical_status',0)
    }
    
    let validPeriod = null;
    if (userInscribed.Training.Course.ValidPeriod.valid_period){
      validPeriod = userInscribed.Training.Course.ValidPeriod.valid_period;
    }
    const  userId = userInscribed.user_id
    const courseId = userInscribed.Training.Course.id
    //Update Certificate
    if (userInscribed.Training.is_e_learning == resAfterUpdateModule.elearning_status && 
        userInscribed.Training.is_classroom == resAfterUpdateModule.classroom_status &&
        userInscribed.Training.is_practical == resAfterUpdateModule.practical_status) 
    {

      await updateStatusCertificate(userId, courseId , 1, validPeriod)
    } else{
      await updateStatusCertificate(userId, courseId,  0, validPeriod)
    }
    setRefresh(refresh * -1);
  };

  return (
    <>
      <Typography variant="h4">Checker checking progress</Typography>
      {usersInscribed? (
        <TableComponent
          header={[
            { title: "User ID", style: { width: "5%" } },
            { title: "User"},
            { title: "Assessment Passed" },
          ]}
          renderChildren={(userInscribed,  index) => (
            <TableRow key={index}>
              <TableCell>{userInscribed.user_id}</TableCell>
              <TableCell>{userInscribed.User.fullname}</TableCell>

              <TableCell>
              {checkerPassed == 1  &&
                <StyledSwitch
                  title="Checker Passed"
                  disabled={userInscribed.checker_passed===1}
                  checked={userInscribed.checker_passed===1}
                  onChange={() => {
                    const valSwitch = userInscribed.checker_passed === 1 ? 0 : 1;
                    onEventSwitch(userInscribed.id, 'checker_passed', valSwitch, userInscribed) 
                    }
                  }
                />
              }
              </TableCell>

            </TableRow>
          )}
          search={["user_id","fullname"]}
          pagination
          items={usersInscribed}
        />
      ) : null}
      <Button title="Back" onClick={() => history.goBack()}>
              Back
      </Button>
    </>
  );
};
export default CheckerCheckingProgress;
