import React, { useState } from "react";
import Paper from "@mui/material/Paper";

import TextField from "@mui/material/TextField";
import Button from "../../components/Button";

import { addCategory } from "../../api/categories";

import "./index.scss";

const CATEGORY_ERROR_TEXT = "Please enter the name of Category";

const CategoriesAdd = ({ history }) => {
  const [categoryName, setCategoryName] = useState("");
  const [categoryErrorText, setCategoryErrorText] = useState(null);

  function handleCategoryName(value) {
    setCategoryName(value);
    if (!value) return setCategoryErrorText(CATEGORY_ERROR_TEXT);
    setCategoryErrorText(null);
  }

  async function addNewCategory() {
    if (!categoryName) return handleCategoryName(categoryName);

    const result = await addCategory(categoryName);
    if (result.ok) {
      history.push("/categories");
    }
  }

  return (
    <>
      <h2>Add</h2>
      <Paper variant="outlined" style={{ padding: "1rem 1.5rem" }}>
        <TextField
          className="add-field"
          variant="outlined"
          size="small"
          label="Category name*"
          value={categoryName}
          onChange={(event) => handleCategoryName(event.target.value)}
          error={!!categoryErrorText}
          helperText={categoryErrorText}
        />
        <Button title="Add new Category" onClick={addNewCategory}>
          Add
        </Button>
        <Button title="Go back" onClick={() => history.goBack()}>
          Cancel
        </Button>
      </Paper>
    </>
  );
};

export default CategoriesAdd;
