function sortByName(array) {
  return array.sort((a, b) => {
    if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
    return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : 0;
  });
}

const RelationTreeService = {
  getRelationFromNodeId(nodeId) {
    const route = nodeId.split("/");
    const relation = {};
    for (let i = 0; i < route.length; i++) {
      let v = route[i];
      if (/^cat/.test(v)) {
        relation.categoryId = +v.replace("cat", "");
      }
      if (/^a/.test(v)) {
        relation.aircraftTypeId = +v.replace("a", "");
      }
      if (/^s/.test(v)) {
        relation.specializationId = +v.replace("v", "");
      }
      if (/^p/.test(v)) {
        relation.positionId = +v.replace("p", "");
      }
      if (/^o/.test(v)) {
        relation.operationId = +v.replace("o", "");
      }
    }
    return relation;
  },
  sortRelations(relations) {
    const sortedRelations = sortByName(relations);
    sortedRelations.forEach((rel) => {
      rel.courses = sortByName(rel.courses);
      rel.customCourses = sortByName(rel.customCourses);
      rel.groups = sortByName(rel.groups);
      rel.positions.forEach((position) => {
        position.courses = sortByName(position.courses);
        position.customCourses = sortByName(position.customCourses);
        position.groups = sortByName(position.groups);
      });
    });
    return sortedRelations;
  },
};

export default RelationTreeService;
