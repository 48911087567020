import React from "react";
import CrewList from "../../components/CrewList";
import useUser from "../../hooks/useUser";

const UserCrewsList = () => {
  const crews = useUser().crews;

  function exitFromCourse(id) {}

  return (
    <>
      <CrewList crews={crews} onExit={exitFromCourse} />
    </>
  );
};

export default UserCrewsList;
