/* Cross-reload session store */
const UserSession = {
  get activeCrewId() {
    return sessionStorage.getItem("selected_organisation") || null;
  },
  set activeCrewId(id) {
    sessionStorage.setItem("selected_organisation", id);
  },
};

export default UserSession;
