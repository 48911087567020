export const EVENTS = [
    {
      event_id: 1,
      title: "Course 1",
      start: new Date("2021 5 2 00:00"),
      end: new Date("2021 5 3 00:00"),
    },
    {
      event_id: 2,
      title: "Course 2",
      start: new Date("2021 5 4 10:00"),
      end: new Date("2021 5 4 11:00"),
      admin_id: 2
    },
    {
      event_id: 3,
      title: "Course 3",
      start: new Date("2021 4 27 09:00"),
      end: new Date("2021 4 28 10:00"),
      admin_id: 1
    },
    {
      event_id: 4,
      title: "Course 4",
      start: new Date("2021 5 4 9:00"),
      end: new Date("2021 5 4 10:36"),
      admin_id: 2
    },
    {
      event_id: 5,
      title: "Course X",
      start: new Date("2021 5 1 10:00"),
      end: new Date("2021 5 18 11:00"),
      admin_id: 2,
      color: "green"
    },
    {
      event_id: 6,
      title: "Course 6",
      start: new Date("2021 5 2 11:00"),
      end: new Date("2021 5 2 12:00"),
      admin_id: 2
    },
    {
      event_id: 7,
      title: "Course 7",
      start: new Date("2021 5 1 12:00"),
      end: new Date("2021 5 1 13:00"),
      admin_id: 3
    },
    {
      event_id: 8,
      title: "Course 8",
      start: new Date("2021 5 1 13:00"),
      end: new Date("2021 5 1 14:00"),
      admin_id: 3
    },
    {
      event_id: 9,
      title: "Course 11",
      start: new Date("2021 5 5 16:00"),
      end: new Date("2021 5 5 17:00"),
      admin_id: 1
    },
    {
      event_id: 10,
      title: "Course 9",
      start: new Date("2021 5 6  15:00"),
      end: new Date("2021 5 6 16:00"),
      admin_id: 2
    },
    {
      event_id: 11,
      title: "Course 10",
      start: new Date("2021 5 6 14:00"),
      end: new Date("2021 5 6 15:00"),
      admin_id: 2
    }
  ];