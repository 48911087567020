import React, { useState, useEffect } from "react";

import PostAddIcon from "@mui/icons-material/PostAdd";
import Button from "../../components/Button";
import TableComponent from "../../components/TableComponent";

import Confirm from "../../components/Confirm";
import OperationItem from "./OperationItem";

import {
  getOperations,
  deleteOperationById,
  updateOperation,
} from "../../api/operations";

const OperationsTable = ({ match, history }) => {
  const [operations, setOperations] = useState([]);
  const [selectedOperationId, setSelectedOperationId] = useState(null);
  const [isDeleteModalShow, setIsDeleteModalShow] = useState(false);
  const [refresh, setRefresh] = useState(1);

  function showDeleteModal(id) {
    setSelectedOperationId(id);
    setIsDeleteModalShow(true);
  }

  function onEdit(id) {
    history.push(`${match.path}/${id}`);
  }

  async function onStatusChange(id, newStatus) {
    await updateOperation(id, { status: newStatus });

    setRefresh(refresh * -1);
  }

  async function confirmDeleteModal(isConfirmed) {
    if (isConfirmed) {
      await deleteOperationById(selectedOperationId);
    }
    setIsDeleteModalShow(false);

    setRefresh(refresh * -1);
  }

  useEffect(() => {
    (async () => {
      let newOperations = await getOperations();
      const operations = newOperations.map((operation) => ({
        ...operation,
        positions: operation.positions
          .map((position) => position.name)
          .join(", "),
      }));
      setOperations(operations);
    })();
  }, [refresh]);

  return (
    <>
      <h2>Operations</h2>
      <TableComponent
        TopComponent={
          <Button
            size="small"
            onClick={() => history.push(`${match.path}/add_operation`)}
          >
            <PostAddIcon />
            Add new operation
          </Button>
        }
        header={[
          { title: "ID", sort: "id", style: { width: "5%" } },
          { title: "Operation name", sort: "name" },
          { title: "Positions", sort: "positions" },
          { title: "Status", sort: "status", style: { width: "15%" } },
          { title: "Actions", style: { width: "15%" } },
        ]}
        renderChildren={(operation) => (
          <OperationItem
            key={operation.id}
            onEdit={onEdit}
            onDelete={showDeleteModal}
            onStatusChange={onStatusChange}
            operation={operation}
          />
        )}
        items={operations}
        search={["name", "id", "positions"]}
        pagination
      />
      {isDeleteModalShow && selectedOperationId ? (
        <Confirm title={"Delete Operation"} onConfirm={confirmDeleteModal}>
          <p>Are you sure you want to delete the operation?</p>
        </Confirm>
      ) : null}
    </>
  );
};
export default OperationsTable;
