import React from "react";

import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

import Actions from "../Certificate/Actions";

import TableComponent from "../TableComponent";

import "./index.scss";

const BundleList = ({ courses }) => {
  const header = [
    { title: "ID", style: { width: "5%" } },
    { title: "Name", style: { width: "15%" } },
    { title: "Description", style: { width: "25%" } },
    { title: "Includes", style: { width: "30%" } },
    { title: "Price", style: { width: "15%" } },
    { title: "Actions", style: { width: "15%" } },
  ];
  return (
    <TableComponent
      pagination
      pageSize={25}
      items={courses}
      search={["name"]}
      header={header}
      renderChildren={(course) => (
        <TableRow key={course.id}>
          <TableCell>{course.id}</TableCell>
          <TableCell>{course.name}</TableCell>
          <TableCell>{course.description}</TableCell>
          <TableCell>
            <div className="courses" title={course.courses?.join(", ")}>
              {course.courses?.map(({ id, shortname }) => (
                <span key={id}>{shortname}</span>
              ))}
            </div>
          </TableCell>
          <TableCell>{+course.price ? course.price : "Free"}</TableCell>
          <TableCell style={{ width: "5%" }}>
            <Actions certificate={course} />
          </TableCell>
        </TableRow>
      )}
    />
  );
};

export default BundleList;
