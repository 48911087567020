import { useMemo } from "react";

import { SelectableWithIdObject } from "src/components/Form/types";
import { formatBufferZone } from "src/services/formatters";
import { BUFFER_ZONES } from "../constants";

class BufferZone extends SelectableWithIdObject {
  constructor(id: number) {
    super(id);
  }

  getDisplayName(): string {
    return formatBufferZone(this.id);
  }
}

const mapBufferZones = (id: number): BufferZone => {
  return new BufferZone(id);
};

export const useBufferZones = (): SelectableWithIdObject[] => {
  const bufferZones = useMemo(() => {
    return BUFFER_ZONES.map((id) => mapBufferZones(id));
  }, []);

  return bufferZones;
};
