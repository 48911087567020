import React from "react";
import { Route } from "react-router-dom";

import ActivitiesTrainingTable from "../../features/activities-training";
import EditActivitiesTraining from "../../features/activities-training/edit";

const ActivitiesTraining = ({ match }) => (
  <>
    <Route path={`${match.path}/add`} component={EditActivitiesTraining} />
    <Route path={`${match.path}/edit/:activitiesTrainingId`} component={EditActivitiesTraining} />
    <Route exact path={`${match.path}`} component={ActivitiesTrainingTable} />  
  </>
);

export default ActivitiesTraining;
