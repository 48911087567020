import React from "react";

import FlightIcon from "@mui/icons-material/Flight";

import CollapsibleListItem from "../List/CollapsibleList";
import CoursesList from "./CoursesList";
import SpecializationTreeItem from "./SpecializationTreeItem";

import { useCourseRelationsContext } from "../../context/CourseRelationsContext";

const AircraftTreeItem = ({ aircraft, nodeId }) => {
  const key = `${nodeId}/a${aircraft.id}`;
  const actions = useCourseRelationsContext();

  const { courses, customCourses, groups, specializations } = aircraft;

  return (
    <CollapsibleListItem
      nodeId={key}
      icon={<FlightIcon />}
      isExpandable={
        courses?.length ||
        customCourses?.length ||
        groups?.length ||
        specializations?.length
      }
      title={aircraft.name}
      onClick={() => actions.nodeClicked(key)}
    >
      <CoursesList
        nodeId={key}
        courses={courses}
        customCourses={customCourses}
        groups={groups}
      />
      {specializations?.length
        ? specializations.map((specialization) => (
            <SpecializationTreeItem
              key={`${key}/s${specialization.id}`}
              specialization={specialization}
              nodeId={key}
            />
          ))
        : null}
    </CollapsibleListItem>
  );
};

export default AircraftTreeItem;
