import React from "react";

import MaterialSelect from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import { getCountriesList } from "../../services/countries";
const countries = getCountriesList();

const CountriesList = ({ selected, onChange }) => {
  return (
    <MaterialSelect
      variant="outlined"
      type="text"
      value={selected}
      label="Country"
      size="small"
      onChange={(e) => onChange(e.target?.value)}
    >
      {countries.map((country) => (
        <MenuItem key={country.code} value={country.code}>
          {country.label}
        </MenuItem>
      ))}
    </MaterialSelect>
  );
};

export default CountriesList;
