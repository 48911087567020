import React, { useState } from "react";

import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import Autocomplete from "../Form/StyledAutocomplete";
import Button from "../Button";
import { BasePaper, Row, ErrorMessage, Message } from "../Base";
import TableComponent from "../TableComponent";
import useCrew from "../../hooks/useCrew";

const AircraftSpecializationRow = ({ aircraft, onEdit, onDelete }) => (
  <TableRow>
    <TableCell>{aircraft.name}</TableCell>
    <TableCell>
      {aircraft?.specializations?.map((s) => s.name).join(", ")}
    </TableCell>
    <TableCell>

      <Button
        onClick={() => onEdit(aircraft)}
        icon={<EditIcon />}
        title="Edit aircraft specializations"
      />
      <Button
        onClick={() => onDelete(aircraft)}
        icon={<DeleteIcon />}
        title="Delete aircraft specializations"
      />
    </TableCell>
  </TableRow>
);

const AircraftSpecializationForm = ({
  //crewGroupAircraft,
  allAircraft,
  crewAircraft,
  specializations,
  onSave,
  onDelete
}) => {
  const [selectedGroupAircraft, setSelectedGroupAircraft] = useState(null);
  const [selectedAircraft, setSelectedAircraft] = useState(null);
  const [selectedSpecializations, setSelectedSpecializations] = useState(null);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const { crew } = useCrew();


  async function confirmDeleteModal(item) {
    onDelete(item);
  }

  const selectAircraft = (aircraft) => {
    setError(null);
    setSelectedAircraft(aircraft || null);
    const specializationIds = aircraft?.specializations?.map((spec) => spec.id);
    setSelectedSpecializations(
      aircraft && specializationIds
        ? specializations.filter((spec) => specializationIds.includes(spec.id))
        : null
    );
  };

  const selectGroupAircraft = (groupAircraft) => {
    setError(null);
    setSelectedGroupAircraft(groupAircraft || null);
  };

  const save = async () => {
    setError(null);
    setMessage(null);
    const groupAircraftId = selectedGroupAircraft?.id;
    const aircraftId = selectedAircraft?.id;
    if (!aircraftId) {
      setError("Please select aircraft");
    } else {
      const specializationsIds = selectedSpecializations?.map((s) => s.id);
      const success = await onSave(groupAircraftId, aircraftId, specializationsIds);
      if (!success) {
        setError("Can't add specializations, please try again later");
      } else {
        setMessage("Successfully added specializations");
        selectAircraft(null);
        selectedGroupAircraft(null);
      }
    }
  };

  return (
    <BasePaper>
      {/* 
      <Row>
        <Autocomplete
          label="Group aircraft"
          items={crewGroupAircraft}
          selected={selectedGroupAircraft}
          onChange={selectGroupAircraft}
        />
      </Row>
       */}
      <Row>
        <Autocomplete
          label="Aircraft"
          items={allAircraft}
          selected={selectedAircraft}
          onChange={selectAircraft}
        />
      </Row>
      <Row>
        <Autocomplete
          label="Specializations"
          items={specializations}
          selected={selectedSpecializations || []}
          multiple
          onChange={setSelectedSpecializations}
        />
      </Row>
      <Row display="flex" justifyContent="space-between" alignItems="center">
        <Button onClick={save}>Add</Button>
        <Message message={message} />
      </Row>
      {error ? (
        <Row>
          <ErrorMessage error={error} />
        </Row>
      ) : null}
      <TableComponent
        items={crewAircraft}
        header={[
          { title: "Aircraft", style: { width: "50%" } },
          { title: "Specializations", style: { width: "40%" } },
          { title: "Action", style: { width: "10%" } },
        ]}
        renderChildren={(item) => (
          <AircraftSpecializationRow
            key={item.id}
            aircraft={item}
            onEdit={selectAircraft}
            onDelete={confirmDeleteModal}
          />
        )}
      />
    </BasePaper>
  );
};

export default AircraftSpecializationForm;
