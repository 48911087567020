import { createContext, useContext } from "react";

const CertificateActions = createContext({
  showHistory(course) {},
  getBoughtCourses() {},
});

export default CertificateActions;

export const useCertificateActions = () => useContext(CertificateActions);
