import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Button from "../../components/Button";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import {Row} from "../../components/Form";
import "./index.scss";
import {
  updateNews,
  getNewsById
} from "../../api/news";
import { getNgftApp, } from "../../api/ngftApp.js";


const NewsEdit = ({ match, history }) => {
  const [isDataFetched, setIsDataFetched] = useState(false);
  const [newSource, setnewSource] = useState("");
  const [newTitle, setnewTitle] = useState("");
  const [newDescription, setnewDescription] = useState("");
  const [newLink, setnewLink] = useState("");
  const [source, setsource] = useState([]);
  const [value, setValue] = useState('');


  useEffect(() => {
    (async () => {
      const news = await getNewsById(match.params.newsId);
      let newSources = await getNgftApp();
      setIsDataFetched(true);
      setnewSource(news.source);
      setnewTitle(news.title);
      setnewDescription(news.description);
      setnewLink(news.ext_link);
      setsource(newSources);
      let selectSource = newSources.find( app  => app.id === parseInt(news.source) );
      setValue(selectSource)
    })();
  }, [match.params.categoryId]);

  const [groupNameErrorText, setGroupNameErrorText] = useState(null);

  async function save() {
    if (newSource == "" || newTitle == "" || newDescription == "" || newLink == "" ){
      return
    } else {
      const newsId = match.params.newsId;
      const result = await updateNews(newsId, {
        source: value.id,
        title: newTitle,
        description: newDescription,
        ext_link: newLink,

      });
      if (result.ok) {
        history.push("/news");
      }
    }
  }
  return (
    <>
      <h2>Edit News</h2>
      <Paper
        variant="outlined"
        style={{ padding: "1rem 1.5rem" }} 
      >
        {isDataFetched ? (
          <>
              <Row>
              <Autocomplete
                  value={value}
                  onChange={(event, newValue) => {
                    setValue(newValue);
                  }}
                  id="combo-box-demo"
                  options={source}
                  getOptionLabel={(option) => option.name}
                  style={{ width: 300 }}
                  renderInput={(params) => <TextField {...params} label="Source App"  variant="outlined" />}
              />
             </Row>
              <Row>
                <TextField fullWidth 
                  variant="outlined"
                  size="small"
                  label="Title*"
                  value={newTitle}
                  onChange={(event) => setnewTitle(event.target.value)}
                  error={newTitle === ""}
                  helperText={newTitle === "" ? "Please add value!" : " "}
                />
              </Row>
              <Row>
                <TextField fullWidth 
                  variant="outlined"
                  size="small"
                  label="Description*"
                  multiline
                  rows={3}
                  value={newDescription}
                  onChange={(event) => setnewDescription(event.target.value)}   
                  error={newDescription === ""}
                  helperText={newDescription === "" ? "Please add value!" : " "}    
                />
              </Row>
              <Row>
                <TextField fullWidth 
                  variant="outlined"
                  size="small"
                  label="Link*"
                  value={newLink}
                  onChange={(event) => setnewLink(event.target.value)}
                  error={newLink === ""}
                  helperText={newLink === "" ? "Please add value!" : " "}     
                /> 
              </Row>
              <Button title="Save" onClick={save}>
                Save
              </Button>

              <Button
                title="Go back"
                type="button"
                onClick={() => history.goBack()}
              >
                Cancel
              </Button>
          </>
        ) : null}
      </Paper>
    </>
  );
};

export default NewsEdit;
