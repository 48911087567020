import React, { useState, useEffect } from "react";

import Typography from "@mui/material/Typography";

import Button from "../../components/Button";
import { StyledPaper, StyledInput, Row } from "../../components/Base";
import Autocomplete from "../../components/Form/StyledAutocomplete";

import { getRoles } from "../../api/roles";
import { sendInviteEmail } from "../../api/user";

const UserInvite = ({ match, history }) => {
  const { crewId } = match?.params;
  const [email, setEmail] = useState(null);
  const [crewRoles, setCrewRoles] = useState(null);
  const [availableRoles, setAvailableRoles] = useState(null);
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    (async () => {
      const availableRoles = await getRoles(crewId);
      setAvailableRoles(availableRoles);
    })();
  }, []);

  const onSubmit = async () => {
    setMessage(null);
    setError(null);
    const res = await sendInviteEmail(crewId, email, crewRoles);
    if (res.ok) {
      setMessage("Invitation email sent");
      setEmail(null);
      setCrewRoles(null);
    } else if (res.reason === "USER_NOT_EXISTS") {
      setError(
        "No user with given email found. Please use Add Member form to add new user"
      );
    } else if (res.reason === "USER_ALREADY_A_MEMBER") {
      setError("User already a member of a company");
    } else {
      setError("Error sending message");
    }
  };

  return (
    <>
      <Row>
        <Typography variant="h4">Invite Member</Typography>
      </Row>
      <StyledPaper variant="outlined">
        <Row>
          <StyledInput
            fullWidth
            label="User Email"
            name="email"
            value={email || ""}
            onChange={(v) => setEmail(v)}
          />
        </Row>
        <Row>
          <Autocomplete
            multiple
            label="User Roles"
            selected={crewRoles || []}
            items={availableRoles || []}
            onChange={(items) => setCrewRoles(items)}
          />
        </Row>
        <Row>
          <Button onClick={onSubmit}>Invite</Button>
        </Row>
        <Row>
          {message ? <Typography color="primary">{message}</Typography> : null}
          {error ? <Typography color="error">{error}</Typography> : null}
        </Row>
      </StyledPaper>
    </>
  );
};

export default UserInvite;
