
import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "../../components/Button";
import { Form, DatePicker, FileInput, Textarea, Validation, InputForm } from "../../components/Form";
import { StyledPaper } from "../../components/Base";
import { fireEvent } from "../../hooks/usePubsub";
import {  uploadWrittenTest } from "../../api/training";



const validation = {
  certificateFile: Validation.required,
  validAnswer: Validation.required,
  completedDate: Validation.noop,
  comment: Validation.noop,
};

const MAX_FILE_SIZE = 1024 * 1024 * 10; // 10 Mb

const WrittenTestTraining = ({ match, history }) => {

  const trainingId = match.params.trainingId;
  const userId = match.params.userId;

  const submit = async (values) => {
    
    if (values.certificateFile[0]?.size >= MAX_FILE_SIZE) {
      return {
        certificateFile: "File is too large. Maximum acceptable size is 10Mb",
      };
    }

    const result = await uploadWrittenTest({
      userId,
      trainingId,
      validAnswer: values.validAnswer,
      files: values.certificateFile,
      completedDate: values.completedDate,
      comment: values.comment,
    });

    if (result.status === "ok") {  
      history.goBack();
      fireEvent("pending-changed");
      return null;
    } else {
      console.log("error")
      return result?.reason;
    }
    /* 
    if (error) {
      return {
        certificateFile: `Error uploading file: ${error}`,
      };
    }
    */
  };

  return (
    <StyledPaper variant="outlined">
      <Form onSubmit={submit} validation={validation}>
        <Box m={2}>
          <Typography variant="subtitle1">
             Written Test upload
          </Typography>
        </Box>
        <Box m={2}>
          <Box display={"inline-block"}>
            <InputForm name="validAnswer" label="Valid answer" />
          </Box>
        </Box>

        <Box m={2}>
          <FileInput
            name="certificateFile"
            accept="image/*,.pdf"
            label="Certificate File*"
          />
        </Box>

        <Box m={2}>
          <Box marginRight={"1rem"} display={"inline-block"}>
            <DatePicker
              name="completedDate"
              //disableFuture
              label="Complete Date*"
            />
          </Box>
        </Box>
        <Box m={2}>
          <Textarea name="comment" label="Comment" rows={5} />
        </Box>
        <Box m={2}>
          <Button type="submit" title="Save Certificate">
            Save
          </Button>
          <Button title="Back" onClick={() => history.goBack()}>
              Cancel
          </Button>
        </Box>

      </Form>
    </StyledPaper>
  );
};
export default WrittenTestTraining;
