const UserRolesEditService = {
  mapCrews(crews) {
    return crews.map((crew) => ({
      id: crew.id,
      name: crew.companyName,
    }));
  },
  mapRoles(roles) {
    return roles.map((r) => ({
      id: r.id,
      name: r.name,
    }));
  },
};

export default UserRolesEditService;
