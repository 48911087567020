import request from "./request";


export const getProcessStep = async () => {
  const json = await request(`/process-step`);
  return json;
};

//getpassCondition
export const getProcessStepById = async (processStepId) => {
  const processStep = await request(`process-step/${processStepId}`);
  return processStep;
};

export const createProcessStep = async (processStep, activitiesTrainingsIds) => {
  const response = await request.post(
    `process-step`,
    {processStep, activitiesTrainingsIds}
  );
  return response;
};

export const updateProcessStep = async (
  processStepId, 
  processStep,
  activitiesTrainingsIds
) => {

  const response = await request.put(
    `process-step/${processStepId}`,
    {processStep, activitiesTrainingsIds}
  );
  return response;
};

export const deleteProcessStepById = async (processStepId) => {
  const json = await request(`process-step/${processStepId}`, "DELETE");
  return json;
};