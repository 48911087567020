import { SelectableObject } from "src/components/Form/types";
import languages from "./languages.json";

export class Language extends SelectableObject {
  constructor(
    public value: string,
    public label: string,
  ) {
    super();
  }

  getDisplayName(): string {
    return this.label;
  }

  getId(): string {
    return this.value;
  }
}

const mapLanguage = (item: { label: string; value: string }): Language => {
  return new Language(item.value, item.label);
};

export const getLanguageByCode = (code: string): Language | undefined =>
  getLanguagesList().find(
    (language) => language.value.toLowerCase() === (code || "").toLowerCase(),
  );

export const getLanguagesList = (): Language[] => {
  return languages.map(mapLanguage);
};

export const getLanguageByName = (label: string): Language | undefined => {
  return getLanguagesList().find(
    (language) => language.label.toLowerCase() === label.toLowerCase(),
  );
};
