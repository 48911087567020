import React, { useEffect, useState } from "react";
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { getPublishedNews} from "../../api/news";


function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff'),
        color: (theme) => (theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800'),
        border: '1px solid',
        borderColor: (theme) =>
          theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
        p: 1,
        m: 1,
        borderRadius: 2,
        fontSize: '0.875rem',
        fontWeight: '700',
        ...sx,
      }}
      {...other}
    />
  );
}

Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool]),
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};

function NewsSidebar() {
  const [news, setNews] = useState([]);

  useEffect( () => {
    (async () => {
      const newNews = await getPublishedNews();
      setNews(newNews);
    })();
  }, []);

  return (
            <List sx={{ bgcolor: 'background.paper' }}>
                  {news.map((value) => (
                    <ListItemButton 
                      key={value.id}
                      component="a" 
                      href={value.link}
                      target="_blank"
                      alignItems="flex-start"
                      disableGutters
                    >
                    <ListItemAvatar>
                      <Avatar sx={{ bgcolor: value.color }} variant="rounded">
                      {value.short_name}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText 
                      primary={value.title} 
                      secondary={
                        <React.Fragment>
                          <Typography
                            sx={{ display: 'inline' }}
                            component="span"
                            variant="body2"
                            color="text.primary"
                          >
                          </Typography>
                          {value.description}
                        </React.Fragment>
                      }
                      />
                      <Divider />
                    </ListItemButton>  
                  ))}
            </List>            
     
  );
}

export default NewsSidebar