import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "../../components/Button";

const Confirm = ({ title, children, onConfirm, notification = false }) => {
  const close = () => onConfirm(false);

  return (
    <>
      <Dialog open={true} onClose={close}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent style={{ lineHeight: 1.4 }}>{children}</DialogContent>
        <DialogActions>
          {notification ? (
            <Button title="Close Dialog" onClick={close} color="primary">
              OK
            </Button>
          ) : (
            <>
              <Button
                title="Cancel action"
                autoFocus
                onClick={close}
                color="primary"
              >
                Cancel
              </Button>
              <Button
                title="Confirm action"
                onClick={() => onConfirm(true)}
                color="primary"
              >
                Confirm
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Confirm;
