import useCrew from "../hooks/useCrew";

export const WithPermission = ({ permission, children }) => {
  const { crew } = useCrew();
  if (
    (permission === "super_user" && crew.id === 0) ||
    crew?.permissions?.find((perm) => perm.code === permission)
  ) {
    return children;
  }
  return null;
};

export const hasPermission = (permission) =>
  WithPermission({
    permission,
    children: true,
  });
