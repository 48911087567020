import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Button from "../../components/Button";
import Stack from '@mui/material/Stack';
import UserScheduledSlots from "../../components/UserScheduledSlots";
import { getTrainingModuleStatus, getTrainingDetails, getScheduleSlots } from "../../api/training";
import Box from '@mui/material/Box';
import TrainingModuleStatus from "../../components/TrainingModuleStatus";
import PlayCircleIcon from "@mui/icons-material/PlayCircleFilled";
import { goToCourse } from "../../api/";
import { useConfirm } from "../../context/ConfirmContext";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import useUser from "../../hooks/useUser";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';

const convertdate = (date) => {
  if (date == null)  
    return ('')
  else 
    return (new Date(date)).toLocaleDateString('de-CH')
}

function sleep(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

const detailsTraining = ({ match, history }) => {
  const [refresh, setRefresh] = useState(1);
  const [ training, setTraining] = useState([]);
  const [ scheduleSlots, setScheduleSlots] = useState([]);
  const [ trainingModuleStatus, setTrainingModuleStatus] = useState([]);
  const userId = parseInt(match.params.userId);
  const trainingId = match.params.trainingId;
  const dialog = useConfirm();
  const me = useUser();



  useEffect(() => {
    (async () => {
      //const resTraining = await getTraining(trainingId);
      const resTraining = await getTrainingDetails(trainingId);
      const resSchedule = await getScheduleSlots(match.params.trainingId, match.params.userId);
      const trainingModuleStatus = await getTrainingModuleStatus(trainingId, userId);
      setTrainingModuleStatus(trainingModuleStatus);
      setTraining(resTraining); 
      setScheduleSlots(resSchedule)

    })();
  }, [refresh]);

  const showBoughtIcon = me.id !== userId;
  const showPlayIcon = me.id === userId;



  return (
    <>
  
    <Typography variant="h4">Progress</Typography>
    <Stack spacing={2}>  

      {(trainingModuleStatus===null) ? 
      <Typography>
        Nothing to show (not enrolled)
      </Typography>
      : 
      <>
        {(training.is_e_learning ===1) ? (
          <Box> 
            <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', }}>
                <Box sx={{ m: 1 }}> 
                  <TrainingModuleStatus status={trainingModuleStatus.elearning_status} />
                </Box>
                <Typography variant="h6">
                    E-learning Module
                </Typography>
            </div> 
            <Typography>
                    Online Course:
                        {showPlayIcon ? (
                              <Button
                              title="Go To Online Course"
                              icon={<PlayCircleIcon />}
                              onClick={async () => {
                                const url = await goToCourse(training.e_learning_moodle, userId);
                                if (!url) {
                                  dialog.notification(
                                    "Error enrolling course",
                                    "Please try again later"
                                  );
                                } else {
                                  var iframe = document.createElement('iframe');
                                  iframe.src = process.env.REACT_APP_MOODLE_HOST_URI + "/login/co-logout.php"; 
                                  document.body.appendChild(iframe);
                                  await sleep(1000)
                                  window.open(url);
                                }
                              }}
                            />
                        ) : null}

                        {showBoughtIcon ? (
                          <Button
                            title={`Course ready for user`}
                            icon={<AssignmentTurnedInIcon />}
                            onClick={() => {}}
                          />
                        ) : null}
              </Typography>
              <Paper elevation={0} />
          </Box>
        
        ) : null}
      <Divider />


        {(training.is_classroom ===1) ? (
          <Box> 
            <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', }}>
              <Box sx={{ m: 1 }}> 
                  <TrainingModuleStatus status={trainingModuleStatus.classroom_status} />
              </Box>
                <Typography variant="h6">
                    Classroom Module
                </Typography>
            </div> 

            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell align="right">Value</TableCell>
                    <TableCell align="right">Type</TableCell>
                    <TableCell align="right">Passed</TableCell>
                    <TableCell align="right">Evaluator</TableCell>
                    <TableCell align="right">Date</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                {training.classroom_attendance == 1 ? (
                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                      <TableCell component="th" scope="row">Attendance</TableCell>
                      <TableCell align="right">{training.classroom_resource_link}</TableCell>
                      <TableCell align="right">{training.classroom}</TableCell>
                      <TableCell align="right"><TrainingModuleStatus status={trainingModuleStatus.classroom_attendance} /></TableCell> 
                      <TableCell align="right">{trainingModuleStatus.classroom_attendancet_fullname}</TableCell>
                      <TableCell align="right">{convertdate(trainingModuleStatus.classroom_attendance_date)}</TableCell>
                    </TableRow>
                ): null}

                {training.classroom_check == 1 ? (
                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                      <TableCell component="th" scope="row">Test</TableCell>
                      <TableCell align="right">
                        {training.written_test == 1 ? (
                              'Written test'
                            ) : (
                              'Online test'
                            )} 
                      </TableCell>
                      <TableCell align="right">
                        {training.written_test == 1 ? (
                              'Upload written test'
                            ) : (
                              <Button
                              title="Go To Online Test"
                              icon={<PlayCircleIcon />}
                              onClick={async () => {
                                const url = await goToCourse(training.classroom_moodle, userId);
                                if (!url) {
                                  dialog.notification(
                                    "Error enrolling course",
                                    "Please try again later"
                                  );
                                } else {
                                  var iframe = document.createElement('iframe');
                                  iframe.src = process.env.REACT_APP_MOODLE_HOST_URI + "/login/co-logout.php"; 
                                  document.body.appendChild(iframe);
                                  await sleep(1000)
                                  window.open(url);
                                }
                              }}
                            />
                            )} 
                      </TableCell>
                      <TableCell align="right"><TrainingModuleStatus status={trainingModuleStatus.classroom_test} /></TableCell> 
                      <TableCell align="right">{trainingModuleStatus.classroom_test_fullname}</TableCell>
                      <TableCell align="right">{convertdate(trainingModuleStatus.classroom_test_date)}</TableCell>
                    </TableRow>
                  ): null}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        ) : null}
        <Divider />

        {(training.is_practical ===1) ? (
          <>
          <Box> 
            <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', }}>
              <Box sx={{ m: 1 }}> 
                    <TrainingModuleStatus status={trainingModuleStatus.practical_status} />
              </Box>
                
                <Typography variant="h6">
                    Practical Module
                </Typography>
            </div>         
          </Box>

            <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>

                      <TableCell align="right">Passed</TableCell>
                      <TableCell align="right">Evaluator</TableCell>
                      <TableCell align="right">Date</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                  {training.instructor_complete == 1 ? (
                      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                        <TableCell component="th" scope="row">Instructor sign-off</TableCell>
                        <TableCell align="right"><TrainingModuleStatus status={trainingModuleStatus.instructor_complete} /></TableCell> 
                        <TableCell align="right">{trainingModuleStatus.instructor_fullname}</TableCell>
                        <TableCell align="right">{convertdate(trainingModuleStatus.instructor_complete_date)}</TableCell>
                      </TableRow>
                  ): null}

                  {training.assessor_passed == 1 ? (
                      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                        <TableCell component="th" scope="row">Assessment passed</TableCell>
                        <TableCell align="right"><TrainingModuleStatus status={trainingModuleStatus.assessor_passed} /></TableCell> 
                        <TableCell align="right">{trainingModuleStatus.assessor_fullname}</TableCell>
                        <TableCell align="right">{convertdate(trainingModuleStatus.assessor_passed_date)}</TableCell>
                      </TableRow>
                  ): null}

                  {training.checker_passed == 1 ? (
                      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                        <TableCell component="th" scope="row">Check passed</TableCell>
                        <TableCell align="right"><TrainingModuleStatus status={trainingModuleStatus.checker_passed} /></TableCell> 
                        <TableCell align="right">{trainingModuleStatus.checker_fullname}</TableCell>
                        <TableCell align="right">{convertdate(trainingModuleStatus.checker_passed_date)}</TableCell>
                      </TableRow>
                  ): null}

                  </TableBody>
                </Table>
              </TableContainer>
          </>
        ) : null} 
        <Divider />
        
        {(trainingModuleStatus.practical_status ===0) ? (
                <Box> 
                      <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', }}>
                        
                          <Typography variant="h6">
                              Schedule slots
                          </Typography>
                          <UserScheduledSlots  scheduleSlots={scheduleSlots} />
                      </div> 
                </Box>
          ) : null} 

      
      </>
      }
          <Button
            title="Go back"
            type="button"
            onClick={() => history.goBack()}
          >
            Back to overview
          </Button>
    </Stack>
    </>
  );
};

export default detailsTraining;
