import React from "react";

import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

import FlashOnIcon from "@mui/icons-material/FlashOn";
import DeleteIcon from "@mui/icons-material/Delete";

import Button from "../Button";

import StyledListItemIcon from "../List/StyledListItemIcon";

const PermissionTreeItem = ({ permission, onRemove, nodeId }) => {
  const key = `${nodeId}/p${permission.id}`;
  return (
    <ListItem key={key}>
      <StyledListItemIcon>
        <FlashOnIcon />
      </StyledListItemIcon>
      <ListItemText>
        {permission.removable ? (
          <>
            {permission.name}
            <Button
              title={"Remove permission from role"}
              icon={<DeleteIcon />}
              onClick={() => onRemove(permission)}
            />
          </>
        ) : (
          <Box color="text.disabled" fontStyle="italic">
            {permission.name}
          </Box>
        )}
      </ListItemText>
    </ListItem>
  );
};

export default PermissionTreeItem;
