import CertificateService from "../../services/certificate";

const filterByAircraft = (aircraftIds) => (course) =>
  !course.specializationId &&
  course.aircraftTypeId &&
  aircraftIds.includes(course.aircraftTypeId);

const byPosOrOperation = (categoryId, positionId, operationId = null) => (
  course
) =>
  course.categoryId === categoryId &&
  (!course.positionId || course.positionId === positionId) &&
  (!course.operationId || !operationId || course.operationId === operationId);

const toDisplayObject = (certificates, metadata) => (aircraftCourses) => {
  const { categoryId, positionId, operationId } = aircraftCourses;
  const title = `${
    metadata.categories.find((category) => category.id === categoryId).name
  } → ${
    metadata.positions.find((position) => position.id === positionId).name
  }${
    operationId
      ? " → " +
        metadata.operations.find((operation) => operation.id === operationId)
          .name
      : ""
  }`;

  const aircraftObj = {};
  for (const course of aircraftCourses.courses) {
    const aid = course.aircraftTypeId;
    if (!aircraftObj[aid]) aircraftObj[aid] = [];
    const matched = CertificateService.mapToCourse(course, certificates);
    aircraftObj[aid].push(matched);
  }
  for (const customCourse of aircraftCourses.customCourses) {
    const aid = customCourse.aircraftTypeId;
    if (!aircraftObj[aid]) aircraftObj[aid] = [];
    const matched = CertificateService.mapToCustomCourse(
      customCourse,
      certificates
    );
    aircraftObj[aid].push(matched);
  }

  for (const group of aircraftCourses.groups) {
    const aid = group.aircraftTypeId;
    if (!aircraftObj[aid]) aircraftObj[aid] = [];
    const matched = CertificateService.mapToGroup(group, certificates);
    aircraftObj[aid].push(matched);
  }

  const aircraft = Object.entries(aircraftObj).map(([aircraftId, certificates]) => ({
    title: metadata.aircraft.find((a) => a.id === +aircraftId).name,
    status: CertificateService.groupStatus(certificates),
    certificates,
  }));
  return {
    title,
    status: CertificateService.groupStatus(aircraft),
    aircraft,
  };
};

const AircraftService = {
  getAircraft(aircraft, description, properties) {
    const mappedPositions = [];
    const mapped = aircraft
      .map(({ id }) => id)
      .filter((id) => description.relations.crewAircraft?.includes(id));
    const filterFn = filterByAircraft(mapped);
    const courses = description.courses.filter(filterFn);
    const customCourses = description.customCourses.filter(filterFn);
    const groups = description.groups.filter(filterFn);

    properties.forEach((property) => {
      if (property.positionId) {
        const ffn = byPosOrOperation(
          property.categoryId,
          property.positionId,
          property.operationId
        );
        const mc = courses.filter(ffn);
        const mcc = customCourses.filter(ffn);
        const mg = groups.filter(ffn);
        if (mc.length || mcc.length || mg.length) {
          mappedPositions.push({
            categoryId: property.categoryId,
            positionId: property.positionId,
            operationId: property.operationId,
            courses: mc,
            customCourses: mcc,
            groups: mg,
          });
        }
      }
    });
    return mappedPositions;
  },
  toDisplayObject(ops, certificates, metadata) {
    return ops.map(toDisplayObject(certificates, metadata));
  },
};

export default AircraftService;
