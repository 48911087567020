import request from "./request";

export const getNews = async () => {
  const json = await request(`/news`); 
  return json;
};

export const getPublishedNews = async () => {
  const json = await request(`/news/published`); 
  return json;
};

export const addNews = async (source, title, description,link) => {
  const response = await request(`/news`, "POST", {
    source,
    title,
    description,
    link
  });
  return response;
};

export const deleteNewsById = async (newsId) => {
  const json = await request(`/news/${newsId}`, "DELETE");
  return json;

};

export const getNewsById = async (newsId) => {
  const json = await request(`/news/${newsId}`);
  return json;
};


export const updateNews = async (id, newNews) => {
  const response = await request(`/news/${id}`, "PUT", newNews);
  return response;
};




/* 


export const updateNews = async (id, newNews) => {
  const response = await request(`/news/${id}`, "PUT", newNews);

  return response;
};

export const deleteNewsById = async (newsId) => {
  const json = await request(`/news/${newsId}`, "DELETE");

  return json;
};
 */