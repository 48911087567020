import React from "react";

import { Switch, Route } from "react-router";
import OrdersCrewTable from "../../features/orders-crew-table";


const OrdersCrew = ({ match, history }) => {
  return (
    <>
      <Switch>
        <Route exact path={`${match.path}`} component={OrdersCrewTable} />
      </Switch>
    </>
  );
};

export default OrdersCrew;
