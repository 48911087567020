import React from "react";
import { Route } from "react-router";

import FratPage from "./Frat";

// TODO: wrap with Switch when Safety Tools will be moved to separate page
const SafetyToolsRoutes = ({ match }) => (
  <>
    <Route path={`${match.path}/frat`} component={FratPage} />
  </>
);

export default SafetyToolsRoutes;
