import { SelectableWithIdObject } from "src/components/Form/types";

import request from "./request";

interface PositionResponse {
  id: number;
  name: string;
}

export class Position extends SelectableWithIdObject {
  public name: string;

  constructor(data: PositionResponse) {
    super(data.id);
    this.name = data.name;
  }

  getDisplayName(): string {
    return this.name;
  }
}

export const mapPosition = (data: PositionResponse): Position => {
  return new Position(data);
};

export const getPositions = async (): Promise<Position[]> => {
  const response = await request(`/positions`);
  return response.map((data: PositionResponse) => mapPosition(data));
};

export const getPositionsActive = async (): Promise<Position[]> => {
  const response = await request(`/positions/active`);
  return response.map((data: PositionResponse) => mapPosition(data));
};

export const getPositionById = async (
  positionId: number,
): Promise<Position> => {
  const response = await request(`/positions/${positionId}`);
  return mapPosition(response);
};

export const addPosition = async (positionName: string, categoryId: number) => {
  const response = await request(`/positions`, "POST", {
    name: positionName,
    categoryId: categoryId,
  });

  return response;
};

// TODO: replace any type
export const updatePosition = async (positionId: number, newPosition: any) => {
  const response = await request(
    `/positions/${positionId}`,
    "PUT",
    newPosition,
  );

  return response;
};

export const deletePositionById = async (positionId: number) => {
  const json = await request(`/positions/${positionId}`, "DELETE");

  return json;
};
