import React from "react";
import { Switch, Route } from "react-router";

import EditFrat from "../features/EditFrat";
import FratList from "../features/FratList";

const FratPage = ({ match }) => (
  <Switch>
    <Route path={`${match.path}/add`} component={EditFrat} />
    <Route path={`${match.path}/edit/:fratId`} component={EditFrat} />
    <Route path={`${match.path}`} component={FratList} />
  </Switch>
);

export default FratPage;
