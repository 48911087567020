import React from "react";

import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import Button from "../../components/Button";

import { logout } from "../../services/auth";

import "./index.scss";

const UserMenu = () => {
  return (
    <div className="logout">
      <Button
        title="Logout"
        iconSize="medium"
        icon={<ExitToAppIcon />}
        onClick={logout}
      />
    </div>
  );
};

export default UserMenu;
