import React, { useContext } from "react";

import { StyledSwitch } from "../Base";
import FormContext from "./FormContext";

interface SwitchProps {
  name: string;
}

const Switch = ({ name }: SwitchProps) => {
  const context = useContext(FormContext);
  const { value } = context.get(name);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.checked;
    context.set(name, newValue);
  };

  return (
    <StyledSwitch
      checked={!!value}
      onChange={handleChange}
      name={name}
      data-testid={name}
      color="primary"
    />
  );
};

export default Switch;
