import React, { useState, useEffect } from "react";

import CrewMembersActions from "../../context/CrewMembersActionsContext";
import { useConfirm } from "../../context/ConfirmContext";
import Typography from "@mui/material/Typography";

import TableComponent from "../../components/TableComponent";
import { getMembersList, removeMemberFromCrew, getCrewById } from "../../api/crew";
import UserCrewRow from "../../components/UserCrewRow";
import useCrew from "../../hooks/useCrew";
import { formatLastFirstName } from "../../services/formatters";

import { hasPermission } from "../../services/permissions";

const UsersList = ({ match, history }) => {
  const crewId = match?.params?.crewId;
  const confirm = useConfirm();
  const { crew } = useCrew();
  const [loading, setLoading] = React.useState(false);

  const [users, setUsers] = useState([]);
  const [crewInfo, setCrewInfo] = useState(null);
  const [refresh, setRefresh] = useState(0);

  useEffect(() => {
    (async () => {
      const res = (await getMembersList(crewId)).sort((a, b) =>
        formatLastFirstName(a?.fullname).localeCompare(
          formatLastFirstName(b?.fullname)
        )
      );
      const crewInfo = (await getCrewById(crewId));
      setCrewInfo(crewInfo);
      setUsers(res);
    })();
  }, [crewId, refresh]);


  function onEdit(id) {
    history.push(`users/${id}/edit/user`);
  }

  function onSetRole(id) {
    history.push(`users/${id}/edit/roles`);
  }




return (
    <div className="table-wrapper">
      <Typography variant="h4" fontSize="small" className="t-none">
        Users
      </Typography>
      <TableComponent
        header={[
          { title: "ID", sort: "id", style: { width: "5px", paddingRight: 0 } },
          { title: "Fullname", sort: "fullname" },
          { title: "Email", sort: "email" },
          {
            title: "Country",
            sort: "country",
            style: { width: "5px", paddingRight: 0 },
          },

          { title: "Actions", style: { width: "120px" } },
        ]}
        renderChildren={(user) => (
          <UserCrewRow
            key={user.id}
            onEdit={onEdit}
            onSetRole={onSetRole}
            user={user}
          />
        )}
        items={users}
        search={["fullname", "id", "email", "username", "name"]}
        pagination
        users={users}
      />
    </div>
  );
};

export default UsersList;
